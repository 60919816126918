<template>
  <div>
    <VueNotifications
      class="top-right"
      position="top right"
    >
      <template #body="{ item }">
        <NotificationsToast
          :data="item"
        />
      </template>
    </VueNotifications>
    <VueNotifications
      class="bottom-right"
      position="bottom right"
      group="position-bottom-right"
    >
      <template #body="{ item }">
        <NotificationsToast
          :data="item"
        />
      </template>
    </VueNotifications>
    <VueNotifications
      class="bottom-right"
      position="bottom right"
      group="ws"
      :animation-name="null"
      :speed="0"
      :animation="{enter: {opacity: [1, 1]}, leave: {opacity: [1, 1]}}"
    >
      <template #body="{ item }">
        <WSNotificationsToast :data="item" />
      </template>
    </VueNotifications>
  </div>
</template>

<script>
import NotificationsToast from '@/components/Notifications/NotificationsToast.vue';
import WSNotificationsToast from '@/components/Notifications/WSNotificationsToast.vue';

export default {
  name: 'Notifications',
  components: {
    NotificationsToast,
    WSNotificationsToast,
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vue-notification-group {
  width: auto !important;
  right: 50% !important;
  transform: translateX(50%) !important;
  &.top-right {
    top: 16px !important;
    @screen md {
      @apply py-2 px-10;
      top: 0 !important;
      right: 0 !important;
      transform: none !important;
    }
  }
  &.bottom-right {
    bottom: 16px !important;
    @screen md {
      @apply py-2 px-10;
      bottom: 0 !important;
      right: 0 !important;
      transform: none !important;
    }
  }
  & > span {
    @apply flex;
    @apply flex-col;
    @apply space-y-2;
  }
}
</style>
