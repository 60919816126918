import { CancelToken } from 'axios';

/**
 * @class RequestManager
 */
class RequestManager {
  constructor() {
    this.axiosTokens = new Map();
  }

  /**
   * @param {string} key
   * @returns {CancelToken}
   */
  getNextToken(key) {
    const token = CancelToken.source();
    this.axiosTokens.set(key, token);
    return token.token;
  }

  /**
   * @param {string} key
   * @param {string=} reason
   */
  cancelAxios(key, reason) {
    if (this.axiosTokens.has(key)) {
      this.axiosTokens.get(key).cancel(reason);
      this.axiosTokens.delete(key);
    }
  }

  /**
   * @param {string} key
   * @param {string=} reason
   * @returns {CancelToken}
   */
  cancelAxiosAndGetNextToken(key, reason) {
    this.cancelAxios(key, reason);
    return this.getNextToken(key);
  }
}

const Manager = new RequestManager();

export default Manager;
