import http from '@/api';

/* eslint-disable */

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchGrades = (payload) => {
  return  http.post(`/api/admin/reports/details-grades/get_grades_list`, payload);
}
