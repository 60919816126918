var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cursor-default"},[_c('div',{staticClass:"flex flex-col items-stretch border rounded-4px overflow-hidden w-400px bg-white relative text-sm md:text-base",class:{
      'border-success-50': _vm.data.type === 'success',
      'border-danger-200': _vm.data.type === 'danger',
      'border-primary-400': _vm.data.type === 'info',
      '!w-300px': _vm.isMobileOnly
    }},[_c('div',{staticClass:"flex flex-row items-start py-4 px-6 font-semibold space-x-4 justify-center md:justify-start"},[_c('div',{staticClass:"w-6 h-6 flex-shrink-0"},[(_vm.data.type === 'success')?[_c('IconSuccess',{staticClass:"w-full h-full text-success-50"})]:_vm._e(),(_vm.data.type === 'danger')?[_c('IconCaution',{staticClass:"w-full h-full text-danger-200"})]:_vm._e(),(_vm.data.type === 'info')?[_c('IconInfo',{staticClass:"w-full h-full text-primary-400"})]:_vm._e()],2),_c('span',[_vm._v(_vm._s(_vm.data.text))])]),(_vm.payload)?_c('div',{staticClass:"pb-4 px-6"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.detail_stages)}})]):_vm._e(),_c('div',{staticClass:"h-7px flex flex-row justify-start absolute w-full left-0 bottom-0"},[_c('div',{staticClass:"w-full",class:{
          'bg-success-50': _vm.data.type === 'success',
          'bg-danger-200': _vm.data.type === 'danger',
          'bg-primary-400': _vm.data.type === 'info',
        },style:({ transform: ("translateX(" + _vm.progressTranslateX + ")") })})])])])}
var staticRenderFns = []

export { render, staticRenderFns }