import Vue from 'vue';
import Vuex from 'vuex';

import AuthVuex from '@/store/plugins/auth';
import ActiveVuex from '@/store/plugins/active';
import CachedPageFiltersVuex from '@/store/plugins/cachedFilters';
import ProgressVuex from '@/store/plugins/progress';
import { CaslVuex } from '@/store/plugins/casl';

import CoreModule from '@/store/modules/core';
import ActiveModule from '@/store/modules/active';
import UIModule from '@/store/modules/ui';
import AuthModule from '@/store/modules/auth';
import SettingsModule from '@/store/modules/settings';
import AcademicYearsModule from '@/store/modules/academic-years';
import WeekendsModule from '@/store/modules/weekends';
import TrainingProgramsModule from '@/store/modules/training-programs';
import StudentsModule from '@/store/modules/students';
import ClassesModule from '@/store/modules/classes';
import GroupsModule from '@/store/modules/groups';
import TeachersModule from '@/store/modules/teachers';
import ModeratorsModule from '@/store/modules/moderators';
import GradingSystemModule from '@/store/modules/grading-system';
import JournalModule from '@/store/modules/journal';
import ScheduleModule from '@/store/modules/schedule';
import LessonModule from '@/store/modules/lesson';
import ReportsModule from '@/store/modules/reports';
import MainPageModule from '@/store/modules/main';
import CustomerModule from '@/store/modules/customer';
import TasksModule from '@/store/modules/teacherTasks';
import HistoryLogsModule from '@/store/modules/history-logs';
import PagesQueryCache from '@/store/modules/pages-query-cache';
import News from '@/store/modules/news';
import Grades from '@/store/modules/grades';
import Progress from '@/store/modules/progress';
import Tasks from '@/store/modules/tasks/index';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    AuthVuex,
    ActiveVuex,
    CaslVuex,
    CachedPageFiltersVuex,
    ProgressVuex,
  ],
  modules: {
    pagesQueryCache: PagesQueryCache,
    core: CoreModule,
    active: ActiveModule,
    auth: AuthModule,
    settings: SettingsModule,
    academicYears: AcademicYearsModule,
    weekends: WeekendsModule,
    trainingPrograms: TrainingProgramsModule,
    students: StudentsModule,
    classes: ClassesModule,
    groups: GroupsModule,
    teachers: TeachersModule,
    moderators: ModeratorsModule,
    gradingSystem: GradingSystemModule,
    journal: JournalModule,
    schedule: ScheduleModule,
    ui: UIModule,
    lesson: LessonModule,
    reports: ReportsModule,
    main: MainPageModule,
    customer: CustomerModule,
    teacherTasks: TasksModule,
    historyLogs: HistoryLogsModule,
    news: News,
    grades: Grades,
    progress: Progress,
    tasks: Tasks,
  },
});
