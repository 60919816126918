import get from 'lodash.get';
import ContentDisposition from 'content-disposition';
import moment from '@/moment';
import { exportReportsById } from '@/api/reports/index';
import omit from 'lodash.omit';
import pick from 'lodash.pick';
import Vue from 'vue';
import mean from 'lodash.mean';

/**
 * Выполняет аргумет как функцию если он является функцией
 * @param f
 * @returns {undefined|*}
 */
export const callIfFunction = (f) => {
  if (typeof f === 'function') {
    return f();
  }
  return undefined;
};

/**
 * Возвращает значение свойства объекта по пути или коллбэку
 * @param {object} payload
 * @param {Array|string|function} identity
 * @param {any} [defaultValue]
 * @returns {undefined|*}
 */
export const getObjectPropertyByIdentity = (payload, identity, defaultValue) => {
  let result;
  if (typeof payload === 'object') {
    if (typeof identity === 'function') {
      result = identity(payload);
      if (result === undefined) {
        result = defaultValue;
      }
    } else if (typeof identity === 'string' || Array.isArray(identity)) {
      result = get(payload, identity, defaultValue);
    }
  }
  return result;
};

/**
 * Меняет позицию элемента в массиве путем изменения свойства позиции со сдвигом остальных элементов
 * @param {object[]} payload
 * @param {Array|string|function} soughtIdentity
 * @param {number} oldPosition
 * @param {number} newPosition
 * @param {string} [positionKey=position]
 * @returns {object[]}
 */
export const swapArrayObjectPositionProperty = (
  payload,
  soughtIdentity,
  oldPosition,
  newPosition,
  positionKey,
) => {
  if (typeof positionKey === 'undefined') {
    // eslint-disable-next-line no-param-reassign
    positionKey = 'position';
  }
  if (oldPosition === newPosition) {
    return payload;
  }
  const inc = oldPosition < newPosition;
  return payload.map((e) => {
    if (getObjectPropertyByIdentity(e, soughtIdentity)) {
      e[positionKey] = newPosition;
    } else if (inc) {
      if (e[positionKey] <= newPosition) {
        e[positionKey] -= 1;
      }
    } else if (e[positionKey] >= newPosition) {
      e[positionKey] += 1;
    }
    return e;
  });
};

/**
 * Скачивает содержимое ответа как файл
 * @param {object} response
 */
export const downloadBinaryResponse = (response) => {
  const { headers } = response;
  const contentDisposition = ContentDisposition.parse(headers['content-disposition']);
  const contentType = headers['content-type'];
  const file = new Blob(
    [response.data],
    { type: contentType },
  );
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    window.navigator.msSaveOrOpenBlob(file, contentDisposition?.parameters?.filename);
  } else {
    const data = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = data;
    a.download = contentDisposition?.parameters?.filename;
    if (typeof a.download === 'undefined') {
      a.setAttribute('target', '_blank');
    }
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(data);
    }, 200);
  }
};

export const downloadURIFiles = (response) => {
  const link = document.createElement('a');
  link.href = response?.data?.attachment?.url;
  link.style.display = 'none';
  link.download = response?.data?.attachment?.original_name;
  document.body.appendChild(link);
  link.click();
};

export const downloadURIFile = (attachment, target = '_self') => {
  const link = document.createElement('a');
  link.href = attachment.url;
  link.style.display = 'none';
  link.target = target;
  link.download = attachment.original_name;
  document.body.appendChild(link);
  link.click();
};

// Returns an array of dates between the two dates
/*eslint-disable*/
Date.prototype.addDays = function (days) {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export function getDates(startDate, endDate) {
  startDate = moment(startDate).toDate()
  endDate = moment(endDate).toDate()
  const dateArray = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    dateArray.push(new Date(currentDate));
    currentDate = currentDate.addDays(1);
  }
  return dateArray;
}

export async function longTermRequest(foo, frequency = 2000) {
  const reportId = await foo().then((response) => response.data.id);
  return new Promise((resolve) => {
    const interval = setInterval((function exampleFunction() {
      exportReportsById(reportId).then((response) => {
        const { data } = response;
        if (data.status === 'done') {
          clearInterval(interval);
          resolve(response);
        }
      });
      return exampleFunction;
    }()), frequency);
  });
}

export function formatDateForOutput(date) {
  return moment(date).format('YYYY-MM-DD');
}

export function formatGrade(grade) {
  const modifiedGrade = {
    ...omit(grade, ['entity']),
  };
  if (grade.grade_type?.type === 'topic_lesson') {
    modifiedGrade.lesson = {
      id: grade.entity.schedule.lesson.id,
      date: moment(grade.entity.schedule.start_date).format('YYYY-MM-DD'),
      subject: grade.entity.schedule.subject,
      teachers: getTeachersForOutput(grade?.entity?.schedule?.schedule_teachers)
    }
    modifiedGrade.date = moment(grade.entity.schedule.start_date).format('YYYY-MM-DD')
    modifiedGrade.subject = grade.entity.schedule.subject
  } else if (grade.grade_type?.type === 'individual_lesson') {
    modifiedGrade.lesson = {
      id: grade.entity.lesson.id,
      date: moment(grade.entity.lesson.schedule.start_date).format('YYYY-MM-DD'),
      subject: grade.entity.lesson.schedule.subject,
    }
    modifiedGrade.date = moment(grade.entity.lesson.schedule.start_date).format('YYYY-MM-DD')
    modifiedGrade.subject = grade.entity.lesson.schedule.subject
  } else if (grade.grade_type?.type === 'homework' || grade.grade_type?.type === 'individual_task') {
    modifiedGrade.lesson = {
      id: grade.entity.lesson.id,
      date: moment(grade.entity.lesson.schedule.start_date).format('YYYY-MM-DD'),
      subject: grade.entity.lesson.schedule.subject,
      lessonId: grade.entity.lesson_id,
      homeworkId: grade.entity.id
    }
    modifiedGrade.date = moment(grade.entity.lesson.schedule.start_date).format('YYYY-MM-DD')
    modifiedGrade.subject = grade.entity.lesson.schedule.subject
    modifiedGrade.homeworkId = grade.entity.id
    modifiedGrade.taskId = grade.id
  } else if (grade.final_grade_setting){
    modifiedGrade.date = moment(grade.final_grade_setting.grade_at).format('YYYY-MM-DD')
    modifiedGrade.subject = grade.final_grade_setting?.subject
    modifiedGrade.grade_type = grade.final_grade_setting?.grade_type
  } else {
    modifiedGrade.date = moment(grade.entity.schedule.start_date).format('YYYY-MM-DD')
    modifiedGrade.subject = grade.entity.schedule.subject
  }
  return modifiedGrade
}

export function classSortFn(x, y) {
    const number = {
      x: Number(x?.number?.name),
      y: Number(y?.number?.name),
    };
    const letter = {
      x: String(x?.letter?.name),
      y: String(y?.letter?.name),
    };
    /* eslint-disable-next-line */
    return (number.x < number.y ? -1 : (number.x > number.y ? 1 : letter.x.localeCompare(letter.y)));
}

export function modifyPersonFields(person) {
  const fullName = `${person.last_name || ''} ${person.name || ''} ${person.second_name || ''}`.trim()
  return {
    ...pick(person, ['id', 'name', 'last_name', 'second_name']),
    fullName,
    concatName:  Vue.filter('concatName')(fullName),
    shortName:  Vue.filter('concatName')(fullName, 'short'),
  }
}

export function getTeachersForOutput(teachers) {
  return teachers.map((teacher) => {
    const formattedTeacher = modifyPersonFields(teacher.replace_teacher || teacher.main_teacher || teacher.main)
    return {...formattedTeacher, isSpare: !!teacher.replace_teacher}
  })
}

export function getAverageGrade(grades) {
  const gradesValues = grades.map((g) => g.rating_setting.real_value).filter((gradeValue) => gradeValue)
  return gradesValues.length ? mean(gradesValues).toFixed(1) : 'Н/Д';
}

export function checkOverflow(el) {
  const curOverflow = el.style.overflow;
  if ( !curOverflow || curOverflow === "visible" )
    el.style.overflow = "hidden";
  const isOverflowing = el.clientWidth < el.scrollWidth
    || el.clientHeight < el.scrollHeight;
  el.style.overflow = curOverflow;
  return isOverflowing;
}

export function formatMinutesHoursToString(value) {
  return  (value < 10 ? '0' : '') + value
}
