import http from '@/api';

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getDashboardInfo = (payload) => http.get('/api/admin/dashboard/info', {
  params: payload,
});

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getHistoryLogsEvents = () => http.get('/api/admin/history_logs/events');

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getHistoryLogsList = () => http.get('/api/admin/history_logs');

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSchedulesToday = (payload) => http.get('api/admin/work/today/schedules', {
  params: payload,
});

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getHomeworksToday = (payload) => http.get('api/admin/work/today/homeworks', {
  params: payload,
});

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getListActions = (payload) => http.get('api/admin/dashboard/actions', {
  params: payload,
});

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postCreateAction = (payload) => http.post('api/admin/dashboard/actions/bulk', payload);

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const replaceActions = (payload) => http.post('api/admin/dashboard/actions/bulk/replace', payload);

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const putUpdateAction = ({ id, payload }) => http.post(`api/admin/dashboard/actions/${id}`, payload);

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteAction = (id) => http.delete(`api/admin/dashboard/actions/${id}`);

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateAction = ({ id, payload }) => http.put(`api/admin/dashboard/actions/${id}`, payload);
