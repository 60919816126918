import http from '@/api';

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getList = (payload) => http.get('/api/admin/training_programs', {
  params: payload,
});

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getById = (id) => http.get(`/api/admin/training_programs/${id}`);

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postCreate = (payload) => http.post('/api/admin/training_programs', payload);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteById = (id) => http.delete(`/api/admin/training_programs/${id}`);

/**
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const putUpdate = (id, payload) => http.put(`/api/admin/training_programs/${id}`, payload);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getTreeById = (id) => http.get(`/api/admin/training_programs/${id}`);

/**
 * @param {number} trainingProgramId
 * @param {object} payload
 * @param {object} config
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postImport = (trainingProgramId, payload, config = {}) => http.post(
  `/api/admin/training_programs/${trainingProgramId}/import`,
  payload,
  config,
);

/**
 * @param {number} program
 * @returns {Promise}
 */
export const exportProgram = (program) => http.post(`/api/admin/training_programs/${program}/export`, null, {
  responseType: 'arraybuffer',
});

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSubjectsForSelects = (payload) => http.get(
  '/api/admin/training_programs/select_subjects',
  { params: payload },
);
