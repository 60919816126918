import Vue from 'vue';
import * as TasksRepository from '@/api/tasks';
import { waiting } from '@/store/waiting';
import {
  SET_TEACHER_TASKS,
  SET_TEACHER_TASKS_CALENDAR,
} from '@/store/modules/teacherTasks/mutation-types';
import * as LessonRepository from '@/api/lesson/index';

export default {
  namespaced: true,
  state: {
    tasks: null,
    calendar: null,
    pendingHomeworks: null,
    todayHomeworks: null,
  },
  actions: {
    fetchTeacherTasks: waiting(
      'fetch.teacher.teacherTasks',
      ({ commit, rootGetters }, payload) => {
        if (rootGetters['active/getActiveAcademicYearId']) {
          // eslint-disable-next-line no-param-reassign
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        return TasksRepository.getTasks(payload).then((data) => {
          if (data) {
            commit(SET_TEACHER_TASKS, data);
          }
          return data;
        });
      },
    ),
    fetchPendingHomeworks: waiting(
      'fetch.teacher.homeworks.pending',
      ({ commit }, payload) => TasksRepository.getTeacherHomeworks(payload).then(({ data }) => {
        if (data) {
          commit('SET_TEACHER_PENDING_HOMEWORKS', data);
        }
        return data;
      }),
    ),
    fetchTodayHomeworks: waiting(
      'fetch.teacher.homeworks.today',
      ({ commit }, payload) => TasksRepository.getTeacherHomeworks(payload).then(({ data }) => {
        if (data) {
          commit('SET_TEACHER_TODAY_HOMEWORKS', data);
        }
        return data;
      }),
    ),
    updateStudentTask: waiting(
      (payload) => `fetch.teacher.task.${payload.studentTaskId}`,
      ({ commit }, { payload, studentTaskId }) => TasksRepository.getTasks(payload).then((data) => {
        commit('UPDATE_TASK', { studentTask: data[0], studentTaskId });
      }),
    ),
    fetchTasks: waiting(
      'fetch.teacher.teacherTasks',
      (store, payload) => TasksRepository.getTasks(payload),
    ),
    updateStudentTaskStatus: waiting(
      ({ studentTaskStatus }) => `update.student.task.status${studentTaskStatus.studentTaskId}`,
      ({ commit }, { path, studentTaskStatus }) => {
        const payload = {
          student_id: studentTaskStatus.studentId,
          action: studentTaskStatus.action,
        };
        return LessonRepository.updateStudentTaskStatus(path, payload).then(({ data }) => {
          if (data) {
            /* eslint-disable-next-line */
            commit('UPDATE_TASK', {
              studentTask: data,
              studentTaskId: studentTaskStatus.studentTaskId,
            });
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
    ),
    patchStudentTaskStatus: waiting(
      ({ studentTaskStatus }) => `update.student.task.status${studentTaskStatus.studentTaskId}`,
      (store, { path, studentTaskStatus }) => {
        const payload = {
          student_id: studentTaskStatus.studentId,
          action: studentTaskStatus.action,
        };
        return LessonRepository.updateStudentTaskStatus(path, payload).then((response) => response.data);
      },
    ),
    fetchTeacherTasksCalendar: waiting(
      'fetch.teacher.teacherTasks.calendar',
      ({ commit, rootGetters }, payload) => {
        if (rootGetters['active/getActiveAcademicYearId']) {
          // eslint-disable-next-line no-param-reassign
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        return TasksRepository.getTeacherTasksCalendar(payload).then(({ data }) => {
          if (data) {
            commit(SET_TEACHER_TASKS_CALENDAR, data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
    ),
    editStudentTask: waiting(
      ({ studentTaskId }) => `edit.lesson.ratings.${studentTaskId}`,
      ({ commit }, {
        lessonId, taskId, gradeId, payload, studentTaskId,
      }) => LessonRepository.putLessonRatingsByHomework(lessonId, taskId, gradeId, payload).then(({ data }) => {
        if (data) {
          commit('CHANGE_STUDENT_GRADE', { data, studentTaskId });
        }
      }),
    ),
    patchStudentTaskGrade: waiting(
      ({ studentTaskId }) => `patch.student.task${studentTaskId}`,
      (store, {
        lessonId, taskId, gradeId, payload,
      }) => LessonRepository.putLessonRatingsByHomework(lessonId, taskId, gradeId, payload)
        .then((response) => response.data),
    ),
  },
  mutations: {
    [SET_TEACHER_TASKS](state, payload) {
      Vue.set(state, 'tasks', payload);
    },
    SET_TEACHER_PENDING_HOMEWORKS(state, payload) {
      Vue.set(state, 'pendingHomeworks', payload);
    },
    SET_TEACHER_TODAY_HOMEWORKS(state, payload) {
      Vue.set(state, 'todayHomeworks', payload);
    },
    [SET_TEACHER_TASKS_CALENDAR](state, payload) {
      Vue.set(state, 'calendar', payload);
    },
    UPDATE_TASK(state, { studentTask, studentTaskId }) {
      const currentTask = state.tasks.find((task) => task.id === studentTaskId);
      Vue.set(currentTask, 'status', studentTask.status);
      Vue.set(currentTask, 'actions', studentTask.actions);
    },
    CHANGE_STUDENT_GRADE(state, { data, studentTaskId }) {
      const currentTask = state.tasks.find((task) => task.id === studentTaskId);
      currentTask.grade = data;
    },
  }
  ,
};
