export const SET_TRAINING_PROGRAMS_LIST = 'SET_TRAINING_PROGRAMS_LIST';
export const ADD_TRAINING_PROGRAM_TO_LIST = 'ADD_TRAINING_PROGRAM_TO_LIST';
export const REMOVE_TRAINING_PROGRAM_FROM_LIST = 'REMOVE_ACADEMIC_YEAR_FROM_LIST';
export const SET_TRAINING_PROGRAM_TREE = 'SET_TRAINING_PROGRAM_TREE';
export const DROP_TRAINING_PROGRAM_TREE = 'DROP_TRAINING_PROGRAM_TREE';
export const ADD_SUBJECT_TO_TRAINING_PROGRAM_TREE = 'ADD_SUBJECT_TO_TRAINING_PROGRAM_TREE';
export const DELETE_SUBJECT_FROM_TRAINING_PROGRAM_TREE = 'DELETE_SUBJECT_FROM_TRAINING_PROGRAM_TREE';
export const ADD_SECTION_TO_SUBJECT = 'ADD_SECTION_TO_SUBJECT';
export const DELETE_SECTION_FROM_SUBJECT = 'DELETE_SECTION_FROM_SUBJECT';
export const SET_TRAINING_PROGRAM_SUBJECT_SECTION_TOPICS = 'SET_TRAINING_PROGRAM_SUBJECT_SECTION_TOPICS';
export const ADD_TRAINING_PROGRAM_SUBJECT_SECTION_TOPICS = 'ADD_TRAINING_PROGRAM_SUBJECT_SECTION_TOPICS';
export const UPDATE_SUBJECT_SECTION = 'UPDATE_SUBJECT_SECTION';
export const DELETE_TOPIC_FROM_SUBJECT_SECTION = 'DELETE_TOPIC_FROM_SUBJECT_SECTION';
export const SET_SUBJECT_SECTION_TOPIC_POSITION = 'SET_SUBJECT_SECTION_TOPIC_POSITION';
export const UPDATE_TRAINING_PROGRAM_TREE_SUBJECT = 'UPDATE_TRAINING_PROGRAM_TREE_SUBJECT';
export const DROP_TRAINING_PROGRAMS_LIST = 'DROP_TRAINING_PROGRAMS_LIST';
export const SET_SUBJECTS_FOR_SELECTS = 'SET_SUBJECTS_FOR_SELECTS';
export const SET_SUBJECTS_TRAINING_PROGRAM_ID = 'SET_SUBJECTS_TRAINING_PROGRAM_ID';
export const SET_TOPICS_BY_SECTION = 'SET_TOPICS_BY_SECTION';
export const SET_TOPICS_BY_SUBJECT_ONLY = 'SET_TOPICS_BY_SUBJECT_ONLY';
