import Store from '@/store';

export default (to, from, next) => {
  const { meta = {} } = to;
  let { onlyGuest } = meta;
  if (typeof onlyGuest === 'undefined') {
    const { matched } = to;
    const match = matched.slice().reverse().find((route) => typeof route.meta?.onlyGuest !== 'undefined');
    if (match) {
      onlyGuest = Boolean(match.meta.onlyGuest);
    }
  }
  if (typeof onlyGuest !== 'undefined' && onlyGuest && Store.getters['auth/authenticated']) {
    return next({
      name: 'Home',
    });
  }
  return next();
};
