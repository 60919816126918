/* eslint-disable-next-line */
export const TEST_GRADE_TYPES = [
  {
    id: 1,
    name: 'Обычный урок',
    type: 'regular',
  },
  {
    id: 2,
    name: 'Самостоятельная работа',
    type: 'self_work',
  },
  {
    id: 3,
    name: 'Проверочная работа',
    type: 'verification',
  },
  {
    id: 4,
    name: 'Лабораторная работа',
    type: 'laboratory_work',
  },
  {
    id: 5,
    name: 'Контрольная работа',
    type: 'control',
  },
  {
    id: 6,
    name: 'Экзамен',
    type: 'examination',
    created_at: '2021-08-24T23:36:50.000000Z',
    updated_at: '2021-08-24T23:36:50.000000Z',
  },
];

export const MARKERS_LABELS = {
  comments: 'К',
  fullness: 'ЗУ',
  grades: 'О',
  homeworks: 'ДЗ',
  visits: 'П',
};

export const MARKERS_FULL_LABELS = {
  comments: 'Комментарий',
  fullness: 'Урок Заполнен',
  grades: 'Оценка',
  homeworks: 'Домашнее Задание',
  visits: 'Посещаемость',
};
