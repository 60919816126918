import http from '@/api';

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getList = () => http.get('/api/admin/weekends/types');

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getTypesList = () => http.get('/api/admin/weekends/types');

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getById = (id) => http.get(`/api/admin/weekends/${id}`);

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postCreate = (payload) => http.post('/api/admin/weekends', payload);

/**
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const putUpdate = (id, payload) => http.put(`/api/admin/weekends/${id}`, payload);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteById = (id) => http.delete(`/api/admin/weekends/${id}`);
