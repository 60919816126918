import Vue from 'vue';
import * as HistoryRepository from '@/api/history-logs';
import { waiting } from '@/store/waiting';
import {
  SET_LOG_LIST,
  SET_LOG_AUTHORS,
  SET_EVENTS_BY_GROUPS,
} from '@/store/modules/history-logs/mutation-types';

export default {
  namespaced: true,
  state: {
    logList: {},
    authors: null,
    eventsByGroups: null,
  },
  getters: {
    getLogList: (state) => (date) => state.logList[date] || null,
    getLogAuthors: (state) => state.authors,
    getEventsByGroups: (state) => state.eventsByGroups,
  },
  actions: {
    fetchLogList: waiting(
      'fetch.log.list',
      ({ commit }, { params, payload }) => HistoryRepository.getLogList({ params, payload })
        .then(({ data }) => {
          if (data) {
            commit(SET_LOG_LIST, { date: `${payload.start_date}-${payload.end_date}`, payload: data });
            return Promise.resolve();
          }
          return Promise.reject();
        }),
    ),
    fetchAuthorsForLogs: waiting(
      'fetch.authors.logs',
      ({ commit }) => HistoryRepository.getAuthors().then(({ data }) => {
        if (data) {
          commit(SET_LOG_AUTHORS, data);
          return Promise.resolve();
        }
        return Promise.reject();
      }),
    ),
    fetchEventsByGroups: waiting(
      'fetch.events.groups',
      ({ commit }) => HistoryRepository.getEventsByGroups().then(({ data }) => {
        if (data) {
          commit(SET_EVENTS_BY_GROUPS, data);
          return Promise.resolve();
        }
        return Promise.reject();
      }),
    ),
  },
  mutations: {
    [SET_LOG_LIST](state, { date, payload }) {
      Vue.set(state.logList, date, payload);
    },
    [SET_LOG_AUTHORS](state, payload) {
      Vue.set(state, 'authors', payload);
    },
    [SET_EVENTS_BY_GROUPS](state, payload) {
      Vue.set(state, 'eventsByGroups', payload);
    },
  },
};
