import Vue from 'vue';
import * as GroupsRepository from '@/api/classes/groups';
import { waiting } from '@/store/waiting';
import {
  SET_GROUPS_LIST,
  SET_GROUP_BY_ID,
  REMOVE_GROUP_BY_ID,
  REMOVE_GROUP_FROM_LIST,
  SET_SUBGROUPS_LIST,
  ADD_GROUP_TO_LIST,
  REMOVE_STUDENT_FROM_GROUP,
} from '@/store/modules/groups/mutation-types';
import {
  ADD_STUDENT_TO_CLASS_GROUP,
  REMOVE_STUDENT_FROM_CLASS_GROUP,
  ADD_CLASS_GROUP,
  REMOVE_CLASS_GROUP,
} from '@/store/modules/classes/mutation-types';

export default {
  namespaced: true,
  state: {
    groupsList: null,
    groups: {},
    subgroups: [],
  },
  getters: {
    getGroupsList: (state) => {
      if (state.groupsList) {
        return state.groupsList;
      }
      return [];
    },
    getGroupById: (state) => (id) => state.groups[id] || null,
  },
  actions: {
    fetch: waiting(
      'fetch.groups',
      ({ commit, rootGetters }) => {
        const payload = {};
        if (rootGetters['active/getActiveAcademicYearId']) {
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        return GroupsRepository.getList(payload).then(({ data }) => {
          if (data) {
            commit(SET_GROUPS_LIST, data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
    ),
    create: waiting(
      'create.groups',
      ({ commit }, payload) => GroupsRepository.postCreate(payload).then(({ data }) => {
        if (data) {
          commit(ADD_GROUP_TO_LIST, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    delete: waiting(
      (id) => `delete.groups.${id}`,
      ({ commit }, id) => GroupsRepository.deleteById(id).then(() => {
        commit(REMOVE_GROUP_FROM_LIST, id);
        commit(REMOVE_GROUP_BY_ID, id);
      }),
    ),
    getById: waiting(
      (id) => `fetch.groups.${id}`,
      ({ commit }, id) => GroupsRepository.getById(id).then(({ data }) => {
        if (data) {
          commit(SET_GROUP_BY_ID, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    fetchSubgroups: waiting(
      'fetch.subgroups',
      ({ commit, rootGetters }) => {
        const payload = {};
        if (rootGetters['active/getActiveAcademicYearId']) {
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        return GroupsRepository.getSubgroupsList(payload).then(({ data }) => {
          if (data) {
            commit(SET_SUBGROUPS_LIST, data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
    ),
    createSubgroup: waiting(
      ({ classId }) => `create.subgroups.${classId}`,
      ({ rootGetters, commit }, { groupId, classId }) => {
        const payload = {
          group_id: groupId,
          classes_id: classId,
        };
        if (rootGetters['active/getActiveAcademicYearId']) {
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        return GroupsRepository.postCreateSubGroup(payload).then(({ data }) => {
          if (data) {
            commit(`classes/${ADD_CLASS_GROUP}`, {
              classId,
              group: data,
            }, { root: true });
            return Promise.resolve(data);
          }
          return Promise.reject(classId);
        });
      },
    ),
    attachSubgroupStudents: waiting(
      ({ id }) => `attach.subgroups.${id}.students`,
      ({ commit }, {
        classId,
        id: subgroupId,
        payload,
        students,
      }) => GroupsRepository.postSubGroupAttachStudents(subgroupId, payload).then(() => {
        students.forEach((student) => {
          commit(`classes/${ADD_STUDENT_TO_CLASS_GROUP}`, {
            classId,
            subgroupId,
            student,
          }, { root: true });
        });
      }),
    ),
    attachGroupStudentsBulk: waiting(
      'attach.group.students.bulk',
      (context, { id, payload }) => GroupsRepository.postGroupAttachStudentsBulk(id, payload)
        .then(({ data }) => {
          if (data) {
            return Promise.resolve();
          }
          return Promise.reject();
        }),
    ),
    detachSubgroupStudents: waiting(
      ({ id }) => `detach.subgroups.${id}.students`,
      ({ commit }, {
        classId,
        id: subgroupId,
        payload,
        students,
        groupId = null,
      }) => GroupsRepository.postSubGroupDetachStudents(subgroupId, payload).then(() => {
        students.forEach((student) => {
          commit(`classes/${REMOVE_STUDENT_FROM_CLASS_GROUP}`, {
            classId,
            subgroupId,
            student,
          }, { root: true });
        });
        if (groupId) {
          commit(REMOVE_STUDENT_FROM_GROUP, { groupId, subgroupId, studentId: payload.students[0] });
        }
      }),
    ),
    deleteSubgroup: waiting(
      ({ id }) => `delete.subgroups.${id}`,
      ({ commit }, { id, classId }) => GroupsRepository.deleteSubGroupById(id).then(() => {
        commit(`classes/${REMOVE_CLASS_GROUP}`, { classId, groupId: id }, { root: true });
      }),
    ),
    update: waiting(
      ({ id }) => `update.group.${id}`,
      ({ commit, dispatch }, { id, payload }) => GroupsRepository
        .putUpdate(id, payload).then(({ data }) => {
          if (data) {
            commit(ADD_GROUP_TO_LIST, data);
            return dispatch('getById', id);
          }
          return Promise.reject();
        }),
    ),
  },
  mutations: {
    [SET_GROUPS_LIST](state, payload) {
      Vue.set(state, 'groupsList', payload);
    },
    [SET_GROUP_BY_ID](state, payload) {
      const { id } = payload;
      Vue.set(state.groups, id, payload);
    },
    [ADD_GROUP_TO_LIST](state, payload) {
      if (!state.groupsList) {
        Vue.set(state, 'groupsList', { data: [payload] });
      } else {
        const { id: groupId } = payload;
        const foundIndex = state.groupsList.findIndex((o) => parseInt(o.id, 10) === parseInt(groupId, 10));
        if (foundIndex > -1) {
          state.groupsList.splice(foundIndex, 1, payload);
        } else {
          state.groupsList.push(payload);
        }
      }
    },
    [REMOVE_GROUP_FROM_LIST](state, id) {
      if (state.groupsList) {
        state.groupsList = state.groupsList.filter((g) => g.id !== id);
      }
    },
    [REMOVE_GROUP_BY_ID](state, id) {
      Vue.delete(state.groups, id);
    },
    [SET_SUBGROUPS_LIST](state, payload) {
      Vue.set(state, 'subgroups', payload);
    },
    [REMOVE_STUDENT_FROM_GROUP](state, { groupId, subgroupId, studentId }) {
      const subgroup = state.groups[groupId].subgroups.find((sg) => sg.id === subgroupId);
      const studentIndex = subgroup.students.findIndex((s) => s.id === studentId);
      Vue.delete(subgroup.students, studentIndex);
    },
  },
};
