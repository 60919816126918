import Vue from 'vue';
import moment from '@/moment';

Vue.filter('capitalize', (value) => {
  if (!value) {
    return '';
  }
  // eslint-disable-next-line no-param-reassign
  value = value.toString().toLowerCase();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('lowercase', (value) => value.toString().toLowerCase());

Vue.filter('uppercase', (value) => value.toString().toUpperCase());

Vue.prototype.$filters = Vue.options.filters;

Vue.filter('date', (value, formatString) => moment(value).format(formatString));

const GRADE_TYPES_LABELS = {
  topic_lesson: 'Оценка за урок',
  homework: 'Оценка за ДЗ',
};
Vue.filter('gradeTypeLabel', (value) => GRADE_TYPES_LABELS[value]);

Vue.filter('concatName', (value, type) => {
  let array = value.split(' ');
  if (type === 'short') {
    array = array.slice(0, 2);
  }
  let concatName = '';
  array.forEach((item, idx) => {
    if (idx === 0) {
      concatName += item;
    } else {
      concatName += type === 'short' ? ` ${item}` : ` ${item[0]}.`;
    }
  });
  return concatName;
});
