import Vue from 'vue';
import { waiting } from '@/store/waiting';
import * as GradingSystemRepository from '@/api/grading-system';
import {
  SET_CLASS_GRADING_SYSTEM,
  UPDATE_CLASS_RATING,
  UPDATE_CLASS_VISITING,
  SET_VISITINGS_BY_CLASS,
  SET_RATINGS_BY_CLASS,
  CREATE_CLASS_RATING,
  DELETE_CLASS_RATING,
} from '@/store/modules/grading-system/mutation-types';
import orderBy from 'lodash.orderby';

export default {
  namespaced: true,
  state: {
    ratingsAndVisitingsByClassId: null,
    visitingsByClass: {},
    ratingsByClass: {},
  },
  getters: {
    getGradingSystem: (state) => {
      if (state.ratingsAndVisitingsByClassId) {
        return Object.values(state.ratingsAndVisitingsByClassId);
      }
      return state;
    },
    getRatingById: (state) => (id, classId) => {
      if (state.ratingsAndVisitingsByClassId?.[classId]?.rating_settings) {
        return state.ratingsAndVisitingsByClassId[classId].rating_settings.find((r) => r.id === id) || [];
      }
      return null;
    },
    getRatingByRealValue: (state) => (value, classId) => {
      if (state.ratingsAndVisitingsByClassId?.[classId]?.rating_settings) {
        return state.ratingsAndVisitingsByClassId[classId].rating_settings.find((r) => r.real_value === value) || [];
      }
      return null;
    },
    getVisitingById: (state) => (id, classId) => {
      if (state.ratingsAndVisitingsByClassId?.[classId]?.visiting_settings) {
        return state.ratingsAndVisitingsByClassId[classId].visiting_settings.find((r) => r.id === id) || [];
      }
      return null;
    },
    getVisitingByRealValue: (state) => (value, classId) => {
      if (state.ratingsAndVisitingsByClassId?.[classId]?.visiting_settings) {
        return state.ratingsAndVisitingsByClassId[classId].visiting_settings.find((r) => r.real_value === value) || [];
      }
      return null;
    },
    getVisitingByClassId: (state) => (id) => state.visitingsByClass[id] || [],
    getRatingsByClassId: (state) => (id) => state.ratingsByClass[id] || [],
  },
  actions: {
    fetch: waiting(
      'fetch.grading-system',
      ({ commit }) => GradingSystemRepository.getRatingsAndVisitings().then(({ data }) => {
        if (data) {
          data.forEach((c) => {
            commit(SET_CLASS_GRADING_SYSTEM, c);
          });
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    fetchVisitingsByClassId: waiting(
      (id) => `fetch.lesson.visitings.by-class.${id}`,
      ({ commit }, classId) => GradingSystemRepository.getVisitingsByClassId(classId).then(({ data }) => {
        if (data) {
          commit(SET_VISITINGS_BY_CLASS, { classId, payload: data });
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    fetchRatingsByClassId: waiting(
      (id) => `fetch.lesson.ratings.by-class.${id}`,
      ({ commit }, classId) => GradingSystemRepository.getRatingsByClassId(classId).then(({ data }) => {
        if (data) {
          commit(SET_RATINGS_BY_CLASS, { classId, payload: data });
        }
      }),
    ),
    updateRating: waiting(
      ({ id }) => `update.grading-system.rating.${id}`,
      ({ commit }, { id, classId, payload }) => GradingSystemRepository.putUpdateRatingById(
        classId,
        id,
        payload,
      ).then(({ data }) => {
        if (data) {
          commit(UPDATE_CLASS_RATING, { classId, payload: data });
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    createRating: waiting(
      ({ id }) => `create.grading-system.rating.${id}`,
      ({ commit }, { classId, payload }) => GradingSystemRepository.postCreateRating(
        classId,
        payload,
      ).then(({ data }) => {
        if (data) {
          commit(CREATE_CLASS_RATING, { classId, payload: data });
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    deleteRatingById: waiting(
      ({ id }) => `delete.grading-system.rating.${id}`,
      ({ commit }, { classId, id }) => GradingSystemRepository.deleteRatingById(
        classId,
        id,
      ).then(() => commit(DELETE_CLASS_RATING, { classId, id })),
    ),
    updateVisiting: waiting(
      ({ id }) => `update.grading-system.visiting.${id}`,
      ({ commit }, { id, classId, payload }) => GradingSystemRepository.putUpdateVisitingById(
        classId,
        id,
        payload,
      ).then(({ data }) => {
        if (data) {
          commit(UPDATE_CLASS_VISITING, { classId, payload: data });
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
  },
  mutations: {
    [CREATE_CLASS_RATING](state, { classId, payload }) {
      state.ratingsAndVisitingsByClassId[classId].rating_settings.push(payload);
    },
    [DELETE_CLASS_RATING](state, { classId, id }) {
      // eslint-disable-next-line max-len
      state.ratingsAndVisitingsByClassId[classId].rating_settings = state.ratingsAndVisitingsByClassId[classId].rating_settings.filter((r) => r.id !== id);
    },
    [SET_CLASS_GRADING_SYSTEM](state, payload) {
      const { id: classId } = payload;
      if (!state.ratingsAndVisitingsByClassId) {
        Vue.set(state, 'ratingsAndVisitingsByClassId', {});
      }
      if (payload?.rating_settings) {
        // eslint-disable-next-line no-param-reassign
        payload.rating_settings = payload.rating_settings.filter((r) => r.value);
        // eslint-disable-next-line no-param-reassign
        payload.rating_settings = orderBy(payload.rating_settings, 'position', 'asc');
      }
      if (payload?.visiting_settings) {
        // eslint-disable-next-line no-param-reassign
        payload.visiting_settings = orderBy(payload.visiting_settings, 'position', 'asc');
      }
      Vue.set(state.ratingsAndVisitingsByClassId, classId, payload);
    },
    [UPDATE_CLASS_RATING](state, { classId, payload }) {
      const { id: ratingId } = payload;
      if (state.ratingsAndVisitingsByClassId[classId]?.rating_settings) {
        const foundIndex = state.ratingsAndVisitingsByClassId[classId].rating_settings.findIndex(
          (r) => r.id === ratingId,
        );
        if (foundIndex > -1) {
          state.ratingsAndVisitingsByClassId[classId].rating_settings.splice(foundIndex, 1, payload);
        }
      }
    },
    [UPDATE_CLASS_VISITING](state, { classId, payload }) {
      const { id: ratingId } = payload;
      if (state.ratingsAndVisitingsByClassId[classId]?.visiting_settings) {
        const foundIndex = state.ratingsAndVisitingsByClassId[classId].visiting_settings.findIndex(
          (r) => r.id === ratingId,
        );
        if (foundIndex > -1) {
          state.ratingsAndVisitingsByClassId[classId].visiting_settings.splice(foundIndex, 1, payload);
        }
      }
    },
    [SET_VISITINGS_BY_CLASS](state, { classId, payload }) {
      Vue.set(state.visitingsByClass, classId, payload);
    },
    [SET_RATINGS_BY_CLASS](state, { classId, payload }) {
      Vue.set(state.ratingsByClass, classId, payload);
    },
  },
};
