import http from '@/api';

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getStatisticsSummaryVisits = (payload) => http.get('api/admin/reports/summary-statistics-visits', {
  params: payload,
});

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const getCustomerStatisticsSummaryVisits = (payload) => http.get('api/customer/reports/summary-statistics-visits', {
  params: payload,
});

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getStatisticsDetailsVisits = (payload) => http.get('api/admin/reports/details-statistics-visits', {
  params: payload,
});

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const getCustomerStatisticsDetailsVisits = (payload) => http.get('api/customer/reports/details-statistics-visits', {
  params: payload,
});

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getReportsSummaryByTeachers = (payload, config) => http.get('api/admin/reports/summary-by-teachers', {
  params: payload,
  cancelToken: config?.cancelToken,
});

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getReportsSummaryByVisits = (
  payload,
) => http.get('api/admin/reports/summary-by-visits', {
  params: payload,
});

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getCustomerReportsSummaryByVisits = (
  payload,
) => http.get('api/customer/reports/summary-by-visits/classes', {
  params: payload,
});

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getReportsVisitsDetails = (payload) => http.get('api/admin/reports/details-visits', {
  params: payload,
});

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getCustomerReportsVisitsDetails = (payload) => http.get('api/customer/reports/details-visits', {
  params: payload,
});

/**
 * @param {object} payload
 * @param {number} visitId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const setVisitsDetails = (visitId, payload) => http.put(`api/admin/reports/details-visits/${visitId}`, payload);

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const exportDetailsVisits = (payload) => http.get('api/admin/reports/details-visits/export', {
  params: payload,
  // responseType: 'arraybuffer',
});

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const exportStatisticsDetailsVisits = (payload) => http.get('api/admin/reports/details-statistics-visits/export', {
  params: payload,
  // responseType: 'arraybuffer',
});

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const exportReportsGrades = (payload) => http.get('api/admin/reports/details-grades/export', {
  params: payload,
  // responseType: 'arraybuffer',
});

/**
 * @param {number} reportId
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const exportReportsById = (reportId) => http.get(`/api/long-term-tasks/${reportId}`);

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const getReportsSummaryGrades = (payload) => http.get('api/admin/reports/summary-grades', {
  params: payload,
});

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const getCustomerReportsSummaryGrades = (payload) => http.get('api/customer/reports/summary-grades', {
  params: payload,
});

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const getReportsDetailsGrades = (payload) => http.get('api/admin/reports/details-grades', {
  params: payload,
});

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const getSubjectsAndStudentsDetailsGrades = (payload) => http.get('api/admin/reports/details-grades/list_subjects_and_students', {
  params: payload,
});

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const getDatesAndGradesBySubjectAndStudentDetailsGrades = (payload) => http.post('api/admin/reports/details-grades/dates_and_grades_by_subject_students', payload);

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const getCustomerReportsDetailsGrades = (payload) => http.get('api/customer/reports/details-grades', {
  params: payload,
});

/**
 * @param {object} payload
 * @returns {Promise}
 */
export const exportFinalGrades = (payload) => http.get('api/admin/journal/finals/grades/list/all/export', {
  params: payload,
});
