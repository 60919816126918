import http from '@/api';

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getList = (payload) => http.get('/api/admin/groups', {
  params: payload,
});

export const getSubgroupsList = (payload) => http.get('/api/admin/sub-groups', {
  params: payload,
});

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getById = (id) => http.get(`/api/admin/groups/${id}`);

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postCreate = (payload) => http.post('/api/admin/groups', payload);

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postCreateSubGroup = (payload) => http.post('/api/admin/sub-groups', payload);

/**
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postSubGroupAttachStudents = (id, payload) => http.post(
  `/api/admin/sub-groups/${id}/students/attach`,
  payload,
);

/**
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postSubGroupDetachStudents = (id, payload) => http.post(
  `/api/admin/sub-groups/${id}/students/detach`,
  payload,
);

/**
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postGroupAttachStudentsBulk = (id, payload) => http.post(
  `/api/admin/groups/${id}/students/attach/bulk`,
  payload,
);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteSubGroupById = (id) => http.delete(`/api/admin/sub-groups/${id}`);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteById = (id) => http.delete(`/api/admin/groups/${id}`);

export const putUpdate = (id, payload) => http.put(
  `/api/admin/groups/${id}`,
  payload,
);
