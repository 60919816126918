import http from '@/api';

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getList = (payload) => http.get('/api/admin/students', {
  params: payload,
});

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getArchiveList = (payload) => http.get('/api/admin/students/archive', {
  params: payload,
});

/**
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getById = (id, payload) => http.get(`/api/admin/students/${id}`, {
  params: payload,
});

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getShowPasswordById = (id) => http.get(`/api/admin/students/${id}/show-password`);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getGeneratePasswordById = (id) => http.get(`/api/admin/students/${id}/generate-password`);

/**
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const putUpdate = (id, payload) => http.put(
  `/api/admin/students/${id}`,
  payload,
);

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postCreate = (payload) => http.post('/api/admin/students', payload);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteById = (id) => http.delete(`/api/admin/students/${id}`);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getRecoveryById = (id) => http.get(`/api/admin/students/${id}/recovery`);

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getMyClasses = () => http.get('/api/customer/me/classes');

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getStudentsClasses = (payload) => http.post('/api/customer/students/classes', payload);

/**
 * @param {object} payload
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postUpdateStudentActivity = ({ id, payload }) => http.post(`/api/admin/students/${id}/activity`, payload);
