import Vue from 'vue';
import moment from '@/moment';
import { waiting } from '@/store/waiting';
import * as ReportsRepository from '@/api/reports';
import {
  SET_RERORTS_SUMMARY_BY_TEACHERS,
  SET_REPORTS_SUMMARY_BY_VISITS,
  SET_REPORTS_DETAILS_VISITS,
  SET_STATISTICS_SUMMARY_VISITS,
  SET_STATISTICS_DETAILS_VISITS,
  ADD_REPORTS_VISITING,
  SET_REPORTS_SUMMARY_GRADES,
  SET_REPORTS_DETAILS_GRADES,
  SET_SUBJECTS_STUDENTS_DETAILS_GRADES,
} from '@/store/modules/reports/mutation-types';
import { fetchGrades } from '@/api/grades/index';
import { formatGrade } from '@/utils/index';

export default {
  namespaced: true,
  state: {
    reportsSummaryByTeachers: {},
    reportsSummaryByVisits: null,
    reportsVisitsDetails: {},
    statisticsSummaryVisits: null,
    statisticsDetailsVisits: {},
    reportsSummaryGrades: {},
    reportsDetailsGrades: {},
    subjectsAndStudentsDetailsGrades: null,
    grades: null,
  },
  getters: {
    getReportsSummaryByTeachers: (state) => (id) => state.reportsSummaryByTeachers[id] || [],
    getReportsSummaryByVisits: (state) => state.reportsSummaryByVisits?.filter((i) => i.class),
    getReportsVisitsDetails: (state) => (classId, date) => {
      if (state.reportsVisitsDetails[classId]) {
        return state.reportsVisitsDetails[classId][date];
      }
      return [];
    },
    getStatisticsSummaryVisits: (state) => state.statisticsSummaryVisits || [],
    getStatisticsDetailsVisits: (state) => (id) => state.statisticsDetailsVisits[id] || [],
    getReportsSummaryGrades: (state) => (date) => state.reportsSummaryGrades[date] || [],
    getReportsDetailsGrades: (state) => (date) => state.reportsDetailsGrades[date] || [],
    getSubjects: (state) => state.subjectsAndStudentsDetailsGrades,
    // eslint-disable-next-line max-len
    getGrades: (state) => state.grades,
  },
  actions: {
    fetchReportsSummaryByTeachers: waiting(
      'fetch.reports.summary.by.teachers',
      ({ commit }, payload, { cancelToken }) => ReportsRepository
        .getReportsSummaryByTeachers(payload, { cancelToken }).then(({ data }) => {
          if (data) {
            commit(SET_RERORTS_SUMMARY_BY_TEACHERS, { date: payload.start_date, payload: data });
            return Promise.resolve(data);
          }
          return Promise.reject();
        }),
      { cancel: true },
    ),
    fetchReportsSummaryByVisits: waiting(
      'fetch.reports.summary.by.visits',
      ({ commit, rootGetters }, payload) => {
        let request = ReportsRepository.getReportsSummaryByVisits;
        if (['student', 'parent'].includes(rootGetters['auth/role'])) {
          request = ReportsRepository.getCustomerReportsSummaryByVisits;
        }
        return request(payload).then(({ data }) => {
          if (data) {
            commit(SET_REPORTS_SUMMARY_BY_VISITS, data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
    ),
    fetchReportsVisitsDetails: waiting(
      'fetch.reports.visits.details',
      ({ commit, rootGetters }, payload) => {
        let request = ReportsRepository.getReportsVisitsDetails;
        if (['student', 'parent'].includes(rootGetters['auth/role'])) {
          request = ReportsRepository.getCustomerReportsVisitsDetails;
        }
        return request(payload).then(({ data }) => {
          if (data) {
            const { classes_id: classId, start_date: startDate, end_date: endDate } = payload;
            const start = moment.utc(moment(startDate)).toISOString();
            const end = moment.utc(moment(endDate)).toISOString();
            const date = `${start}-${end}`;
            commit(SET_REPORTS_DETAILS_VISITS, { classId, date, payload: data });
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
    ),
    fetchStatisticsSummaryVisits: waiting(
      'fetch.reports.statistics.summary.visits',
      ({ commit, rootGetters }, payload) => {
        let request = ReportsRepository.getStatisticsSummaryVisits;
        if (['student', 'parent'].includes(rootGetters['auth/role'])) {
          request = ReportsRepository.getCustomerStatisticsSummaryVisits;
        }
        return request(payload).then(({ data }) => {
          if (data) {
            commit(SET_STATISTICS_SUMMARY_VISITS, data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
    ),
    fetchStatisticsDetailsVisits: waiting(
      'fetch.reports.statistics.details.visits',
      ({ commit, rootState }, payload) => {
        let request = ReportsRepository.getStatisticsDetailsVisits;
        if (rootState.active.activeStudentId) {
          request = ReportsRepository.getCustomerStatisticsDetailsVisits;
        }
        return request(payload).then(({ data }) => {
          if (data) {
            commit(SET_STATISTICS_DETAILS_VISITS, { classId: payload.classes_id, data });
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
    ),
    setVisitsDetails: waiting(
      'set.reports.visits.details',
      ({ commit }, {
        selectedDate, classId, visitId, payload,
      }) => ReportsRepository.setVisitsDetails(visitId, payload)
        .then(({ data }) => {
          if (data) {
            const start = moment.utc(selectedDate.start_date).startOf('day').toISOString();
            const end = moment.utc(selectedDate.end_date).endOf('day').toISOString();
            const date = `${start}-${end}`;
            commit(ADD_REPORTS_VISITING, {
              date,
              classId,
              visitId,
              payload: data,
              studentId: payload.student_id,
            });
            return Promise.resolve(data);
          }
          return Promise.reject();
        }),
    ),
    exportDetailsVisits: waiting(
      'export.details.visits',
      (context, payload) => ReportsRepository.exportDetailsVisits(payload),
    ),
    exportStatisticsDetailsVisits: waiting(
      'export.statistics.details.visits',
      (context, payload) => ReportsRepository.exportStatisticsDetailsVisits(payload),
    ),
    exportReportsGrades: waiting(
      'export.reports.grades',
      (context, payload) => ReportsRepository.exportReportsGrades(payload),
    ),
    exportReportsById: waiting(
      (id) => `export.reports.${id}`,
      (context, id) => ReportsRepository.exportReportsById(id),
    ),
    fetchReportsSummaryGrades: waiting(
      'fetch.reports.summary.grades',
      ({ commit, rootGetters }, { payload }) => {
        if (rootGetters['active/getActiveAcademicYearId']) {
          // eslint-disable-next-line no-param-reassign
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        let request = ReportsRepository.getReportsSummaryGrades;
        if (['student', 'parent'].includes(rootGetters['auth/role'])) {
          request = ReportsRepository.getCustomerReportsSummaryGrades;
        }
        return request(payload).then(({ data }) => {
          if (data) {
            const start = moment(payload.start_date).utcOffset(0).set({
              hour: 0, minute: 0, second: 0, millisecond: 0,
            });
            start.toISOString();
            const end = moment(payload.end_date).utcOffset(0).set({
              hour: 23, minute: 59, second: 59, millisecond: 59,
            });
            end.toISOString();
            const date = payload.start_date;
            commit(SET_REPORTS_SUMMARY_GRADES, {
              date,
              payload: data,
            });
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
    ),
    fetchReportsDetailsGrades: waiting(
      'fetch.reports.details.grades',
      ({ commit, rootGetters }, { payload }) => {
        let request = ReportsRepository.getReportsDetailsGrades;
        if (['student', 'parent'].includes(rootGetters['auth/role'])) {
          request = ReportsRepository.getCustomerReportsDetailsGrades;
        }
        return request(payload).then(({ data }) => {
          if (data) {
            const start = moment.utc(moment(payload.start_date)).toISOString();
            const end = moment.utc(moment(payload.end_date)).toISOString();
            const date = `${start}-${end}`;
            commit(SET_REPORTS_DETAILS_GRADES, {
              date,
              payload: data,
            });
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
    ),
    fetchSubjectsAndStudentsDetailsGrades: waiting(
      'fetch.subjects.students.details.grades',
      ({ commit }, { payload }) => ReportsRepository.getSubjectsAndStudentsDetailsGrades(payload)
        .then(({ data }) => {
          if (data) {
            commit(SET_SUBJECTS_STUDENTS_DETAILS_GRADES, { payload: data });
            return Promise.resolve(data);
          }
          return Promise.reject();
        }),
    ),
    fetchGradesByType: waiting(
      'fetch.dates.grades.by.subject.student.details.grades',
      ({ commit }, payload) => fetchGrades(payload)
        .then((response) => response.data.map((grade) => formatGrade(grade)))
        .then((grades) => {
          commit('SET_GRADES', grades);
          return grades;
        }),
    ),
    fetchGradesByStudent: waiting(
      'fetch.dates.grades.by.subject.student.details.grades',
      (store, payload) => fetchGrades(payload)
        .then((response) => response.data.map((grade) => formatGrade(grade))),
    ),
    exportFinalGrades: waiting(
      'export.final.grades',
      (context, payload) => ReportsRepository.exportFinalGrades(payload),
    ),
  },
  mutations: {
    [SET_RERORTS_SUMMARY_BY_TEACHERS](state, { date, payload }) {
      Vue.set(state.reportsSummaryByTeachers, date, payload);
    },
    [SET_REPORTS_SUMMARY_BY_VISITS](state, payload) {
      Vue.set(state, 'reportsSummaryByVisits', payload);
    },
    [SET_REPORTS_DETAILS_VISITS](state, { classId, date, payload }) {
      if (!state.reportsVisitsDetails[classId]) {
        Vue.set(state.reportsVisitsDetails, classId, {});
      }
      if (!state.reportsVisitsDetails[classId][date]) {
        Vue.set(state.reportsVisitsDetails[classId], date, payload);
      }
    },
    [SET_STATISTICS_SUMMARY_VISITS](state, payload) {
      state.statisticsSummaryVisits = payload;
    },
    [SET_STATISTICS_DETAILS_VISITS](state, { classId, data }) {
      Vue.set(state.statisticsDetailsVisits, classId, data);
    },
    [SET_REPORTS_SUMMARY_GRADES](state, { date, payload }) {
      Vue.set(state.reportsSummaryGrades, date, payload);
    },
    [SET_REPORTS_DETAILS_GRADES](state, { date, payload }) {
      Vue.set(state.reportsDetailsGrades, date, payload);
    },
    [SET_SUBJECTS_STUDENTS_DETAILS_GRADES](state, { payload }) {
      state.subjectsAndStudentsDetailsGrades = payload;
    },
    SET_GRADES(state, grades) {
      state.grades = grades;
    },
    [ADD_REPORTS_VISITING](state, {
      date, classId, visitId, payload, studentId,
    }) {
      const studentIndex = state.reportsVisitsDetails[classId][date].items
        .findIndex((i) => i.student.id === studentId);
      const foundIndex = state.reportsVisitsDetails[classId][date].items[studentIndex].visits
        .findIndex((i) => i.id === visitId);
      // eslint-disable-next-line no-param-reassign
      payload.lesson_id = payload.lesson.id;
      // eslint-disable-next-line no-param-reassign
      payload.student_id = studentId;
      state.reportsVisitsDetails[classId][date].items[studentIndex].visits.splice(foundIndex, 1, payload);
    },
  },
};
