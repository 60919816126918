<template>
  <div
    id="app"
  >
    <Layout v-if="initialized" />
    <PusherService />
    <Notifications />
    <portal-target
      name="root"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import Layout from '@/components/Global/Layout.vue';
import Notifications from '@/components/Global/Notifications.vue';
import PusherService from '@/components/PusherService.vue';

export default {
  name: 'App',
  metaInfo: {
    titleTemplate: (value) => {
      const postfix = 'Lancman School';
      if (value) {
        return `${value} | ${postfix}`;
      }
      return postfix;
    },
  },
  components: {
    PusherService,
    Layout,
    Notifications,
  },
  computed: {
    ...mapState({
      initialized: (state) => state.core.initialized,
    }),
    ...mapGetters({
      role: 'auth/role',
      authenticated: 'auth/authenticated',
    }),
  },
};
</script>
