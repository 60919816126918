import Vue from 'vue';
import * as SettingsRepository from '@/api/settings';
import { waiting } from '@/store/waiting';
import {
  SET_SETTINGS,
  SET_COUNTRIES_LIST,
  SET_DATE_TYPES_LIST,
  SET_DAYS_LIST, SET_SAVED_FILTERS, DELETE_SAVED_FILTER, SET_SAVED_FILTER,
} from '@/store/modules/settings/mutation-types';

export default {
  namespaced: true,
  state: {
    settings: null,
    countries: null,
    dateTypes: null,
    days: null,
    savedFilters: null,
    lessonTimeRange: {
      start: 8,
      end: 20,
    },
    juniorClassesNumbers: [1, 2, 3, 4],
  },
  getters: {
    getCountries: (state) => state?.countries || [],
    getDateTypes: (state) => state?.dateTypes || [],
    getDays: (state) => state?.days || [],
    getUserFilers: (state) => (type) => state.savedFilters?.[type],
    getIsHwWithGrades: (state) => state.settings?.is_homework_with_grades,
    getLessonTimeRange: (state) => state.lessonTimeRange,
    getJuniorClassesNumbers: (state) => state.juniorClassesNumbers,
  },
  actions: {
    fetchSettings: waiting(
      'fetch.settings',
      ({ commit }) => SettingsRepository.getSettings().then(({ data }) => {
        if (data) {
          commit(SET_SETTINGS, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    fetchSettingsForCustomer: waiting(
      'fetch.settings',
      ({ commit }) => SettingsRepository.getSettingsForCustomer().then(({ data }) => {
        if (data) {
          commit(SET_SETTINGS, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    fetchCountries: waiting(
      'fetch.settings.countries',
      ({ commit }) => SettingsRepository.getCountriesList().then(({ data }) => {
        if (data) {
          commit(SET_COUNTRIES_LIST, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    fetchDateTypes: waiting(
      'fetch.settings.date-types',
      ({ commit }) => SettingsRepository.getDateTypesList().then(({ data }) => {
        if (data) {
          commit(SET_DATE_TYPES_LIST, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    fetchDays: waiting(
      'fetch.settings.days',
      ({ commit }) => SettingsRepository.getDaysList().then(({ data }) => {
        if (data) {
          commit(SET_DAYS_LIST, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    updateSettings: waiting(
      (payload) => {
        if (Object.keys(payload).length === 1) {
          // eslint-disable-next-line no-param-reassign
          const [property] = Object.keys(payload);
          return `update.settings.${property}`;
        }
        return 'update.settings';
      },
      (
        { commit },
        payload,
      ) => SettingsRepository.putUpdateSettings(payload).then(({ data }) => {
        if (data) {
          commit(SET_SETTINGS, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    postUploadLogo: waiting(
      'upload.settings.logo',
      ({ commit }, { payload, config }) => SettingsRepository.postUploadLogo(payload, config).then(({ data }) => {
        if (data) {
          commit(SET_SETTINGS, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    fetchSavedFilters: waiting(
      'fetch.saved-filters',
      ({ commit }) => SettingsRepository.getSavedFilters().then((data) => {
        if (data) {
          commit(SET_SAVED_FILTERS, data);
        }
      }),
    ),
    postNewFilter: waiting(
      'post.filter',
      ({ commit }, filter) => SettingsRepository.postNewFilter(filter).then((data) => {
        if (data) {
          commit(SET_SAVED_FILTER, data);
        }
        return data;
      }),
    ),
    deleteFilter: waiting(
      'post.filter',
      ({ commit }, id) => SettingsRepository.deleteFilter(id).then(({ data }) => {
        if (data) {
          // console.log('deleteFilter');
          commit(DELETE_SAVED_FILTER, { id, type: data.type });
        }
      }),
    ),
  },
  mutations: {
    [SET_SETTINGS](state, payload) {
      Vue.set(state, 'settings', payload);
    },
    [SET_COUNTRIES_LIST](state, payload) {
      state.countries = payload;
    },
    [SET_DATE_TYPES_LIST](state, payload) {
      state.dateTypes = payload;
    },
    [SET_DAYS_LIST](state, payload) {
      state.days = payload;
    },
    [SET_SAVED_FILTERS](state, payload) {
      Vue.set(state, 'savedFilters', payload);
    },
    [DELETE_SAVED_FILTER](state, { type, id }) {
      const filterIdx = state.savedFilters[type].findIndex((f) => f.id === id);
      state.savedFilters[type].splice(filterIdx, 1);
    },
    [SET_SAVED_FILTER](state, filter) {
      if (!state.savedFilters[filter.type]) {
        Vue.set(state.savedFilters, filter.type, []);
      }
      state.savedFilters[filter.type].unshift(filter);
    },
  },
};
