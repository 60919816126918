<template>
  <div class="cursor-default">
    <!-- eslint-disable -->
    <div
      class="flex flex-col items-stretch border rounded-4px overflow-hidden w-400px bg-white relative text-sm md:text-base"
      :class="{
        'border-success-50': data.type === 'success',
        'border-danger-200': data.type === 'danger',
        'border-primary-400': data.type === 'info',
        '!w-300px': isMobileOnly
      }"
    >
    <!-- eslint-enable -->
      <div class="flex flex-row items-start py-4 px-6 font-semibold space-x-4 justify-center md:justify-start">
        <div class="w-6 h-6 flex-shrink-0">
          <template v-if="data.type === 'success'">
            <IconSuccess class="w-full h-full text-success-50" />
          </template>
          <template v-if="data.type === 'danger'">
            <IconCaution class="w-full h-full text-danger-200" />
          </template>
          <template v-if="data.type === 'info'">
            <IconInfo class="w-full h-full text-primary-400" />
          </template>
        </div>
        <span>{{ data.text }}</span>
      </div>
      <div v-if="payload" class="pb-4 px-6"><span v-html="detail_stages"></span></div>
      <div class="h-7px flex flex-row justify-start absolute w-full left-0 bottom-0">
        <div
          class="w-full"
          :class="{
            'bg-success-50': data.type === 'success',
            'bg-danger-200': data.type === 'danger',
            'bg-primary-400': data.type === 'info',
          }"
          :style="{ transform: `translateX(${progressTranslateX})` }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import IconCaution from '@/assets/icons/caution.svg?component';
import IconSuccess from '@/assets/icons/success.svg?component';
import IconInfo from '@/assets/icons/info.svg?component';
import { screenDetectMixin } from '@/mixins/screenDetect';

export default {
  name: 'WSNotificationsToast',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    IconCaution,
    IconSuccess,
    IconInfo,
  },
  mixins: [screenDetectMixin],
  computed: {
    payload() {
      return this.data?.data;
    },
    stages() {
      return this.payload?.stages ?? [];
    },
    detail_stages() {
      return (() => {
        const stages = this.payload?.detail_stages ?? [];
        return stages.map((stage) => {
          if (stage.finished) {
            return `<s>${stage.value}</s>`;
          }
          if (stage.current) {
            return `<b>${stage.value}</b>`;
          }
          if (!stage.finished && !stage.current) {
            return `<span>${stage.value}</span>`;
          }
          return `<i>${stage.value}</i>`;
        }).join(', ');
      })();
    },
    percentage() {
      return (() => {
        const p = this.payload?.percent?.current;
        if (Number.isNaN(p)) {
          return 0;
        }
        if (p > 100) {
          return 100;
        }
        return p;
      })();
    },
    progressTranslateX() {
      return `${this.percentage - 100}%`;
    },
  },
};
</script>
