import http from '@/api';
import axios from 'axios';
/**
 * @param {string} phone
 * @param {string} password
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postLogin = (phone, password) => http.post('/api/login', {
  phone,
  password,
});

// eslint-disable-next-line max-len
export const postSchoolLogin = (phone, password) => axios.post(`${process.env.VUE_APP_SCHOOL_API_URL}/auth/login`, { phone, password });

export const getMyInfo = () => http.get('/api/user');

/**
 * @param {string} token
 * @returns {Promise<AxiosResponse<any>>}
 */
export const refreshToken = (token) => http.post('/api/refresh', {
  refresh_token: token,
}, { skipAuthRefresh: true, showToastOnError: false });
