// eslint-disable-next-line import/prefer-default-export
export const screenDetectMixin = {
  computed: {
    isMobileOnly() {
      return ['xs', 'sm'].includes(this.$screen.breakpoint);
    },
    isMobileAndTablet() {
      return ['xs', 'sm', 'md', 'lg'].includes(this.$screen.breakpoint);
    },
    isTablet() {
      return ['md', 'lg'].includes(this.$screen.breakpoint);
    },
    lessThanTablet() {
      return ['xs', 'sm'].includes(this.$screen.breakpoint);
    },
  },
};
