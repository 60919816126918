import Store from '@/store';
import { ability, updateAbilities } from '@/store/plugins/casl';

// eslint-disable-next-line import/prefer-default-export
export const AbilityMiddleware = ({ action, subject, redirect = 'Home' }) => (to, from, next) => {
  updateAbilities(Store);
  if (!ability.can(action, subject)) {
    return next({
      name: redirect,
    });
  }
  return next();
};
export const AbilityMiddlewareSome = (paramsArray, redirect = 'Home') => (to, from, next) => {
  updateAbilities(Store);
  if (!paramsArray.some((p) => ability.can(p.action, p.subject))) {
    return next({
      name: redirect,
    });
  }
  return next();
};
