import http from '@/api';

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getEventsList = () => http.get('/api/admin/history_logs/events');

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getEventsByGroups = () => http.get('/api/admin/history_logs/events-by-groups');

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getAuthors = () => http.get('/api/admin/history_logs/authors');

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getLogList = ({ params, payload }) => http.post('/api/admin/history_logs', payload, {
  params,
});
