import Vue from 'vue';
import { waiting } from '@/store/waiting';
import * as ModeratorsRepository from '@/api/moderators';
import * as UsersRepository from '@/api/users';
import {
  SET_MODERATORS_LIST,
  DELETE_MODERATOR_BY_ID,
  SET_MODERATOR_BY_ID,
  DROP_MODERATOR_BY_ID,
  ADD_MODERATOR_TO_LIST,
  UPDATE_MODERATOR_BY_ID,
  SET_MODERATORS_ARCHIVE_LIST,
  REMOVE_MODERATOR_FROM_ARCHIVE_LIST,
} from '@/store/modules/moderators/mutation-types';
import orderBy from 'lodash.orderby';

export default {
  namespaced: true,
  state: {
    moderatorsList: null,
    moderatorsArchiveList: null,
    moderators: {},
  },
  getters: {
    getModeratorsList: (state) => {
      if (state.moderatorsList?.data) {
        return orderBy(state.moderatorsList.data, 'id', ['desc']);
      }
      return [];
    },
    getModeratorsArchiveList: (state) => {
      if (state.moderatorsArchiveList) {
        return orderBy(state.moderatorsArchiveList, 'id', ['desc']);
      }
      return [];
    },
    getModeratorById: (state) => (id) => state.moderators[id] || null,
  },
  actions: {
    fetch: waiting(
      'fetch.moderators',
      ({ commit }) => ModeratorsRepository.getList().then(({ data }) => {
        if (data) {
          commit(SET_MODERATORS_LIST, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    fetchArchive: waiting(
      'fetch.moderators.archive',
      ({ commit, rootGetters }) => {
        const payload = {};
        if (rootGetters['active/getActiveAcademicYearId']) {
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        return ModeratorsRepository.getArchiveList(payload).then(({ data }) => {
          if (data) {
            commit(SET_MODERATORS_ARCHIVE_LIST, data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
    ),
    getById: waiting(
      (id) => `fetch.moderators.${id}`,
      ({ commit }, id) => ModeratorsRepository.getById(id).then(({ data }) => {
        if (data) {
          commit(SET_MODERATOR_BY_ID, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    delete: waiting(
      (id) => `delete.moderators.${id}`,
      ({ commit }, id) => ModeratorsRepository.deleteById(id).then(() => {
        commit(DELETE_MODERATOR_BY_ID, id);
      }),
    ),
    update: waiting(
      ({ id }) => `update.moderators.${id}`,
      ({ commit }, { id, payload }) => ModeratorsRepository.putUpdate(id, payload).then(({ data }) => {
        if (data) {
          commit(ADD_MODERATOR_TO_LIST, data);
          commit(SET_MODERATOR_BY_ID, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    recoverById: waiting(
      ({ id }) => `recover.moderator.${id}`,
      ({ commit }, id) => ModeratorsRepository.getRecoveryById(id).then(({ data }) => {
        if (data) {
          commit(ADD_MODERATOR_TO_LIST, data);
          commit(SET_MODERATOR_BY_ID, data);
          commit(REMOVE_MODERATOR_FROM_ARCHIVE_LIST, id);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    create: waiting(
      'create.moderators',
      ({ commit }, payload) => ModeratorsRepository.postCreate(payload).then(({ data }) => {
        if (data) {
          commit(ADD_MODERATOR_TO_LIST, data);
          commit(SET_MODERATOR_BY_ID, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    getPasswordById: waiting(
      (id) => `fetch.moderators.${id}.password`,
      ({ commit }, id) => ModeratorsRepository.getShowPasswordById(id).then(({ data }) => {
        if (data) {
          commit(UPDATE_MODERATOR_BY_ID, { id, payload: data });
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    export: waiting(
      'export.moderators',
      () => UsersRepository.getExport('moderator'),
    ),
    import: waiting(
      'import.moderators',
      (context, { payload, config }) => UsersRepository.postImport(
        payload,
        config,
      ).then((data) => Promise.resolve(data))
        .catch((res) => Promise.reject(res.response.data)),
    ),
  },
  mutations: {
    [SET_MODERATORS_LIST](state, payload) {
      Vue.set(state, 'moderatorsList', payload);
    },
    [SET_MODERATORS_ARCHIVE_LIST](state, payload) {
      Vue.set(state, 'moderatorsArchiveList', payload);
    },
    [DELETE_MODERATOR_BY_ID](state, id) {
      if (state.moderatorsList?.data) {
        state.moderatorsList.data = state.moderatorsList.data.filter((s) => parseInt(s.id, 10) !== parseInt(id, 10));
      }
      if (state.moderators[id]) {
        Vue.delete(state.moderators, id);
      }
    },
    [SET_MODERATOR_BY_ID](state, payload) {
      const { id } = payload;
      Vue.set(state.moderators, id, payload);
    },
    [DROP_MODERATOR_BY_ID](state, id) {
      Vue.delete(state.moderators, id);
    },
    [ADD_MODERATOR_TO_LIST](state, payload) {
      const { id } = payload;
      if (!state.moderatorsList?.data) {
        Vue.set(state, 'moderatorsList', { data: [] });
      }
      const foundIndex = state.moderatorsList.data.findIndex((o) => parseInt(o.id, 10) === parseInt(id, 10));
      if (foundIndex > -1) {
        state.moderatorsList.data.splice(foundIndex, 1, payload);
      } else {
        state.moderatorsList.data.push(payload);
      }
    },
    [UPDATE_MODERATOR_BY_ID](state, { id, payload }) {
      if (state.moderators[id]) {
        Vue.set(state.moderators, id, {
          ...state.moderators[id],
          ...payload,
        });
      }
    },
    [REMOVE_MODERATOR_FROM_ARCHIVE_LIST](state, id) {
      if (state.moderatorsArchiveList) {
        // eslint-disable-next-line max-len
        state.moderatorsArchiveList = state.moderatorsArchiveList.filter((s) => parseInt(s.id, 10) !== parseInt(id, 10));
      }
    },
  },
};
