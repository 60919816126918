export const SET_RERORTS_SUMMARY_BY_TEACHERS = 'SET_RERORTS_SUMMARY_BY_TEACHERS';
export const SET_REPORTS_SUMMARY_BY_VISITS = 'SET_REPORTS_SUMMARY_BY_VISITS';
export const SET_REPORTS_DETAILS_VISITS = 'SET_REPORTS_DETAILS_VISITS';
export const SET_STATISTICS_SUMMARY_VISITS = 'SET_STATISTICS_SUMMARY_VISITS';
export const SET_STATISTICS_DETAILS_VISITS = 'SET_STATISTICS_DETAILS_VISITS';
export const ADD_REPORTS_VISITING = 'ADD_REPORTS_VISITING';
export const SET_REPORTS_SUMMARY_GRADES = 'SET_REPORTS_SUMMARY_GRADES';
export const SET_REPORTS_DETAILS_GRADES = 'SET_REPORTS_DETAILS_GRADES';
export const SET_SUBJECTS_STUDENTS_DETAILS_GRADES = 'SET_SUBJECTS_STUDENTS_DETAILS_GRADES';
export const SET_GRADES = 'SET_DATES_GRADES_BY_SUBJECT_STUDENT';
