export default {
  weekdays: {
    monday: 'понедельник',
    tuesday: 'вторник',
    wednesday: 'среда',
    thursday: 'четверг',
    friday: 'пятница',
    saturday: 'суббота',
    sunday: 'воскресенье',
  },
};
