import Vue from 'vue';
import VueRouter from 'vue-router';
import qs from 'qs';
import VueRouterMultiguard from 'vue-router-multiguard';

import AuthMiddleware from '@/router/middlewares/auth';
import GuestMiddleware from '@/router/middlewares/guest';
import { AbilityMiddleware, AbilityMiddlewareSome } from '@/router/middlewares/ability';

import { ability } from '@/store/plugins/casl';

import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import Store from '@/store/index';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "view-home" */ '@/views/Home.vue'),
  },
  {
    path: '/main',
    name: 'Main',
    component: () => import(/* webpackChunkName: "view-main" */ '@/views/Main.vue'),
    meta: {
      layout: 'fullWidthLayout',
    },
    beforeEnter: (to, from, next) => {
      if (!ability.can('access', 'student')) {
        next();
      } else {
        next({ name: 'Home' });
      }
    },
  },
  {
    path: '/auth',
    name: 'Auth',
    redirect: {
      name: 'Auth.Login',
    },
    meta: {
      auth: false,
      onlyGuest: true,
      rootLayout: 'auth',
    },
    component: () => import(/* webpackChunkName: "view-auth" */ '@/views/Auth.vue'),
    children: [
      {
        path: 'login',
        name: 'Auth.Login',
        component: () => import(/* webpackChunkName: "view-auth" */ '@/views/Auth/AuthLogin.vue'),
      },
      {
        path: 'login/bytoken',
        name: 'Auth.ByToken',
        component: () => import(/* webpackChunkName: "view-authbytoken" */ '@/views/Auth/AuthByToken.vue'),
      },
    ],
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    redirect: {
      name: 'Dashboard.Home',
    },
    component: () => import(/* webpackChunkName: "view-dashboard" */ '@/views/Dashboard.vue'),
    children: [
      {
        path: '/',
        name: 'Dashboard.Home',
        redirect: {
          name: 'Dashboard.Settings',
        },
      },
      {
        path: 'settings',
        name: 'Dashboard.Settings',
        redirect: {
          name: 'Dashboard.Settings.General',
        },
        component: () => import(/* webpackChunkName: "view-dashboard-settings" */ '@/views/Dashboard/DashboardSettings.vue'),
        children: [
          {
            path: 'general',
            name: 'Dashboard.Settings.General',
            component: () => import(/* webpackChunkName: "view-dashboard-settings-general" */ '@/views/Dashboard/DashboardSettings/DashboardSettingsGeneral.vue'),
            beforeEnter: VueRouterMultiguard([
              AbilityMiddleware({
                action: 'create',
                subject: 'settings-general',
                redirect: 'Dashboard.Settings.AcademicYears',
              }),
              AbilityMiddleware({
                action: 'update',
                subject: 'settings-general',
                redirect: 'Dashboard.Settings.AcademicYears',
              }),
            ]),
          },
          {
            path: 'academic-years',
            name: 'Dashboard.Settings.AcademicYears',
            component: () => import(/* webpackChunkName: "view-dashboard-settings-academic-years" */ '@/views/Dashboard/DashboardSettings/DashboardSettingsAcademicYears.vue'),
            beforeEnter: VueRouterMultiguard([
              AbilityMiddleware({
                action: 'create',
                subject: 'settings-academic-year',
              }),
              AbilityMiddleware({
                action: 'update',
                subject: 'settings-academic-year',
              }),
            ]),
          },
          {
            path: 'training-program',
            name: 'Dashboard.Settings.TrainingProgram',
            component: () => import(/* webpackChunkName: "view-dashboard-settings-training-program" */ '@/views/Dashboard/DashboardSettings/DashboardSettingsTrainingProgram.vue'),
            redirect: { name: 'Dashboard.Settings.TrainingProgram.Home' },
            beforeEnter: VueRouterMultiguard([
              AbilityMiddleware({
                action: 'create',
                subject: 'settings-program',
              }),
              AbilityMiddleware({
                action: 'update',
                subject: 'settings-program',
              }),
            ]),
            children: [
              {
                path: '/',
                name: 'Dashboard.Settings.TrainingProgram.Home',
                component: () => import(/* webpackChunkName: "view-dashboard-settings-training-program" */ '@/views/Dashboard/DashboardSettings/DashboardSettingsTrainingProgram/DashboardSettingsTrainingProgramHome.vue'),
              },
              {
                path: ':id',
                name: 'Dashboard.Settings.TrainingProgram.Subjects',
                component: () => import(/* webpackChunkName: "view-dashboard-settings-training-program" */ '@/views/Dashboard/DashboardSettings/DashboardSettingsTrainingProgram/DashboardSettingsTrainingProgramSubjects.vue'),
              },
            ],
          },
          {
            path: 'teachers',
            name: 'Dashboard.Settings.Teachers',
            component: () => import(/* webpackChunkName: "view-dashboard-settings-teachers" */ '@/views/Dashboard/DashboardSettings/DashboardSettingsTeachers.vue'),
            redirect: { name: 'Dashboard.Settings.Teachers.Home' },
            beforeEnter: VueRouterMultiguard([
              AbilityMiddleware({
                action: 'create',
                subject: 'settings-teacher',
              }),
              AbilityMiddleware({
                action: 'update',
                subject: 'settings-teacher',
              }),
            ]),
            children: [
              {
                path: '/',
                name: 'Dashboard.Settings.Teachers.Home',
                component: () => import(/* webpackChunkName: "view-dashboard-settings-teachers" */ '@/views/Dashboard/DashboardSettings/DashboardSettingsTeachers/DashboardSettingsTeachersHome.vue'),
              },
              {
                path: 'archive',
                name: 'Dashboard.Settings.Teachers.Archive',
                component: () => import(/* webpackChunkName: "view-dashboard-settings-teachers" */ '@/views/Dashboard/DashboardSettings/DashboardSettingsTeachers/DashboardSettingsTeachersArchive.vue'),
              },
              {
                path: 'new',
                name: 'Dashboard.Settings.Teachers.New',
                component: () => import(/* webpackChunkName: "view-dashboard-settings-teachers" */ '@/views/Dashboard/DashboardSettings/DashboardSettingsTeachers/DashboardSettingsTeachersTeacher.vue'),
              },
              {
                path: ':id',
                name: 'Dashboard.Settings.Teachers.Teacher',
                component: () => import(/* webpackChunkName: "view-dashboard-settings-teachers" */ '@/views/Dashboard/DashboardSettings/DashboardSettingsTeachers/DashboardSettingsTeachersTeacher.vue'),
              },
            ],
          },
          {
            path: 'students',
            name: 'Dashboard.Settings.Students',
            component: () => import(/* webpackChunkName: "view-dashboard-settings-students" */ '@/views/Dashboard/DashboardSettings/DashboardSettingsStudents.vue'),
            redirect: { name: 'Dashboard.Settings.Students.Home' },
            beforeEnter: VueRouterMultiguard([
              AbilityMiddleware({
                action: 'create',
                subject: 'settings-student',
              }),
              AbilityMiddleware({
                action: 'update',
                subject: 'settings-student',
              }),
            ]),
            children: [
              {
                path: '/',
                name: 'Dashboard.Settings.Students.Home',
                component: () => import(/* webpackChunkName: "view-dashboard-settings-students" */ '@/views/Dashboard/DashboardSettings/DashboardSettingsStudents/DashboardSettingsStudentsHome.vue'),
              },
              {
                path: 'archive',
                name: 'Dashboard.Settings.Students.Archive',
                component: () => import(/* webpackChunkName: "view-dashboard-settings-students" */ '@/views/Dashboard/DashboardSettings/DashboardSettingsStudents/DashboardSettingsStudentsArchive.vue'),
              },
              {
                path: 'new',
                name: 'Dashboard.Settings.Students.New',
                component: () => import(/* webpackChunkName: "view-dashboard-settings-students" */ '@/views/Dashboard/DashboardSettings/DashboardSettingsStudents/DashboardSettingsStudentsStudent.vue'),
              },
              {
                path: ':id',
                name: 'Dashboard.Settings.Students.Student',
                component: () => import(/* webpackChunkName: "view-dashboard-settings-students" */ '@/views/Dashboard/DashboardSettings/DashboardSettingsStudents/DashboardSettingsStudentsStudent.vue'),
              },
            ],
          },
          {
            path: 'classes-and-groups',
            name: 'Dashboard.Settings.ClassesAndGroups',
            component: () => import(/* webpackChunkName: "view-dashboard-settings-classes-and-groups" */ '@/views/Dashboard/DashboardSettings/DashboardSettingsClassesAndGroups.vue'),
            redirect: { name: 'Dashboard.Settings.ClassesAndGroups.Classes' },
            beforeEnter: VueRouterMultiguard([
              AbilityMiddleware({
                action: 'create',
                subject: 'settings-class',
              }),
              AbilityMiddleware({
                action: 'update',
                subject: 'settings-class',
              }),
              AbilityMiddleware({
                action: 'create',
                subject: 'settings-group',
              }),
              AbilityMiddleware({
                action: 'update',
                subject: 'settings-group',
              }),
            ]),
            children: [
              {
                path: 'classes',
                name: 'Dashboard.Settings.ClassesAndGroups.Classes',
                component: () => import(/* webpackChunkName: "view-dashboard-settings-classes-and-groups" */ '@/views/Dashboard/DashboardSettings/DashboardSettingsClassesAndGroups/DashboardSettingsClassesAndGroupsClasses.vue'),
                redirect: { name: 'Dashboard.Settings.ClassesAndGroups.Classes.Home' },
                children: [
                  {
                    path: '/',
                    name: 'Dashboard.Settings.ClassesAndGroups.Classes.Home',
                    component: () => import(/* webpackChunkName: "view-dashboard-settings-classes-and-groups" */ '@/views/Dashboard/DashboardSettings/DashboardSettingsClassesAndGroups/DashboardSettingsClassesAndGroupsClasses/DashboardSettingsClassesAndGroupsClassesHome.vue'),
                  },
                  {
                    path: ':id',
                    name: 'Dashboard.Settings.ClassesAndGroups.Classes.Class',
                    component: () => import(/* webpackChunkName: "view-dashboard-settings-classes-and-groups" */ '@/views/Dashboard/DashboardSettings/DashboardSettingsClassesAndGroups/DashboardSettingsClassesAndGroupsClasses/DashboardSettingsClassesAndGroupsClassesClass.vue'),
                  },
                ],
              },
              {
                path: 'groups',
                name: 'Dashboard.Settings.ClassesAndGroups.Groups',
                component: () => import(/* webpackChunkName: "view-dashboard-settings-classes-and-groups" */ '@/views/Dashboard/DashboardSettings/DashboardSettingsClassesAndGroups/DashboardSettingsClassesAndGroupsGroups.vue'),
                redirect: { name: 'Dashboard.Settings.ClassesAndGroups.Groups.Home' },
                children: [
                  {
                    path: '/',
                    name: 'Dashboard.Settings.ClassesAndGroups.Groups.Home',
                    component: () => import(/* webpackChunkName: "view-dashboard-settings-classes-and-groups" */ '@/views/Dashboard/DashboardSettings/DashboardSettingsClassesAndGroups/DashboardSettingsClassesAndGroupsGroups/DashboardSettingsClassesAndGroupsGroupsHome.vue'),
                  },
                  {
                    path: 'create',
                    name: 'Dashboard.Settings.ClassesAndGroups.Groups.Create',
                    component: () => import(/* webpackChunkName: "view-dashboard-settings-classes-and-groups" */ '@/views/Dashboard/DashboardSettings/DashboardSettingsClassesAndGroups/DashboardSettingsClassesAndGroupsGroups/DashboardSettingsClassesAndGroupsGroupsCreate.vue'),
                  },
                  {
                    path: ':id',
                    name: 'Dashboard.Settings.ClassesAndGroups.Groups.Group',
                    component: () => import(/* webpackChunkName: "view-dashboard-settings-classes-and-groups" */ '@/views/Dashboard/DashboardSettings/DashboardSettingsClassesAndGroups/DashboardSettingsClassesAndGroupsGroups/DashboardSettingsClassesAndGroupsGroupsGroup.vue'),
                  },
                ],
              },
            ],
          },
          {
            path: 'grading-system',
            name: 'Dashboard.Settings.GradingSystem',
            component: () => import(/* webpackChunkName: "view-dashboard-settings-grading-system" */ '@/views/Dashboard/DashboardSettings/DashboardSettingsGradingSystem.vue'),
            beforeEnter: VueRouterMultiguard([
              AbilityMiddleware({
                action: 'create',
                subject: 'settings-grading-system',
              }),
              AbilityMiddleware({
                action: 'update',
                subject: 'settings-grading-system',
              }),
            ]),
          },
          {
            path: 'moderators',
            name: 'Dashboard.Settings.Moderators',
            component: () => import(/* webpackChunkName: "view-dashboard-settings-moderators" */ '@/views/Dashboard/DashboardSettings/DashboardSettingsModerators.vue'),
            redirect: { name: 'Dashboard.Settings.Moderators.Home' },
            beforeEnter: VueRouterMultiguard([
              AbilityMiddleware({
                action: 'create',
                subject: 'settings-admins',
              }),
            ]),
            children: [
              {
                path: '/',
                name: 'Dashboard.Settings.Moderators.Home',
                component: () => import(/* webpackChunkName: "view-dashboard-settings-moderators" */ '@/views/Dashboard/DashboardSettings/DashboardSettingsModerators/DashboardSettingsModeratorsHome.vue'),
              },
              {
                path: 'archive',
                name: 'Dashboard.Settings.Moderators.Archive',
                component: () => import(/* webpackChunkName: "view-dashboard-settings-moderators" */ '@/views/Dashboard/DashboardSettings/DashboardSettingsModerators/DashboardSettingsModeratorsArchive.vue'),
              },
              {
                path: 'new',
                name: 'Dashboard.Settings.Moderators.New',
                component: () => import(/* webpackChunkName: "view-dashboard-settings-moderators" */ '@/views/Dashboard/DashboardSettings/DashboardSettingsModerators/DashboardSettingsModeratorsModerator.vue'),
              },
              {
                path: ':id',
                name: 'Dashboard.Settings.Moderators.Moderator',
                component: () => import(/* webpackChunkName: "view-dashboard-settings-moderators" */ '@/views/Dashboard/DashboardSettings/DashboardSettingsModerators/DashboardSettingsModeratorsModerator.vue'),
              },
            ],
          },
          {
            path: 'history-log',
            name: 'Dashboard.Settings.HistoryLogs',
            component: () => import(/* webpackChunkName: "view-dashboard-settings-historylog" */ '@/views/Dashboard/DashboardSettings/DashboardSettingsHistoryLogs.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/journal',
    name: 'Journal',
    redirect: { name: 'Journal.Lessons' },
    component: () => import(/* webpackChunkName: "view-journal" */ '@/views/Journal.vue'),
    children: [
      {
        path: 'homeworks',
        name: 'Journal.Homeworks',
        component: () => import(/* webpackChunkName: "view-journal-lesson-hometasks" */ '@/views/Journal/homeworks/JournalHomeworks.vue'),
      },
      {
        path: 'lessons',
        name: 'Journal.Lessons',
        component: () => import(/* webpackChunkName: "view-journal-lessons" */ '@/views/Journal/JournalLessons.vue'),
      },
      {
        path: 'progress-universal',
        name: 'Journal.Progress.Universal',
        component: () => import(/* webpackChunkName: "view-journal-grades" */ '@/views/Journal/JournalProgress/JournalProgressUniversal.vue'),
      },
      {
        path: 'progress',
        name: 'Journal.Progress',
        component: () => import(/* webpackChunkName: "view-journal-progress" */ '@/views/Journal/JournalProgress.vue'),
        redirect: { name: 'Journal.Progress.Home' },
        children: [
          {
            path: '/',
            name: 'Journal.Progress.Home',
            component: () => {
              if (ability.can('access', 'student') || ability.can('access', 'parent')) {
                return import(/* webpackChunkName: "view-journal-progress" */ '@/views/Journal/JournalProgress/JournalProgressUniversal.vue');
              }
              return import(/* webpackChunkName: "view-journal-progress" */ '@/views/Journal/JournalProgress/JournalProgressHome.vue');
            },
          },
          {
            path: 'details',
            name: 'Journal.Progress.Progress',
            component: () => {
              if (ability.can('access', 'student') || ability.can('access', 'parent')) {
                return import(/* webpackChunkName: "view-journal-progress" */ '@/views/Journal/JournalProgress/JournalProgressProgressCustomer.vue');
              }
              return import(/* webpackChunkName: "view-journal-progress" */ '@/views/Journal/JournalProgress/JournalProgressProgress.vue');
            },
            meta: {
              layout: 'fullWidthLayout',
            },
          },
        ],
      },
      {
        path: 'visitings',
        name: 'Journal.Visitings',
        component: () => import(/* webpackChunkName: "view-journal-visitings" */ '@/views/Journal/JournalVisitings.vue'),
      },
      {
        path: 'final-grades',
        name: 'Journal.Final.Grades',
        component: () => import(/* webpackChunkName: "view-journal-grades" */ '@/views/Journal/JournalFinalGrades.vue'),
      },
      {
        path: 'final-grades-list',
        name: 'Journal.Final.Grades.List',
        component: () => import(/* webpackChunkName: "view-journal-grades" */ '@/views/Journal/JournalFinalGrades/FinalGradesList.vue'),
        meta: {
          layout: 'fullWidthLayout',
        },
      },
      {
        path: 'lessons/:id',
        name: 'Journal.Lesson',
        redirect: { name: 'Lesson.Ratings' },
        component: () => import(/* webpackChunkName: "view-journal-lesson" */ '@/views/Journal/JournalLesson.vue'),
        meta: {
          isRouteLesson: true,
        },
        children: [
          {
            path: '/',
            name: 'Lesson.Ratings',
            component: () => import(/* webpackChunkName: "view-journal-lesson-ratings" */ '@/views/Journal/JournalLesson/LessonRatings.vue'),
            meta: {
              isRouteLesson: true,
            },
          },
          {
            path: 'sections-and-themes',
            name: 'Journal.Lesson.SectionAndThemes',
            component: () => import(/* webpackChunkName: "view-journal-lesson-sections-and-themes" */ '@/views/Journal/JournalLesson/JournalLessonSectionAndThemes.vue'),
            meta: {
              isRouteLesson: true,
            },
          },
          {
            path: 'individual',
            name: 'Journal.Lesson.Individual',
            component: () => import(/* webpackChunkName: "view-journal-lesson-individual" */ '@/views/Journal/JournalLesson/JournalLessonIndividual.vue'),
            meta: {
              isRouteLesson: true,
            },
          },
          {
            path: 'hometasks',
            name: 'Journal.Lesson.HomeTasks',
            component: () => import(/* webpackChunkName: "view-journal-lesson-hometasks" */ '@/views/Journal/JournalLesson/JournalLessonHomeTasks.vue'),
            meta: {
              isRouteLesson: true,
            },
          },
        ],
      },
      {
        path: 'ratings',
        name: 'Journal.Ratings',
        component: () => import(/* webpackChunkName: "view-journal-ratings" */ '@/views/Journal/JournalRatings.vue'),
      },
      {
        path: 'homework-class/:id',
        name: 'Journal.Homework.Class',
        component: () => import(/* webpackChunkName: "view-journal-homework-class" */ '@/views/Journal/JournalRatings/JournalHomeworkClass.vue'),
        meta: {
          layout: 'fullWidthLayout',
        },
      },
      {
        path: 'progress-programs',
        name: 'Journal.Programs.Progress',
        component: () => import(/* webpackChunkName: "view-journal-programs-progress" */ '@/views/Journal/JournalProgramsProgress.vue'),
        redirect: { name: 'Journal.Program.Progress.Home' },
        children: [
          {
            path: '/',
            name: 'Journal.Program.Progress.Home',
            component: () => import(/* webpackChunkName: "view-journal-programm-progress" */ '@/views/Journal/JournalProgramProgress/JournalProgramProgressHome.vue'),
          },
          {
            path: 'details',
            name: 'Journal.Program.Progress.Details',
            component: () => import(/* webpackChunkName: "view-journal-program-progress-details" */ '@/views/Journal/JournalProgramProgress/JournalProgramProgressDetails.vue'),
            meta: {
              layout: 'fullWidthLayout',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/reports',
    name: 'Reports',
    redirect: () => {
      if (ability.can('read', 'report-teachers')) {
        return { name: 'Reports.Teachers' };
      }
      return { name: 'Reports.Visits' };
    },
    component: () => import(/* webpackChunkName: "view-reports" */ '@/views/Reports.vue'),
    children: [
      {
        path: 'teachers',
        name: 'Reports.Teachers',
        component: () => import(/* webpackChunkName: "view-reports-teachers" */ '@/views/Reports/ReportsTeachers.vue'),
        beforeEnter: VueRouterMultiguard([
          AbilityMiddleware({
            action: 'read',
            subject: 'report-teachers',
            redirect: 'Reports.Visits',
          }),
        ]),
      },
      {
        path: 'visits',
        name: 'Reports.Visits',
        redirect: { name: 'Reports.Visits.Home' },
        component: {
          template: '<router-view />',
        },
        children: [
          {
            path: '/',
            name: 'Reports.Visits.Home',
            component: () => import(/* webpackChunkName: "view-reports-visits" */ '@/views/Reports/ReportsVisits.vue'),
          },
          {
            path: 'details',
            name: 'Reports.Visits.Details',
            component: () => import(/* webpackChunkName: "view-reports-visits-details" */ '@/views/Reports/ReportsVisitsDetails.vue'),
            meta: {
              layout: 'fullWidthLayout',
            },
          },
        ],
      },
      {
        path: 'statistics',
        name: 'Reports.Statistics',
        redirect: { name: 'Reports.Statistics.Home' },
        component: {
          template: '<router-view />',
        },
        children: [
          {
            path: '/',
            name: 'Reports.Statistics.Home',
            component: () => import(/* webpackChunkName: "view-reports-statistics" */ '@/views/Reports/ReportsStatistics.vue'),
          },
          {
            path: 'details',
            name: 'Reports.Statistics.Details.Visits',
            component: () => import(/* webpackChunkName: "view-reports-statistics-details-visits" */ '@/views/Reports/ReportsStatisticsDetailsVisits.vue'),
            meta: {
              layout: 'fullWidthLayout',
            },
          },
        ],
      },
      {
        path: 'grades',
        name: 'Reports.Grades',
        component: () => import(/* webpackChunkName: "view-reports-grades" */ '@/views/Reports/ReportsGradesDetails.vue'),
      },
      {
        path: 'grades-summary',
        name: 'Reports.Grades.Summary',
        component: () => import(/* webpackChunkName: "view-reports-grades-summary" */ '@/views/Reports/ReportsGradesSummary.vue'),
        // meta: {
        //   layout: 'fullWidthLayout',
        // },
      },
      {
        path: 'teachers-calendar',
        name: 'Reports.Teachers.Calendar',
        component: () => import(/* webpackChunkName: "view-reports-teachers-calendar" */ '@/views/Reports/ReportsTeachersCalendar.vue'),
        meta: {
          layout: 'fullWidthLayout',
        },
        beforeEnter: VueRouterMultiguard([
          AbilityMiddlewareSome([
            { action: 'access', subject: 'admin' },
            { action: 'access', subject: 'moderator' },
          ]),
        ]),
      },
    ],
  },
  {
    path: '/schedule',
    name: 'Schedule',
    redirect: {
      name: 'Schedule.Home',
    },
    meta: {
      layout: 'fullWidthLayout',
    },
    component: () => import(/* webpackChunkName: "view-schedule" */ '@/views/Schedule.vue'),
    children: [
      {
        path: '/',
        name: 'Schedule.Home',
        component: () => import(/* webpackChunkName: "view-schedule-home" */ '@/views/Schedule/ScheduleHome.vue'),
      },
      {
        path: '/schedule-templates',
        name: 'Schedule.Templates',
        component: () => import(/* webpackChunkName: "view-schedule-templates" */ '@/views/Schedule/ScheduleTemplates.vue'),
      },
      {
        path: 'student-lessons/:id',
        name: 'Schedule.Lessons.Lesson',
        component: () => import(/* webpackChunkName: "view-schedule-student-lesson" */ '@/views/Schedule/ScheduleStudentLesson.vue'),
        meta: {
          layout: 'wideLayout',
        },
      },
    ],
  },
  {
    path: '/today',
    name: 'Today',
    component: {
      template: '<router-view />',
    },
    redirect: {
      name: 'Today.Home',
    },
    beforeEnter: (to, from, next) => {
      if (ability.can('access', 'today-page')) {
        next();
      } else {
        next({ name: 'Home' });
      }
    },
    children: [
      {
        path: '/',
        name: 'Today.Home',
        component: () => import(/* webpackChunkName: "view-student-today" */ '@/views/Today/StudentToday.vue'),
        meta: {
          layout: 'wideLayout',
        },
      },
      {
        path: 'student-lessons/:id',
        name: 'Today.Lessons.Lesson',
        component: () => import(/* webpackChunkName: "view-today-student-lesson" */ '@/views/Today/TodayStudentLesson.vue'),
        meta: {
          layout: 'wideLayout',
        },
      },
    ],
  },
  {
    path: '/homeworks',
    name: 'Homeworks',
    component: {
      template: '<router-view />',
    },
    redirect: {
      name: 'Homeworks.Home',
    },
    beforeEnter: (to, from, next) => {
      if (ability.can('access', 'homeworks-page')) {
        next();
      } else {
        next({ name: 'Home' });
      }
    },
    children: [
      {
        path: '/',
        name: 'Homeworks.Home',
        component: () => import(/* webpackChunkName: "view-homeworks" */ '@/views/Homeworks/Homeworks.vue'),
        meta: {
          layout: 'fullWidthLayout',
        },
      },
      {
        path: 'student-lessons/:id',
        name: 'Homeworks.Lessons.Lesson',
        component: () => import(/* webpackChunkName: "view-schedule-student-lesson" */ '@/views/Homeworks/HomeworksStudentLesson.vue'),
        meta: {
          layout: 'wideLayout',
        },
      },
    ],
  },
  {
    path: '*',
    name: '404',
    meta: {
      layout: 'error',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  parseQuery: (query) => qs.parse(query),
  stringifyQuery: (query) => {
    const q = qs.stringify(query, { encodeValuesOnly: true });
    if (q) {
      return `?${q}`;
    }
    return '';
  },
});

router.beforeEach((to, from, next) => {
  Store.commit('pagesQueryCache/SET_LAST_PAGE', to.name);
  clearAllBodyScrollLocks();
  next();
});

router.beforeEach(VueRouterMultiguard([
  AuthMiddleware,
  GuestMiddleware,
]));

export default router;
