import { SET_INITIALIZED } from '@/store/modules/core/mutation-types';

export default {
  namespaced: true,
  state: {
    initialized: false,
  },
  getters: {},
  actions: {
    setInitialized({ commit }, value = true) {
      commit(SET_INITIALIZED, value);
    },
  },
  mutations: {
    SET_INITIALIZED(state, value) {
      state.initialized = !!value;
    },
  },
};
