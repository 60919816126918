import axios from 'axios';
import RequestManager from '@/api/request-manager';

/**
 * Run vue-wait with specific key generated by keyFactory and call asyncCallback
 *
 * @param keyFactory
 * @param asyncCallback
 * @param config
 * @returns {function(...[*]=)}
 */
export const waiting = (
  keyFactory,
  asyncCallback,
  config = {},
) => (context, payload) => {
  const _defaults = { cancel: false };
  // eslint-disable-next-line no-unused-vars, prefer-object-spread
  const _config = Object.assign({}, _defaults, config);
  let key = keyFactory;
  if (typeof key === 'function') {
    key = keyFactory(payload, context);
  }
  const { dispatch, rootGetters } = context;

  let cancelToken;
  if (_config.cancel) {
    cancelToken = RequestManager.cancelAxiosAndGetNextToken(key);
  } else if (typeof rootGetters['wait/is'] === 'function' && rootGetters['wait/is'](key)) {
    return Promise.reject();
  }

  dispatch('wait/start', key, { root: true }).then(() => {});
  return asyncCallback(context, payload, { cancelToken }).then((response) => {
    dispatch('wait/end', key, { root: true }).then(() => {});
    return Promise.resolve(response);
  }).catch((e) => {
    if (!axios.isCancel(e)) {
      dispatch('wait/end', key, { root: true }).then(() => {});
    }
    return Promise.reject(e || `Rejected -> ${key}`);
  });
};

export default waiting;
