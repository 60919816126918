import Vue from 'vue';
import * as AuthRepository from '@/api/auth';
import { waiting } from '@/store/waiting';
import {
  SET_AUTHENTICATION_DATA,
  SET_MY_INFO,
  SET_SCHOOL_AUTH,
  CLEAR_SCHOOL_DATA,
} from '@/store/modules/auth/mutation-types';

export default {
  namespaced: true,
  state: {
    authentication: null,
    me: null,
    tokenSchool: null,
  },
  getters: {
    authenticated: (state) => Boolean(state.authentication),
    authenticatedSchool: (state) => Boolean(state.tokenSchool),
    token: (state) => state.authentication?.access_token || null,
    refreshToken: (state) => state.authentication?.refresh_token || null,
    isRefreshTokenValid: (state, getters) => () => {
      if (!getters.refreshToken) {
        return false;
      }
      const now = new Date();
      const refreshTokenTimeout = state.authentication?._refresh_token_timeout;
      const diff = new Date(refreshTokenTimeout) - now;
      if (diff > 0) {
        return diff;
      }
      return 0;
    },
    getMyInfo: (state) => state.me,
    role: (state) => state.me?.roles[0].name,
    isStudentRole: (state, getters) => ['student', 'parent'].includes(getters.role),
    userStudents(state, getters) {
      if (getters.role === 'parent') {
        return state.me.children;
      }
      if (getters.role === 'student') {
        return [state.me];
      }
      return null;
    },
  },
  actions: {
    authenticate: waiting(
      'login',
      (
        { commit, dispatch, state },
        { phone, password },
      ) => {
        dispatch('active/unsetActiveStudent', null, { root: true });
        return AuthRepository.postLogin(phone, password).then(async ({ data }) => {
          if (data) {
            commit(SET_AUTHENTICATION_DATA, data);
            await dispatch('authenticateSchool', { phone, password });
            return Promise.resolve(state.authentication);
          }
          return Promise.reject();
        });
      },
    ),
    authenticateSchool({ commit }, { phone, password }) {
      AuthRepository.postSchoolLogin(phone, password).then(({ data }) => {
        if (data) {
          commit(SET_SCHOOL_AUTH, data);
          return Promise.resolve(data);
        }
        return Promise.resolve();
      });
    },
    getMyInfo: waiting(
      'fetch.me',
      ({ commit }) => AuthRepository.getMyInfo().then(({ data }) => {
        if (data?.data) {
          commit(SET_MY_INFO, data?.data);
          return Promise.resolve(data?.data);
        }
        return Promise.reject();
      }),
    ),
    refreshToken: waiting(
      'refresh.token',
      ({ getters, commit, state }) => {
        const { refreshToken } = getters;
        if (!refreshToken) {
          return Promise.reject();
        }
        return AuthRepository.refreshToken(refreshToken).then(({ data }) => {
          commit(SET_AUTHENTICATION_DATA, data);
          return Promise.resolve(state.authentication);
        });
      },
    ),
    logout: async ({ commit, dispatch }) => {
      commit(SET_AUTHENTICATION_DATA, null);
      commit(SET_MY_INFO, null);
      commit(CLEAR_SCHOOL_DATA, null);
      commit('pagesQueryCache/CLEAR_ALL_CACHE', null, { root: true });
      dispatch('active/unsetActiveStudent', null, { root: true });
      dispatch('active/clearActiveScheduleSettings', null, { root: true });
      dispatch('main/clearTeacherData', null, { root: true });
      dispatch('schedule/clearScheduleData', null, { root: true });
      dispatch('active/setSelectedAcademicYear', null, { root: true });
      return Promise.resolve();
    },
  },
  mutations: {
    [SET_AUTHENTICATION_DATA](state, payload) {
      if (payload) {
        const now = new Date();
        const accessTokenTtl = payload.access_token_ttl || 0;
        const refreshTokenTtl = payload.refresh_token_ttl || 0;
        const accessTokenTimeout = new Date(now).setSeconds(now.getSeconds() + accessTokenTtl);
        const refreshTokenTimeout = new Date(now).setSeconds(now.getSeconds() + refreshTokenTtl);
        // eslint-disable-next-line no-param-reassign
        payload = {
          ...payload,
          _updated: now,
          _access_token_timeout: new Date(accessTokenTimeout),
          _refresh_token_timeout: new Date(refreshTokenTimeout),
        };
      }
      Vue.set(state, 'authentication', payload);
    },
    [SET_MY_INFO](state, payload) {
      Vue.set(state, 'me', payload);
    },
    [SET_SCHOOL_AUTH](state, payload) {
      Vue.set(state, 'tokenSchool', payload.token);
    },
    [CLEAR_SCHOOL_DATA](state) {
      Vue.set(state, 'tokenSchool', null);
    },
  },
};
