import Vue from 'vue';
import Notifications from 'vue-notification';

Vue.use(Notifications, {
  componentName: 'VueNotifications',
});

const defaults = {
  ignoreDuplicates: true,
  duration: 5000,
};

const toast = {
  warn: (payload) => {
    Vue.notify({
      ...defaults,
      type: 'warn',
      ...payload,
    });
  },
  success: (payload) => {
    Vue.notify({
      ...defaults,
      type: 'success',
      ...payload,
    });
  },
  danger: (payload) => {
    Vue.notify({
      ...defaults,
      type: 'danger',
      duration: 10000,
      ...payload,
    });
  },
  info: (payload) => {
    Vue.notify({
      ...defaults,
      type: 'info',
      ...payload,
    });
  },
};

Vue.prototype.$toast = toast;

export default toast;
