import http from '@/api';

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getRatingsAndVisitings = () => http.get('/api/admin/system-grades/classes');

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getRatingsAndVisitingsByClassId = (id) => http.get(`/api/admin/system-grades/classes/${id}/all-marks`);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getRatingsByClassId = (id) => http.get(`/api/admin/system-grades/classes/${id}/ratings`);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getVisitingsByClassId = (id) => http.get(`/api/admin/system-grades/classes/${id}/visitings`);

/**
 * @param {number} classId
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const putUpdateRatingById = (classId, id, payload) => http.put(
  `/api/admin/system-grades/classes/${classId}/ratings/${id}`,
  payload,
);

/**
 * @param {number} classId
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postCreateRating = (classId, payload) => http.post(
  `/api/admin/system-grades/classes/${classId}/ratings`,
  payload,
);

/**
 * @param {number} classId
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteRatingById = (classId, id) => http.delete(
  `/api/admin/system-grades/classes/${classId}/ratings/${id}`,
);

/**
 * @param {number} classId
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const putUpdateVisitingById = (classId, id, payload) => http.put(
  `/api/admin/system-grades/classes/${classId}/visitings/${id}`,
  payload,
);
