import {
  mapActions, mapGetters, mapState, mapMutations,
} from 'vuex';

export default {
  watch: {
    authenticated: {
      async handler(value) {
        if (value) {
          this.setInitialized(false);
          this.SET_TODAY_CACHE_DATE();
          this.fetchNews();
          this.fetchSavedFilters();
          try {
            await Promise.all([this.getMyInfo(), this.fetchCurrentAcademicYear(), this.fetchSettingsFoo()]);
          } catch (e) {
            console.log('Установите АГ');
          }
          this.fetchDataForSelects();
          this.setInitialized();
        } else {
          this.setInitialized();
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      activeAcademicYearId: (state) => state.active.academicYearId,
      activeStudentId: (state) => state.active.activeStudentId,
      me: (state) => state.auth.me,
    }),
    ...mapGetters({
      getActiveAcademicYear: 'active/getActiveAcademicYear',
      authenticated: 'auth/authenticated',
      role: 'auth/role',
      userStudents: 'auth/userStudents',
    }),
    fetchSettingsFoo() {
      const accessRoles = ['root', 'teacher', 'moderator'];
      if (accessRoles.includes(this.role)) {
        return this.fetchSettings;
      }
      return this.fetchSettingsForCustomer;
    },

  },
  mounted() {
    this.$watch(
      () => [this.authenticated, this.me],
      ([authenticated, me]) => {
        if (authenticated && me) {
          if (this.userStudents) {
            const isForeignStudent = !this.userStudents.map((student) => student.id).includes(this.activeStudentId);
            if ((!this.activeStudentId || isForeignStudent)) {
              this.setActiveStudent(this.userStudents[0].id);
            }
          }
        }
      },
      { immediate: true },
    );
  },
  methods: {
    ...mapActions({
      fetchAcademicYearsList: 'academicYears/fetch',
      getAcademicYearById: 'academicYears/getById',
      setActiveAcademicYear: 'active/setActiveAcademicYear',
      setActiveStudent: 'active/setActiveStudent',
      fetchDashboardInfo: 'main/fetchDashboardInfo',
      fetchCurrentAcademicYear: 'active/fetchCurrentAcademicYear',
      getMyInfo: 'auth/getMyInfo',
      setInitialized: 'core/setInitialized',
      fetchSavedFilters: 'settings/fetchSavedFilters',
      fetchNews: 'news/fetchMessages',
      fetchSubjects: 'trainingPrograms/fetchSubjectsForSelects',
      fetchClasses: 'classes/fetch',
      fetchClassesTypes: 'classes/fetchTypes',
      fetchAudienceLinks: 'schedule/fetchAudienceLinks',
      fetchGroups: 'groups/fetch',
      fetchSettings: 'settings/fetchSettings',
      fetchSettingsForCustomer: 'settings/fetchSettingsForCustomer',
    }),
    ...mapMutations({
      SET_TODAY_CACHE_DATE: 'pagesQueryCache/SET_TODAY_DATE',
    }),
    fetchDataForSelects() {
      this.fetchSubjects();
      this.fetchClasses();
      this.fetchGroups();
      this.fetchClassesTypes();
      this.fetchAudienceLinks();
    },
  },
};
