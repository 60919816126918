<template>
  <div class="flex items-center justify-center flex-col">
    <img
      :style="{width: '100px'}"
      src="@/assets/images/nothing-found-planet@3x.png"
      alt=""
    >
    <div>{{ text }}</div>
  </div>
</template>

<script>

export default {
  name: 'NothingFound',
  props: {
    text: {
      type: String,
      default: 'По данным фильтрам ничего не найденно',
    },
  },
};
</script>

<style scoped>

</style>
