import http from '@/api';
import groupBy from 'lodash.groupby';

const formatUserFilter = (filter) => ({
  query: filter.metadata[0],
  id: filter.id,
  name: filter.name,
  type: filter.type,
});

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSettings = () => http.get('/api/admin/settings');

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSettingsForCustomer = () => http.get('/api/settings');

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getCountriesList = () => http.get('/api/admin/settings/countries');

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getDateTypesList = () => http.get('/api/admin/settings/datetypes');

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getDaysList = () => http.get('/api/admin/settings/days');

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const putUpdateSettings = (payload) => http.put('/api/admin/settings', payload);

/**
 * @param {object} payload
 * @param {object} config
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postUploadLogo = (payload, config = {}) => http.post('/api/admin/settings/upload/logo', payload, config);

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSavedFilters = () => http.get('/api/admin/user_filters')
  .then((response) => groupBy(response.data.map((i) => formatUserFilter(i)), (i) => i.type));

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postNewFilter = (payload) => http.post('/api/admin/user_filters', payload)
  .then(({ data }) => formatUserFilter(data));

/**
 * @param {String} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteFilter = (id) => http.delete(`/api/admin/user_filters/${id}`);
