import http from '@/api';

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getShowPasswordById = (id) => http.get(`/api/admin/parents/${id}/show-password`);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getGeneratePasswordById = (id) => http.get(`/api/admin/parents/${id}/generate-password`);

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postCreate = (payload) => http.post(
  '/api/admin/parents',
  payload,
);

/**
 * @param {number} parentId
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const putUpdate = (parentId, payload) => http.put(
  `/api/admin/parents/${parentId}`,
  payload,
);

/**
 * @param {string} phone
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSearchByPhone = (phone) => http.get(
  '/api/admin/parents/search',
  {
    params: {
      phone,
    },
  },
);

/**
 * @param {number} parentId
 * @param {number} studentId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteById = (parentId, studentId) => http.delete(
  `/api/admin/parents/${parentId}`,
  {
    params: {
      student_id: studentId,
    },
  },
);
