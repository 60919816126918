import Vue from 'vue';
import * as StudentsRepository from '@/api/students';
import * as ParentsRepository from '@/api/students/parents';
import * as UsersRepository from '@/api/users';
import { waiting } from '@/store/waiting';
import {
  SET_STUDENTS_LIST,
  SET_STUDENT_BY_ID,
  DROP_STUDENT_BY_ID,
  UPDATE_STUDENT_BY_ID,
  ADD_STUDENT_TO_LIST,
  SET_STUDENT_PARENT_BY_ID,
  DELETE_STUDENT_PARENT_BY_ID,
  DELETE_STUDENT_BY_ID,
  SET_STUDENT_CLASS,
  DROP_STUDENTS_LIST,
  SET_STUDENTS_ARCHIVE_LIST,
  REMOVE_STUDENT_FROM_ARCHIVE_LIST,
} from '@/store/modules/students/mutation-types';
import orderBy from 'lodash.orderby';

export default {
  namespaced: true,
  state: {
    studentsList: null,
    studentsArchiveList: null,
    students: {},
  },
  getters: {
    getStudentsList: (state) => {
      if (state.studentsList) {
        return orderBy(state.studentsList, 'id', ['desc']);
      }
      return [];
    },
    getStudentsArchiveList: (state) => {
      if (state.studentsArchiveList) {
        return orderBy(state.studentsArchiveList, 'id', ['desc']);
      }
      return [];
    },
    getStudentById: (state) => (id) => state.students[id] || null,
  },
  actions: {
    fetch: waiting(
      'fetch.students',
      ({ commit, rootGetters }) => {
        const payload = {};
        if (rootGetters['active/getActiveAcademicYearId']) {
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        return StudentsRepository.getList(payload).then(({ data }) => {
          if (data) {
            commit(SET_STUDENTS_LIST, data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
    ),
    fetchArchive: waiting(
      'fetch.students.archive',
      ({ commit, rootGetters }) => {
        const payload = {};
        if (rootGetters['active/getActiveAcademicYearId']) {
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        return StudentsRepository.getArchiveList(payload).then(({ data }) => {
          if (data) {
            commit(SET_STUDENTS_ARCHIVE_LIST, data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
    ),
    getById: waiting(
      (id) => `fetch.students.${id}`,
      ({ commit, rootGetters }, id) => {
        const payload = {};
        if (rootGetters['active/getActiveAcademicYearId']) {
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        return StudentsRepository.getById(id, payload).then(({ data }) => {
          if (data) {
            commit(SET_STUDENT_BY_ID, data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
    ),
    getPasswordById: waiting(
      (id) => `fetch.students.${id}.password`,
      ({ commit }, id) => StudentsRepository.getShowPasswordById(id).then(({ data }) => {
        if (data) {
          commit(UPDATE_STUDENT_BY_ID, { id, payload: data });
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    generatePasswordById: waiting(
      (id) => `create.students.${id}.password`,
      ({ commit }, id) => StudentsRepository.getGeneratePasswordById(id).then(({ data }) => {
        if (data) {
          commit(UPDATE_STUDENT_BY_ID, { id, payload: data });
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    update: waiting(
      ({ id }) => `update.students.${id}`,
      ({ commit, rootGetters }, { id, payload }) => {
        if (rootGetters['active/getActiveAcademicYearId']) {
          // eslint-disable-next-line no-param-reassign
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        return StudentsRepository.putUpdate(id, payload).then(async ({ data }) => {
          if (data) {
            commit(SET_STUDENT_BY_ID, data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
    ),
    recoverById: waiting(
      ({ id }) => `recover.students.${id}`,
      ({ commit }, id) => StudentsRepository.getRecoveryById(id).then(({ data }) => {
        if (data) {
          commit(ADD_STUDENT_TO_LIST, data);
          commit(SET_STUDENT_BY_ID, data);
          commit(REMOVE_STUDENT_FROM_ARCHIVE_LIST, id);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    create: waiting(
      'create.students',
      ({ commit, dispatch }, payload) => {
        let classObj;
        if (payload.class) {
          classObj = payload.class;
          // eslint-disable-next-line no-param-reassign
          delete payload.class;
        }
        return StudentsRepository.postCreate(payload).then(async ({ data }) => {
          if (data) {
            if (classObj) {
              await dispatch('classes/attachStudents', { classObj, students: [data.id] }, { root: true });
              // eslint-disable-next-line no-param-reassign
              data.class = classObj;
            }
            commit(ADD_STUDENT_TO_LIST, data);
            commit(SET_STUDENT_BY_ID, data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
    ),
    delete: waiting(
      (id) => `delete.students.${id}`,
      ({ commit }, id) => StudentsRepository.deleteById(id).then(() => {
        commit(DELETE_STUDENT_BY_ID, id);
      }),
    ),
    getParentPasswordById: waiting(
      ({ studentId, parentId }) => {
        if (studentId) {
          return `fetch.students.${studentId}.parent.${parentId}.password`;
        }
        return `fetch.students.parent.${parentId}.password`;
      },
      (
        { commit },
        { studentId, parentId },
      ) => ParentsRepository.getShowPasswordById(parentId).then(({ data }) => {
        if (data) {
          commit(SET_STUDENT_PARENT_BY_ID, { studentId, parentId, payload: data });
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    createParent: waiting(
      ({ studentId }) => `create.students.${studentId}.parent`,
      ({ commit }, { studentId, payload }) => ParentsRepository.postCreate(payload).then(({ data }) => {
        if (data) {
          const { id: parentId } = data;
          commit(SET_STUDENT_PARENT_BY_ID, { studentId, parentId, payload: data });
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    updateParent: waiting(
      ({ parentId }) => `update.parent.${parentId}`,
      ({ commit }, { studentId, parentId, payload }) => ParentsRepository.putUpdate(
        parentId,
        payload,
      ).then(({ data }) => {
        if (data) {
          commit(SET_STUDENT_PARENT_BY_ID, { studentId, parentId, payload: data });
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    deleteParent: waiting(
      ({ studentId, parentId }) => `delete.student.${studentId}.parent.${parentId}`,
      ({ commit }, { studentId, parentId }) => ParentsRepository.deleteById(parentId, studentId).then(() => {
        commit(DELETE_STUDENT_PARENT_BY_ID, { studentId, parentId });
      }),
    ),
    export: waiting(
      'export.students',
      () => UsersRepository.getExport('student'),
    ),
    import: waiting(
      'import.students',
      (context, { payload, config }) => UsersRepository.postImport(
        payload,
        config,
      ).then((data) => Promise.resolve(data))
        .catch((res) => Promise.reject(res.response.data)),
    ),
    updateStudentActivity: waiting(
      'update.student.activity',
      (context, { id, payload }) => StudentsRepository.postUpdateStudentActivity({ id, payload })
        .then(({ data }) => {
          if (data) {
            return Promise.resolve(data.data);
          }
          return Promise.reject();
        }),
    ),
  },
  mutations: {
    [SET_STUDENTS_LIST](state, payload) {
      Vue.set(state, 'studentsList', payload);
    },
    [SET_STUDENTS_ARCHIVE_LIST](state, payload) {
      Vue.set(state, 'studentsArchiveList', payload);
    },
    [DROP_STUDENTS_LIST](state) {
      Vue.set(state, 'studentsList', null);
    },
    [SET_STUDENT_BY_ID](state, payload) {
      const { id } = payload;
      Vue.set(state.students, id, payload);
    },
    [UPDATE_STUDENT_BY_ID](state, { id, payload }) {
      if (state.students[id]) {
        Vue.set(state.students, id, {
          ...state.students[id],
          ...payload,
        });
      }
    },
    [DROP_STUDENT_BY_ID](state, id) {
      Vue.delete(state.students, id);
    },
    [ADD_STUDENT_TO_LIST](state, payload) {
      const { id } = payload;
      if (!state.studentsList) {
        Vue.set(state, 'studentsList', [payload]);
      }
      const foundIndex = state.studentsList.findIndex((o) => parseInt(o.id, 10) === parseInt(id, 10));
      if (foundIndex > -1) {
        state.studentsList.splice(foundIndex, 1, payload);
      } else {
        state.studentsList.push(payload);
      }
    },
    [SET_STUDENT_PARENT_BY_ID](state, { studentId, parentId, payload }) {
      if (state.students[studentId]?.parents) {
        // eslint-disable-next-line max-len
        const foundIndex = state.students[studentId].parents.findIndex((o) => parseInt(o.id, 10) === parseInt(parentId, 10));
        if (foundIndex > -1) {
          state.students[studentId].parents.splice(foundIndex, 1, payload);
        } else {
          state.students[studentId].parents.push(payload);
        }
      }
    },
    [DELETE_STUDENT_PARENT_BY_ID](state, { studentId, parentId }) {
      if (state.students[studentId]?.parents) {
        state.students[studentId].parents = state.students[studentId].parents.filter(
          (p) => parseInt(p.id, 10) !== parseInt(parentId, 10),
        );
      }
      if (state.studentsList) {
        const foundIndex = state.studentsList.findIndex((s) => parseInt(s.id, 10) === parseInt(studentId, 10));
        if (foundIndex > -1) {
          if (state.studentsList[foundIndex]?.parents_count > 0) {
            state.studentsList[foundIndex].parents_count -= 1;
          }
        }
      }
    },
    [DELETE_STUDENT_BY_ID](state, id) {
      if (state.studentsList) {
        state.studentsList = state.studentsList.filter((s) => parseInt(s.id, 10) !== parseInt(id, 10));
      }
      if (state.students[id]) {
        Vue.delete(state.students, id);
      }
    },
    [SET_STUDENT_CLASS](state, { studentId, payload }) {
      if (state.studentsList) {
        const foundIndex = state.studentsList.findIndex((s) => s.id === studentId);
        if (foundIndex > -1) {
          Vue.set(state.studentsList[foundIndex], 'class', payload);
        }
      }
      if (state.students[studentId]) {
        Vue.set(state.students[studentId], 'class', payload);
      }
    },
    [REMOVE_STUDENT_FROM_ARCHIVE_LIST](state, id) {
      if (state.studentsArchiveList) {
        state.studentsArchiveList = state.studentsArchiveList.filter((s) => parseInt(s.id, 10) !== parseInt(id, 10));
      }
    },
    CHANGE_STUDENT_CLASS(state, { id, classObj }) {
      Vue.set(state.students[id], 'class', classObj);
    },
  },
};
