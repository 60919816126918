import http from '@/api';

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getLessonById = (id) => http.get(`api/admin/journal/lesson/${id}`);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getLessonVisitings = (id) => http.get(`api/admin/journal/lesson/${id}/visits/`);

/**
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const putLessonVisiting = (id, payload) => http.put(`api/admin/journal/lesson/${id}/visits/`, payload);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getLessonRatingsByHomework = (id) => http.get(`api/admin/journal/lesson/${id}/grades/homework`);

/**
 * @param {number} lessonId
 * @param {number} taskId
 * @param {number} gradeId
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const putLessonRatingsByHomework = (lessonId, taskId, gradeId, payload) => http.put(`api/admin/journal/lesson/${lessonId}/grades/homework/${taskId}/${gradeId}`, payload);

/**
 * @param {number} lessonId
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const getLessonRatingsIndividuals = (lessonId) => http.get(`api/admin/journal/lesson/${lessonId}/grades/individual-task`);

/**
 * @param {number} lessonId
 * @param {number} taskId
 * @param {number} gradeId
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const putLessonRatingsIndividuals = (lessonId, taskId, gradeId, payload) => http.put(`api/admin/journal/lesson/${lessonId}/grades/individual-task/${taskId}/${gradeId}`, payload);

/**
 * @param {number} lessonId
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const getLessonIndividualTaskList = (lessonId) => http.get(`api/admin/journal/lesson/${lessonId}/tasks/individual`);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getGradesListByLessonId = (id) => http.get(`api/admin/journal/lesson/${id}/grades/lesson`);

/**
 * @param {number} lessonId
 * @param {number} gradeId
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const putGradesForTopic = (lessonId, gradeId, payload) => http.put(`api/admin/journal/lesson/${lessonId}/grades/lesson/${gradeId}`, payload);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getLessonComments = (id) => http.get(`api/admin/journal/lesson/${id}/comments`);

/**
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postCreateComment = (id, payload) => http.post(`api/admin/journal/lesson/${id}/comments`, payload);

/**
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const postCreateCommentBulk = (id, payload) => http.post(`api/admin/journal/lesson/${id}/comments/bulk`, payload);

/**
 * @param {number} lessonId
 * @param {number} commentId
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const putUpdateComment = ({ lessonId, commentId }, payload) => http.put(`api/admin/journal/lesson/${lessonId}/comments/${commentId}`, payload);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getDebtsHomework = (id) => http.get(`api/admin/journal/lesson/${id}/tasks/homework/debts`);

/**
 * @param {number} id
 * @param {object} [payload]
 * @returns {Promise}
 */
export const getHomeworkList = (id, payload) => http.get(`api/admin/journal/lesson/${id}/tasks`, {
  params: payload,
});

/**
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postAddTopicLesson = (id, payload) => http.post(`api/admin/journal/lesson/${id}/topics`, payload);

/**
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const putEditTopicLesson = (id, payload) => http.put(`api/admin/journal/lesson/${id}/topics`, payload);

/**
 * @param {number} lessonId
 * @param {number} topicId
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const deleteTopicLesson = (lessonId, topicId) => http.delete(`/api/admin/journal/lesson/${lessonId}/topics/${topicId}`);

/**
 * @param {number} lessonId
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const deleteBulkTopics = (lessonId, payload) => http.post(`api/admin/journal/lesson/${lessonId}/topics/bulk`, payload);

/**
 * @param {number} lessonId
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const attachTopics = (lessonId, payload) => http.post(`api/admin/journal/lesson/${lessonId}/attach_topics`, payload);

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getLessonTypes = () => http.get('api/admin/journal/lesson/types');

/**
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const createLessonTask = (id, payload) => http.post(`api/admin/journal/lesson/${id}/tasks`, payload);

/**
 * @param {number} lessonId
 * @param {number} homeworkId
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const updateLessonTask = (lessonId, homeworkId, payload) => http.put(`api/admin/journal/lesson/${lessonId}/tasks/${homeworkId}`, payload);

/**
 * @param {number} lessonId
 * @param {number} taskId
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const uploadAttachmentsToTask = (lessonId, taskId, payload) => http.post(`api/admin/journal/lesson/${lessonId}/tasks/${taskId}/upload`, payload);

/**
 * @param {number} lessonId
 * @param {number} taskId
 * @param {number} attachmentId
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const deleteAttachmentsFromTask = (lessonId, taskId, attachmentId) => http.delete(`api/admin/journal/lesson/${lessonId}/tasks/${taskId}/upload/${attachmentId}`);

/**
 * @param {number} lessonId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getIndividualLessons = (lessonId) => http.get(`api/admin/journal/lesson/${lessonId}/individuals`);

/**
 * @param {number} lessonId
 * @param {number} individualsId
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const deleteIndividualLesson = (lessonId, individualsId) => http.delete(`api/admin/journal/lesson/${lessonId}/individuals/${individualsId}`);

/**
 * @param {number} lessonId
 * @param {number} individualLessonId
 * @param {number} gradeId
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const putGradeIndividualLesson = (lessonId, individualLessonId, gradeId, payload) => http.put(`api/admin/journal/lesson/${lessonId}/grades/individual-lesson/${individualLessonId}/${gradeId}`, payload);

/**
 * @param {number} lessonId
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const createIndividualLesson = (lessonId, payload) => http.post(`api/admin/journal/lesson/${lessonId}/individuals`, payload);

/**
 * @param {number} lessonId
 * @param {number} individualsId
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const updateIndividualLesson = (lessonId, individualsId, payload) => http.put(`api/admin/journal/lesson/${lessonId}/individuals/${individualsId}`, payload);

/**
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const putUpdateLesson = ({ id, payload }) => http.put(`/api/admin/journal/lesson/${id}`, payload);

/**
 * @param {number} lessonId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getTabDataGrades = (lessonId) => http.get(`api/admin/journal/lesson/${lessonId}/grades`);

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getLessonsDatesByClassSubject = (payload) => http.get('api/admin/schedules/calendar', {
  params: payload,
});

/**
 * @param {object} payload
 * @param {number} studentId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getStudentCalender = (studentId, payload) => http.get(`api/customer/schedules/${studentId}/calendar`, {
  params: payload,
});

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getStudentLessonById = ({ lessonId, studentId }) => http.get(`api/customer/lesson/${lessonId}`, {
  params: {
    student_id: studentId,
  },
});

/**
 * @param {number} lessonId
 * @param {number} homeworkId
 * @param {number} studentId
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const uploadStudentHomework = (lessonId, homeworkId, studentId, payload) => http.post(`/api/customer/lesson/${lessonId}/${homeworkId}/upload?student_id=${studentId}`, payload);

/**
 * @param {number} lessonId
 * @param {number} taskId
 * @param {number} attachmentId
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const deleteHomeworkAttachment = (lessonId, taskId, attachmentId, payload) => http.delete(`/api/customer/lesson/${lessonId}/${taskId}/upload/${attachmentId}`, {
  params: payload,
});

/**
 * @param {number} lessonId
 * @param {number} taskId
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const deleteHomeTask = (lessonId, taskId) => http.delete(`/api/admin/journal/lesson/${lessonId}/tasks/${taskId}`);

/**
 * @param {number} lessonId
 * @param {number} taskId
 * @param {number} studentId
 * @returns {Promise<AxiosResponse<any>>}
 */

// eslint-disable-next-line max-len
export const downloadAttachmentsByTaskAndStudentForAdmin = ({ lessonId, taskId, studentId }) => http.get(`/api/admin/journal/lesson/${lessonId}/tasks/${taskId}/download/${studentId}/all`);

// eslint-disable-next-line max-len
export const downloadAttachmentsByTeacherForStudent = ({ taskId, payload }) => http.get(`/api/customer/tasks/${taskId}/attachments`, {
  params: payload,
});

// eslint-disable-next-line max-len
export const downloadAttachmentsByStudentForStudent = ({ taskId, payload }) => http.get(`/api/customer/tasks/${taskId}/student/attachments`, {
  params: payload,
});

// eslint-disable-next-line max-len
export const downloadAttachmentsByLessonForAdmin = (lessonId) => http.get(`/api/admin/journal/lesson/${lessonId}/download/all`);

// eslint-disable-next-line max-len
export const downloadAttachmentsByLessonWithoutStudentForAdmin = ({ lessonId, taskId }) => http.get(`/api/admin/journal/lesson/${lessonId}/tasks/${taskId}/download/all`);

// eslint-disable-next-line max-len
export const downloadAttachmentsByLessonForStudent = (lessonId) => http.get(`/api/customer/lesson/${lessonId}/download/all`);

/**
 * @param {number} lessonId
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const uploadLessonAttachment = (lessonId, payload) => http.post(`/api/admin/journal/lesson/${lessonId}/upload`, payload);

/**
 * @param {number} lessonId
 * @param {number} attachmentId
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const deleteLessonAttachment = (lessonId, attachmentId) => http.delete(`/api/admin/journal/lesson/${lessonId}/upload/${attachmentId}`);

/**
 * @param {Object} path
 * @param {Object} payload
 * @returns {Promise}
 */
export const updateStudentTaskStatus = ({ lessonId, taskId }, payload) => (
  http.patch(`/api/admin/journal/lesson/${lessonId}/tasks/${taskId}/change_status`, payload));
