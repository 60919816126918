import Vue from 'vue';

/* eslint-disable no-param-reassign */
Vue.directive('deploy', (el, binding) => {
  el.style.overflow = 'hidden';
  setTimeout(() => {
    if (!el.offsetHeight) return;
    if (!binding.arg && !el.dataset.sourceHeight) {
      el.dataset.sourceHeight = el.offsetHeight;
    }
    // eslint-disable-next-line no-nested-ternary
    el.style.height = `${binding.value ? el.scrollHeight
      : el.dataset.sourceHeight ? el.dataset.sourceHeight : binding.arg || 0}px`;
  });
});
