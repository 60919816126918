<template>
  <div />
</template>

<script>
import Pusher from 'pusher-js';
import { mapState, mapGetters } from 'vuex';

export default {
  data() {
    return {
      pusher: null,
      presenceProgressQueueJobChannel: null,
    };
  },
  computed: {
    ...mapState({
      settings: (store) => store.settings?.settings,
    }),
    ...mapGetters({
      authenticated: 'auth/authenticated',
    }),
    pusherSettings() {
      return this.settings?.websocket?.connections?.pusher;
    },
  },
  methods: {
    onClean() {
      this.$toast.success({ group: 'ws', clean: true });
      this.$toast.danger({ group: 'ws', clean: true });
      this.$toast.info({ group: 'ws', clean: true });
    },
    onEvent(progressEvent) {
      try {
        const { state, percent, message } = progressEvent;
        this.onClean();
        if (state === 'error') {
          this.$toast.error({
            group: 'ws',
            text: message,
            duration: 5000,
          });
          return;
        }
        if (state === 'done' || percent?.current >= 100) {
          this.$toast.success({
            group: 'ws',
            text: message,
            duration: 5000,
            data: progressEvent,
          });
          return;
        }
        this.$toast.info({
          group: 'ws',
          text: message,
          duration: -1,
          data: progressEvent,
        });
      } catch (err) {
        console.error(err);
      }
    },
  },
  mounted() {
    this.$watch(
      () => [this.authenticated, this.pusherSettings],
      // eslint-disable-next-line no-unused-vars
      async ([authenticated, pusherSettings]) => {
        if (authenticated && pusherSettings && !this.pusher) {
          const token = this.$store.getters['auth/token'];
          if (token) {
            // eslint-disable-next-line no-param-reassign
            this.pusher = new Pusher(pusherSettings.key, {
              cluster: pusherSettings.options?.cluster,
              wsHost: pusherSettings.options?.host || window.location.host,
              wsPort: pusherSettings.options?.port,
              wssPort: pusherSettings.options?.port,
              forceTLS: false,
              useTLS: false,
              enableStats: false,
              auth: {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
              authEndpoint: pusherSettings?.user_auth_uri,
            });
            this.presenceProgressQueueJobChannel = this.pusher.subscribe('progress-queue-job');
            this.presenceProgressQueueJobChannel.bind('progress.event', (e) => {
              this.onEvent(e);
            });
          }
        }
      },
      { immediate: true },
    );
  },
};
</script>
