import Vue from 'vue';
import * as ClassesRepository from '@/api/classes';
import { waiting } from '@/store/waiting';
import {
  SET_CLASSES_LIST,
  SET_TYPES,
  ADD_CLASS_TO_LIST,
  REMOVE_CLASS_FROM_LIST,
  SET_CLASS_BY_ID,
  SET_CLASS_STUDENTS,
  SET_CLASS_GROUPS,
  REMOVE_STUDENT_FROM_CLASS,
  ADD_STUDENT_TO_CLASS_GROUP,
  REMOVE_STUDENT_FROM_CLASS_GROUP,
  ADD_CLASS_GROUP,
  REMOVE_CLASS_GROUP,
  SET_CLASS_SUBJECTS,
} from '@/store/modules/classes/mutation-types';
import {
  SET_STUDENT_CLASS,
} from '@/store/modules/students/mutation-types';

export default {
  namespaced: true,
  state: {
    classesList: null,
    classes: {},
    classesStudents: {},
    classesGroups: {},
    classesSubjects: {},
    types: null,
  },
  getters: {
    getClassesList: (state) => {
      if (state.classesList?.data) {
        return state.classesList.data;
      }
      if (state.classesList) {
        return state.classesList;
      }
      return [];
    },
    getClassById: (state, getters, rootState, rootGetters) => (id) => state.classes[id]
      || state.classesList?.data?.find((c) => c.id === id)
      || state.classesList?.find((c) => c.id === id)
      || rootGetters['auth/getMyInfo']?.children?.map((c) => c?.class).filter(Boolean).find((c) => c.id === id)
      || null,
    getClassNumbers: (state) => state.types?.class_numbers || [],
    getClassLetters: (state) => state.types?.class_letters || [],
    getStudentsByClassId: (state) => (id) => state.classesStudents[id] || [],
    getGroupsByClassId: (state) => (id) => state.classesGroups[id] || [],
    getSubjectsByClassId: (state) => (id) => state.classesSubjects[id] || [],
    getClassesStudents: (state) => {
      const classesStudents = {};
      Object.keys(state.classes).forEach((key) => {
        classesStudents[key] = state.classes[key].students;
      });
      return classesStudents;
    },
  },
  actions: {
    fetch: waiting(
      'fetch.classes',
      ({ commit, rootGetters }) => {
        const payload = {};
        if (rootGetters['active/getActiveAcademicYearId']) {
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        return ClassesRepository.getList(payload).then(({ data }) => {
          if (data) {
            commit(SET_CLASSES_LIST, data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
      { cancel: true },
    ),
    fetchTypes: waiting(
      'fetch.classes.types',
      ({ commit }) => ClassesRepository.getTypes().then(({ data }) => {
        if (data) {
          commit(SET_TYPES, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    create: waiting(
      'create.classes',
      ({ commit, rootGetters }, payload) => {
        if (rootGetters['active/getActiveAcademicYearId']) {
          // eslint-disable-next-line no-param-reassign
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        return ClassesRepository.postCreate(payload).then(({ data }) => {
          if (data) {
            commit(ADD_CLASS_TO_LIST, data);
            return Promise.resolve();
          }
          return Promise.reject();
        });
      },
    ),
    delete: waiting(
      (id) => `delete.classes.${id}`,
      ({ commit }, id) => ClassesRepository.deleteById(id).then(() => {
        commit(REMOVE_CLASS_FROM_LIST, id);
        return Promise.resolve();
      }),
    ),
    update: waiting(
      ({ id }) => `update.classes.${id}`,
      ({ commit }, { id, payload }) => ClassesRepository.putUpdate(id, payload).then(({ data }) => {
        if (data) {
          commit(ADD_CLASS_TO_LIST, data);
          commit(SET_CLASS_BY_ID, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    attachStudents: waiting(
      ({ classObj }) => `attach.classes.${classObj.id}.students`,
      ({ rootGetters, commit }, { classObj, students }) => {
        const { id: classId } = classObj;
        const payload = { students };
        if (rootGetters['active/getActiveAcademicYearId']) {
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        return ClassesRepository.postAttachStudents(classId, payload).then(() => {
          students.forEach((studentId) => {
            commit(`students/${SET_STUDENT_CLASS}`, { studentId, payload: classObj }, { root: true });
          });
          return Promise.resolve();
        });
      },
    ),
    detachStudents: waiting(
      ({ classObj }) => `detach.classes.${classObj.id}.students`,
      ({ commit, rootGetters }, { classObj, students }) => {
        const { id: classId } = classObj;
        const payload = { students };
        if (rootGetters['active/getActiveAcademicYearId']) {
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        return ClassesRepository.postDetachStudents(classId, payload).then(() => {
          students.forEach((id) => {
            commit(REMOVE_STUDENT_FROM_CLASS, { classId, studentId: id });
          });
        });
      },
    ),
    getById: waiting(
      (id) => `fetch.classes.${id}`,
      ({ commit }, id) => ClassesRepository.getById(id).then(({ data }) => {
        if (data) {
          commit(SET_CLASS_BY_ID, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    getClassStudents: waiting(
      (id) => `fetch.classes.${id}.students`,
      ({ commit, rootGetters }, id) => {
        const payload = {};
        if (rootGetters['active/getActiveAcademicYearId']) {
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        return ClassesRepository.getStudentsByClassId(id, payload).then(({ data }) => {
          if (data) {
            commit(SET_CLASS_STUDENTS, { classId: id, payload: data });
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
    ),
    getClassGroups: waiting(
      (id) => `fetch.classes.${id}.groups`,
      ({ commit }, id) => ClassesRepository.getGroupsByClassId(id).then(({ data }) => {
        if (data) {
          commit(SET_CLASS_GROUPS, { classId: id, payload: data });
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    getClassSubjects: waiting(
      (id) => `fetch.subjects.${id}.subjects`,
      ({ commit }, id) => ClassesRepository.getSubjectsByClassId(id).then(({ data }) => {
        if (data) {
          commit(SET_CLASS_SUBJECTS, { classId: id, payload: data });
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    createClassNumber: waiting(
      'create.class.number',
      (context, payload) => ClassesRepository.postCreateNumber(payload).then(({ data }) => {
        if (data) {
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    createClassLetter: waiting(
      'create.class.letter',
      (context, payload) => ClassesRepository.postCreateLetter(payload).then(({ data }) => {
        if (data) {
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
  },
  mutations: {
    [SET_CLASSES_LIST](state, payload) {
      Vue.set(state, 'classesList', payload);
    },
    [SET_TYPES](state, payload) {
      Vue.set(state, 'types', payload);
    },
    [ADD_CLASS_TO_LIST](state, payload) {
      if (!state.classesList) {
        Vue.set(state, 'classesList', { data: [payload] });
      } else {
        const { id: classId } = payload;
        const foundIndex = state.classesList.data.findIndex((o) => parseInt(o.id, 10) === parseInt(classId, 10));
        if (foundIndex > -1) {
          state.classesList.data.splice(foundIndex, 1, payload);
        } else {
          state.classesList.data.push(payload);
        }
      }
    },
    [REMOVE_CLASS_FROM_LIST](state, id) {
      if (state.classesList?.data) {
        state.classesList.data = state.classesList.data.filter(
          (c) => parseInt(c.id, 10) !== parseInt(id, 10),
        );
      }
    },
    [SET_CLASS_BY_ID](state, payload) {
      const { id } = payload;
      Vue.set(state.classes, id, payload);
    },
    [SET_CLASS_STUDENTS](state, { classId, payload }) {
      Vue.set(state.classesStudents, classId, payload);
    },
    [SET_CLASS_GROUPS](state, { classId, payload }) {
      Vue.set(state.classesGroups, classId, payload);
    },
    [SET_CLASS_SUBJECTS](state, { classId, payload }) {
      Vue.set(state.classesSubjects, classId, payload);
    },
    [REMOVE_STUDENT_FROM_CLASS](state, { classId, studentId }) {
      if (state.classesStudents[classId]) {
        state.classesStudents[classId] = state.classesStudents[classId].filter((s) => s.id !== studentId);
      }
    },
    [ADD_STUDENT_TO_CLASS_GROUP](state, { classId, subgroupId, student }) {
      if (state.classesGroups[classId]) {
        const { id: studentId } = student;
        const foundSubgroupIndex = state.classesGroups[classId].findIndex((g) => g.id === subgroupId);
        if (foundSubgroupIndex > -1 && state.classesGroups[classId][foundSubgroupIndex].students) {
          const foundSubgroupStudentIndex = state.classesGroups[classId][foundSubgroupIndex].students.findIndex(
            (s) => s.id === studentId,
          );
          if (foundSubgroupStudentIndex > -1) {
            // eslint-disable-next-line max-len
            state.classesGroups[classId][foundSubgroupIndex].students = state.classesGroups[classId][foundSubgroupIndex].students.splice(foundSubgroupStudentIndex, 1, student);
          } else {
            state.classesGroups[classId][foundSubgroupIndex].students.push(student);
          }
        }
      }
    },
    [REMOVE_STUDENT_FROM_CLASS_GROUP](state, { classId, subgroupId, student }) {
      if (state.classesGroups[classId]) {
        const { id: studentId } = student;
        const foundSubgroupIndex = state.classesGroups[classId].findIndex((g) => g.id === subgroupId);
        if (foundSubgroupIndex > -1 && state.classesGroups[classId][foundSubgroupIndex].students) {
          // eslint-disable-next-line max-len
          state.classesGroups[classId][foundSubgroupIndex].students = state.classesGroups[classId][foundSubgroupIndex].students.filter(
            (s) => s.id !== studentId,
          );
        }
      }
    },
    [ADD_CLASS_GROUP](state, { classId, group }) {
      if (state.classesGroups[classId]) {
        const { id: groupId } = group;
        const foundSubgroupIndex = state.classesGroups[classId].findIndex((g) => g.id === groupId);
        if (foundSubgroupIndex > -1) {
          state.classesGroups[classId][foundSubgroupIndex] = state.classesGroups[classId][foundSubgroupIndex].splice(
            foundSubgroupIndex,
            1,
            group,
          );
        } else {
          state.classesGroups[classId].push(group);
        }
      }
    },
    [REMOVE_CLASS_GROUP](state, { classId, groupId }) {
      if (state.classesGroups[classId]) {
        state.classesGroups[classId] = state.classesGroups[classId].filter((g) => g.id !== groupId);
      }
    },
  },
};
