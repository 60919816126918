import Vue from 'vue';
import { AbilityBuilder, Ability } from '@casl/ability';
import { abilitiesPlugin } from '@casl/vue';

export const ability = new Ability();

/**
 * @description
 * root | Супер-администратор
 * moderator | Модератор
 * teacher | Учитель
 * student | Ученик
 * parent | Родитель
 */

const defineAbilities = (store) => {
  const { rules, can } = new AbilityBuilder();
  if (store.getters['auth/authenticated']) {
    const { roles: userRoles } = store.state.auth.me;
    const roles = userRoles.map((r) => r.name);
    if (roles.includes('root')) {
      can('access', 'admin'); // админ вошел
      can('api', 'admin'); // отправлять  запросы на api/admin/
      // Настройки
      can('create', 'settings-general'); // создавать филиал
      can('update', 'settings-general'); // редактировать настройки филиала
      can('create', 'settings-academic-year'); // создавать учебный год
      can('update', 'settings-academic-year'); // редактировать учебный год
      can('create', 'settings-program'); // создавать программу
      can('update', 'settings-program'); // редактировать программу
      can('create', 'settings-student'); // создавать студента
      can('update', 'settings-student'); // редактировать студента
      can('create', 'settings-teacher'); // создавать учителя
      can('update', 'settings-teacher'); // редактировать учителя
      can('create', 'settings-class'); // создавать класс
      can('update', 'settings-class'); // редактировать класс
      can('create', 'settings-group'); // создавать группу
      can('update', 'settings-group'); // редактировать группу
      can('create', 'settings-grading-system'); // создавать систему оценивания
      can('update', 'settings-grading-system'); // редактировать систему оценивания
      can('create', 'settings-admins'); // создавать модераторов
      // Расписание
      can('replace', 'teacher'); // ставить на замену учителя
      can('copy', 'schedule'); // копировать расписание
      can('create', 'schedule-template'); // создавать шаблон расписания
      can('create', 'lesson'); // создавать урок
      can('update', 'lesson'); // редактировать урок
      can('delete', 'lesson'); // удалять урок
      can('create', 'lesson-topic'); // создавать тему
      can('update', 'lesson-topic'); // редактировать тему
      can('create', 'individual-lesson'); // создавать индивидуальный урок
      can('update', 'individual-lesson'); // редактировать индивидуальный урок
      can('create', 'homework'); // создавать дз
      can('update', 'homework'); // редактировать дз
      can('create', 'control-work'); // создавать контрольную работу
      can('update', 'control-work'); // редактировать контрольную работу
      can('set', 'lesson-visitings'); // выставлять посещение в уроке
      can('set', 'lesson-grades'); // выставлять оценки за тему в уроке
      can('set', 'homework-grades'); // выставлять оценки за дз в уроке
      can('set', 'individual-grades'); // выставлять оценки за инд.урок
      can('set', 'lesson-comment'); // добавлять коммент к уроку
      can('attach', 'homework-files'); // прикреплять файлы к дз
      // Журнал
      can('fill', 'journal-progress'); // заполнять прогресс
      can('fill', 'journal-visiting'); // заполнять посещение
      can('set', 'journal-grades-and-homeworks'); // выставлять оценки и дз
      can('create', 'final-grades'); // создавать итоговую оценку
      can('set', 'final-grades'); // выставить итоговую оценку
      // Отчеты
      can('read', 'report-teachers'); // просмотр отчета по учителям
      can('fill', 'report-visiting'); // заполнение отчета по посещениям
      can('export', 'report-visiting'); // экспорт отчета по посещениям
      can('export', 'report-statistics'); // экспорт отчета по статистике
      can('export', 'report-grades'); // экспорт отчета по оценкам
    }
    if (roles.includes('moderator')) {
      can('access', 'moderator'); // модератор вошел
      can('api', 'admin'); // отправлять  запросы на api/admin/
      // Настройки
      can('create', 'settings-academic-year'); // создавать учебный год
      can('update', 'settings-academic-year'); // редактировать учебный год
      can('create', 'settings-program'); // создавать программу
      can('update', 'settings-program'); // редактировать программу
      can('create', 'settings-student'); // создавать студента
      can('update', 'settings-student'); // редактировать студента
      can('create', 'settings-teacher'); // создавать учителя
      can('update', 'settings-teacher'); // редактировать учителя
      can('create', 'settings-class'); // создавать класс
      can('update', 'settings-class'); // редактировать класс
      can('create', 'settings-group'); // создавать группу
      can('update', 'settings-group'); // редактировать группу
      can('create', 'settings-grading-system'); // создавать систему оценивания
      can('update', 'settings-grading-system'); // редактировать систему оценивания
      // Расписание
      can('replace', 'teacher'); // ставить на замену учителя
      can('copy', 'schedule'); // копировать расписание
      can('create', 'schedule-template'); // создавать шаблон расписания
      can('create', 'lesson'); // создавать урок
      can('update', 'lesson'); // редактировать урок
      can('delete', 'lesson'); // удалять урок
      can('create', 'lesson-topic'); // создавать тему
      can('update', 'lesson-topic'); // редактировать тему
      can('create', 'individual-lesson'); // создавать индивидуальный урок
      can('update', 'individual-lesson'); // редактировать индивидуальный урок
      can('create', 'homework'); // создавать дз
      can('update', 'homework'); // редактировать дз
      can('create', 'control-work'); // создавать контрольную работу
      can('update', 'control-work'); // редактировать контрольную работу
      can('set', 'lesson-visitings'); // выставлять посещение в уроке
      can('set', 'lesson-grades'); // выставлять оценки за тему в уроке
      can('set', 'homework-grades'); // выставлять оценки за дз в уроке
      can('set', 'individual-grades'); // выставлять оценки за инд.урок
      can('set', 'lesson-comment'); // добавлять коммент к уроку
      can('attach', 'homework-files'); // прикреплять файлы к дз
      // Журнал
      can('fill', 'journal-progress'); // заполнять прогресс
      can('fill', 'journal-visiting'); // заполнять посещение
      can('set', 'journal-grades-and-homeworks'); // выставлять оценки и дз
      can('create', 'final-grades'); // создавать итоговую оценку
      can('set', 'final-grades'); // выставить итоговую оценку
      // Отчеты
      can('read', 'report-teachers'); // просмотр отчета по учителям
      can('fill', 'report-visiting'); // заполнение отчета по посещениям
      can('export', 'report-visiting'); // экспорт отчета по посещениям
      can('export', 'report-statistics'); // экспорт отчета по статистике
      can('export', 'report-grades'); // экспорт отчета по оценкам
    }
    if (roles.includes('teacher')) {
      can('access', 'teacher'); // учитель вошел
      can('access', 'homeworks-page'); // доступ к странице заданий
      can('api', 'admin'); // отправлять  запросы на api/admin/
      // Расписание
      can('create', 'lesson-topic'); // создавать тему
      can('update', 'lesson-topic'); // редактировать тему
      can('create', 'individual-lesson'); // создавать индивидуальный урок
      can('update', 'individual-lesson'); // редактировать индивидуальный урок
      can('create', 'homework'); // создавать дз
      can('update', 'homework'); // редактировать дз
      can('create', 'control-work'); // создавать контрольную работу
      can('update', 'control-work'); // редактировать контрольную работу
      can('set', 'lesson-visitings'); // выставлять посещение в уроке
      can('set', 'lesson-grades'); // выставлять оценки за тему в уроке
      can('set', 'homework-grades'); // выставлять оценки за дз в уроке
      can('set', 'individual-grades'); // выставлять оценки за инд.урок
      can('set', 'lesson-comment'); // добавлять коммент к уроку
      can('attach', 'homework-files'); // прикреплять файлы к дз
      // Журнал
      can('fill', 'journal-progress'); // заполнять прогресс
      can('fill', 'journal-visiting'); // заполнять посещение
      can('set', 'journal-grades-and-homeworks'); // выставлять оценки и дз
      can('create', 'final-grades'); // создавать итоговую оценку
      can('set', 'final-grades'); // выставить итоговую оценку
      // Отчеты
      can('fill', 'report-visiting'); // заполнение отчета по посещениям
    }
    if (roles.includes('student')) {
      can('access', 'student'); // общее определение роли
      can('access', 'today-page'); // доступ к странице сегодня
      can('access', 'homeworks-page'); // доступ к странице домоших заданий
    }
    if (roles.includes('parent')) {
      can('access', 'parent'); // общее определение роли
      can('access', 'today-page'); // доступ к странице сегодня
      can('access', 'homeworks-page'); // доступ к странице домоших заданий
    }
  }
  return rules;
};

export const updateAbilities = (store) => {
  ability.update(defineAbilities(store));
};

export const CaslVuex = (store) => store.watch(
  (state) => state.auth.me,
  () => {
    updateAbilities(store);
  },
  { immediate: true },
);

Vue.use(abilitiesPlugin, ability);
