import http from '@/api';

/**
 * @param {number} trainingProgramId
 * @param {number} subjectId
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postCreate = (trainingProgramId, subjectId, payload) => http.post(
  `/api/admin/training_programs/${trainingProgramId}/subjects/${subjectId}/sections`,
  payload,
);

/**
 * @param {number} trainingProgramId
 * @param {number} subjectId
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postCreateBatch = (trainingProgramId, subjectId, payload) => http.post(
  `/api/admin/training_programs/${trainingProgramId}/subjects/${subjectId}/sections-batch`,
  payload,
);

/**
 * @param {number} trainingProgramId
 * @param {number} subjectId
 * @param {number} sectionId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteById = (trainingProgramId, subjectId, sectionId) => http.delete(
  `/api/admin/training_programs/${trainingProgramId}/subjects/${subjectId}/sections/${sectionId}`,
);

/**
 * @param {number} trainingProgramId
 * @param {number} subjectId
 * @param {number} sectionId
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const putUpdate = (trainingProgramId, subjectId, sectionId, payload) => http.put(
  `/api/admin/training_programs/${trainingProgramId}/subjects/${subjectId}/sections/${sectionId}`,
  payload,
);
