import Vue from 'vue';
import { waiting } from '@/store/waiting';
import * as LessonRepository from '@/api/lesson';
import * as TasksRepository from '@/api/tasks';
import {
  ADD_PROGRESS_GRADES,
} from '@/store/modules/journal/mutation-types';
import { classSortFn, modifyPersonFields } from '@/utils/index';
import groupBy from 'lodash.groupby';
import orderBy from 'lodash.orderby';
import { TEST_GRADE_TYPES } from '@/catalog';
import uniqBy from 'lodash.uniqby';
import toast from '@/notify';

export default {
  namespaced: true,
  state: {
    lesson: null,
    homeworksList: null,
    tasksByHomework: null,
    lessonVisitings: null,
    lessonRatingsHomework: null,
    individualLessons: null,
    lessonTabDataGrades: null,
    lessonTypes: null,
    lessonsDatesByClassSubject: null,
    studentLesson: null,
  },
  getters: {
    getLessonById: (state) => state.lesson,
    getLessonRatingsHomework: (state) => state.lessonRatingsHomework,
    getLessonTabDataGrades: (state) => state.lessonTabDataGrades,
    getIndividualLessons: (state) => state.individualLessons,
    getLessonTypes: (state) => {
      if (state.lessonTypes) {
        return state.lessonTypes;
      }
      return [];
    },
    getLessonTopics(state) {
      const { true: regular, false: control } = groupBy(state.lesson?.lesson_topics,
        (t) => t.lesson_type.type === 'regular');
      return { regular, control };
    },
    getHomeworksWithTasks(state) {
      return state.homeworksList?.map((homework) => ({ homework, tasks: state.tasksByHomework?.[homework.id] }));
    },
    getLessonComments(state) {
      return state.lessonTabDataGrades?.comments;
    },
    getLessonsStudents: (state, getters) => {
      if (!getters.getVisits) return null;
      const students = getters.getVisits.map((visit) => ({
        ...modifyPersonFields(visit.student),
        class: visit.student.class,
      }));
      return orderBy(uniqBy(students, 'id'), 'fullName');
    },
    getVisits(state) {
      return state.lessonTabDataGrades?.visits.map((visit) => ({
        visiting_setting: visit.visiting_setting, student: { ...visit.student, class: visit.class },
      }));
    },
    getTopicsGrades(state) {
      const topicGrades = state.lessonTabDataGrades?.topic_grades.filter((grade) => grade.grade_type.type === 'topic_lesson');
      const groupedGradesByTopics = groupBy(topicGrades, (grade) => grade.topic.id);
      return Object.keys(groupedGradesByTopics).map((key) => ({
        topic: topicGrades.find((grade) => grade.topic.id === +key).topic, grades: groupedGradesByTopics[key],
      }));
    },
    getTestGrades(state) {
      const topicGrades = state.lessonTabDataGrades?.topic_grades.filter((grade) => (
        TEST_GRADE_TYPES.map((type) => type.type).includes(grade.grade_type.type)
      ));
      const groupedGradesByTopics = groupBy(topicGrades, (grade) => grade.topic.id);
      return Object.keys(groupedGradesByTopics).map((key) => ({
        topic: topicGrades.find((grade) => grade.topic.id === +key).topic, grades: groupedGradesByTopics[key],
      }));
    },
    getIndividualGrades(state) {
      return state.lessonTabDataGrades?.individual_grades;
    },
    getIsGroupLesson(state) {
      return !!state.lesson.schedule.schedule_subgroups?.length;
    },
    getLessonGradesByTopic(state) {
      const gradesGroupedByTopic = groupBy(state.lessonTabDataGrades?.topic_grades, (grade) => grade.topic.id);
      return Object.keys(gradesGroupedByTopic).map((key) => ({ topicId: key, grades: gradesGroupedByTopic[key] }));
    },
    getSubgroups(state, getters) {
      if (!getters.getLessonsStudents) return null;
      const classes = getters.getLessonsStudents.map((student) => student.class).filter((c) => c);
      const uniqClasses = uniqBy(classes, 'id');
      return uniqClasses.sort((a, b) => classSortFn(a, b));
    },
    getIsTabDataReceived(state) {
      return !!state.lessonTabDataGrades;
    },
  },
  actions: {
    fetchUpdatedTask: waiting(
      (payload) => `fetch.teacher.task.${payload.studentTaskId}`,
      (store, { payload }) => TasksRepository.getTasks(payload),
    ),
    fetchLessonById: waiting(
      (lessonId) => `fetch.lesson.${lessonId}`,
      ({ commit }, id) => LessonRepository.getLessonById(id).then(({ data }) => {
        if (data) {
          commit('SET_LESSON', data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    fetchStudentLessonById: waiting(
      (lessonId) => `fetch.student.lesson.${lessonId}`,
      // eslint-disable-next-line max-len
      ({ commit, rootState }, lessonId) => LessonRepository.getStudentLessonById({ studentId: rootState.active.activeStudentId, lessonId })
        .then(({ data }) => {
          if (data) {
            commit('SET_STUDENT_LESSON_BY_ID', data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        }),
    ),
    fetchLessonRatingsHomework: waiting(
      'fetch.lesson.ratings',
      ({ commit }, lessonId) => LessonRepository.getLessonRatingsByHomework(lessonId).then(({ data }) => {
        if (data) {
          commit('SET_LESSON_RATINGS_HOMEWORK', data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    editLessonRatingsHomework: waiting(
      ({ gradeId }) => `edit.lesson.ratings.${gradeId}`,
      ({ commit }, {
        lessonId, taskId, gradeId, payload,
      }) => LessonRepository.putLessonRatingsByHomework(lessonId, taskId, gradeId, payload).then(({ data }) => {
        if (data) {
          commit('ADD_LESSON_RATINGS_HOMEWORK', data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    editLessonRatingsIndividuals: waiting(
      ({ lessonId }) => `edit.lesson.ratings.individuals.${lessonId}`,
      ({ commit }, {
        lessonId, taskId, gradeId, payload,
      }) => LessonRepository.putLessonRatingsIndividuals(lessonId, taskId, gradeId, payload)
        .then(({ data }) => {
          if (data) {
            commit('ADD_LESSON_RATINGS_INDIVIDUALS', data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        }),
    ),
    updateStudentTaskStatus: waiting(
      ({ studentTaskStatus, path }) => `update.student.task.status${studentTaskStatus.studentId}${path.taskId}`,
      (store, { path, studentTaskStatus }) => {
        const payload = {
          student_id: studentTaskStatus.studentId,
          action: studentTaskStatus.action,
        };
        return LessonRepository.updateStudentTaskStatus(path, payload);
      },
    ),
    fetchLessonVisitings: waiting(
      (lessonId) => `fetch.lesson.visitings.${lessonId}`,
      ({ commit }, lessonId) => LessonRepository.getLessonVisitings(lessonId).then(({ data }) => {
        if (data) {
          commit('SET_LESSON_VISITINGS', data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    editLessonVisiting: waiting(
      ({ lessonId }) => `edit.lesson.visiting.${lessonId}`,
      ({ commit }, { lessonId, payload }) => LessonRepository.putLessonVisiting(lessonId, payload).then(({ data }) => {
        if (data) {
          commit('ADD_VISITING_TO_LIST', data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    editLessonGradeForTopic: waiting(
      ({ lessonId }) => `edit.lesson.grade.for.topic.${lessonId}`,
      ({ commit }, {
        lessonId, gradeId, payload, progressId = null,
      }) => LessonRepository.putGradesForTopic(lessonId, gradeId, payload)
        .then(({ data }) => {
          if (data) {
            commit('ADD_GRADES_FOR_TOPIC', data);
            if (progressId) {
              commit(`journal/${ADD_PROGRESS_GRADES}`, { progressId, gradeId, payload: data }, { root: true });
            }
            return Promise.resolve(data);
          }
          return Promise.reject();
        }),
    ),
    createComment: waiting(
      ({ lessonId }) => `create.lesson.comment.${lessonId}`,
      ({ commit }, { id, payload }) => LessonRepository.postCreateComment(id, payload).then((res) => {
        const { data, status } = res;
        if (data) {
          commit('ADD_LESSON_COMMENTS', data);
          return Promise.resolve(data);
        }
        if ([204].includes(status)) {
          return Promise.resolve(status);
        }
        return Promise.reject();
      }),
    ),
    postCreateCommentBulk: waiting(
      ({ lessonId }) => `create.lesson.comment.bulk.${lessonId}`,
      ({ commit }, { id, payload }) => LessonRepository.postCreateCommentBulk(id, payload)
        .then(({ data }) => {
          if (data) {
            commit('ADD_LESSON_COMMENTS_BULK', data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        }),
    ),
    fetchHomeworkList: waiting(
      'fetch.lesson.homeworks',
      ({ commit }, lessonId) => LessonRepository.getHomeworkList(lessonId).then(({ data }) => {
        if (data) {
          commit('SET_LESSON_HOMEWORK_LIST', data);
        }
        return data;
      }),
    ),
    fetchHomeworkTasks: waiting(
      (homeworkId) => `fetch.homework.tasks.${homeworkId}`,
      ({ commit }, homeworkId) => TasksRepository.getTasks({ task_id: homeworkId })
        .then((tasks) => {
          commit('SET_TASKS_BY_HOMEWORK', { homeworkId, tasks });
        }),
    ),
    createLessonTask: waiting(
      ({ id }) => `create.lesson.task.${id}`,
      ({ commit }, { id, payload }) => LessonRepository.createLessonTask(id, payload).then(({ data }) => {
        if (data) {
          commit('ADD_LESSON_HOMEWORK', data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    updateHomework: waiting(
      ({ homeworkId }) => `update.lesson.homework.${homeworkId}`,
      ({ commit }, { lessonId, homeworkId, payload }) => LessonRepository.updateLessonTask(lessonId, homeworkId, payload)
        .then(({ data }) => {
          if (data) {
            commit('UPDATE_LESSON_HOMEWORK', data);
            return data;
          }
          return Promise.reject();
        }),
    ),
    uploadAttachmentsToTask: waiting(
      'upload.lesson.attachments.task',
      ({ commit }, { lessonId, taskId, payload }) => LessonRepository.uploadAttachmentsToTask(lessonId, taskId, payload)
        .then(({ data }) => {
          if (data) {
            commit('ADD_HOMEWORK_TO_LIST', data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        }),
    ),
    deleteAttachmentsFromTask: waiting(
      'delete.lesson.attachment',
      ({ commit }, { lessonId, taskId, attachmentId }) => LessonRepository
        .deleteAttachmentsFromTask(lessonId, taskId, attachmentId).then(() => {
          commit('DELETE_ATTACHMENT_FROM_TASK', { taskId, attachmentId });
        }),
    ),
    addTopicLesson: waiting(
      'add.lesson.topic',
      ({ commit }, { id, payload }) => LessonRepository.postAddTopicLesson(id, payload).then(({ data }) => {
        if (data) {
          commit('SET_LESSON', data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    editTopicLesson: waiting(
      'add.lesson.topic',
      ({ commit }, { id, payload }) => LessonRepository.putEditTopicLesson(id, payload).then(({ data }) => {
        if (data) {
          commit('SET_LESSON', data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    deleteTopicLesson: waiting(
      ({ topicLessonId }) => `delete.lesson.topic.${topicLessonId}`,
      ({ commit }, { lessonId, topicLessonId }) => LessonRepository.deleteTopicLesson(lessonId, topicLessonId)
        .then(() => {
          commit('DELETE_TOPIC_LESSON', topicLessonId);
        }),
    ),
    deleteBulkTopics: waiting(
      'delete.lesson.bulk.topics',
      ({ commit }, { lessonId, payload }) => LessonRepository.deleteBulkTopics(lessonId, payload)
        .then(({ data }) => {
          if (data) {
            commit('SET_TOPICS', data.lesson_topics);
            commit('SET_LESSON_TYPE', data.lesson_type);
            return Promise.resolve(data);
          }
          return Promise.reject();
        })
        .catch((error) => Promise.reject(error.response.data)),
    ),
    attachTopics: waiting(
      'attach.lesson.topics',
      ({ commit }, { lessonId, payload }) => LessonRepository.attachTopics(lessonId, payload)
        .then(({ data }) => {
          if (data) {
            commit('SET_TOPICS', data.lesson_topics);
            commit('SET_LESSON_TYPE', data.lesson_type);
            return Promise.resolve(data);
          }
          return Promise.reject();
        })
        .catch((error) => Promise.reject(error.response.data)),
    ),
    fetchLessonTypes: waiting(
      'fetch.lesson.types',
      ({ commit }) => LessonRepository.getLessonTypes().then(({ data }) => {
        if (data) {
          commit('SET_LESSON_TYPES', data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    fetchIndividualLessons: waiting(
      () => 'fetch.lesson.individual.lessons',
      ({ commit }, lessonId) => LessonRepository.getIndividualLessons(lessonId).then(({ data }) => {
        if (data) {
          commit('SET_INDIVIDUAL_LESSONS', data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    deleteIndividualLesson: waiting(
      ({ individualsId }) => `delete.lesson.individual.${individualsId}`,
      ({ commit }, { lessonId, individualsId }) => LessonRepository.deleteIndividualLesson(lessonId, individualsId)
        .then(() => commit('DELETE_INDIVIDUAL_LESSON', individualsId)),
    ),
    setGradeIndividualLesson: waiting(
      ({ individualLessonId }) => `set.grade.lesson.individual.lesson.${individualLessonId}`,
      ({ commit }, {
        lessonId, individualLessonId, gradeId, payload,
      }) => LessonRepository.putGradeIndividualLesson(lessonId, individualLessonId, gradeId, payload)
        .then(({ data }) => {
          if (data) {
            commit('SET_GRADE_INDIVIDUAL_LESSON', {
              lessonId, individualLessonId, gradeId, payload: data,
            });
            return Promise.resolve(data);
          }
          return Promise.reject();
        }),
    ),
    createIndividualLesson: waiting(
      ({ topicId }) => `create.individual.lesson.${topicId}`,
      ({ commit }, { lessonId, payload }) => LessonRepository.createIndividualLesson(lessonId, payload)
        .then(({ data }) => {
          if (data) {
            commit('ADD_INDIVIDUAL_LESSON', data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        }),
    ),
    updateIndividualLesson: waiting(
      'update.individual.lesson',
      ({ commit }, { lessonId, individualsId, payload }) => LessonRepository
        .updateIndividualLesson(lessonId, individualsId, payload)
        .then(({ data }) => {
          if (data) {
            commit('ADD_INDIVIDUAL_LESSON', { lessonId, payload: data });
            return Promise.resolve(data);
          }
          return Promise.reject();
        }),
    ),

    updateLesson: waiting(
      'create.update.lesson',
      ({ commit }, { id, payload }) => LessonRepository.putUpdateLesson({ id, payload }).then(({ data }) => {
        if (data) {
          commit('SET_LESSON', data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),

    fetchTabDataGrades: waiting(
      (id) => `fetch.tab.data.grades.${id}`,
      ({ commit }, lessonId) => LessonRepository.getTabDataGrades(lessonId)
        .then(({ data }) => {
          data.visits.forEach((visit) => {
            if (!visit.class) {
              toast.danger({
                text: `У ученика ${modifyPersonFields(visit.student)?.shortName} отсутствует класс`,
              });
            }
          });
          if (data) {
            commit('SET_TAB_DATA_GRADES', data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        }),
    ),
    fetchLessonsDatesByClassSubject: waiting(
      'fetch.lessons.dates.by.class.subject',
      ({ commit }, lessonId) => LessonRepository.getLessonsDatesByClassSubject(lessonId)
        .then(({ data }) => {
          if (data) {
            commit('SET_SUBJECT_CALENDAR', data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        }),
    ),
    fetchStudentCalender: waiting(
      'fetch.student.calendar',
      // eslint-disable-next-line max-len
      ({ commit, rootState }, payload) => LessonRepository.getStudentCalender(rootState.active.activeStudentId, payload)
        .then(({ data }) => {
          if (data) {
            commit('SET_SUBJECT_CALENDAR', data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        }),
    ),
    deleteHomeworkAttachment: waiting(
      ({ attachmentId }) => `delete.homework.${attachmentId}`,
      // eslint-disable-next-line max-len
      ({ commit, rootState, rootGetters }, {
        lessonId, taskId, attachmentId,
      }) => {
        const payload = {};
        if (rootGetters['auth/role'] === 'parent') {
          payload.student_id = rootState.active.activeStudentId;
        }
        return LessonRepository.deleteHomeworkAttachment(lessonId, taskId, attachmentId, payload)
          .then(() => {
            commit('DELETE_HOMEWORK_ATTACHMENTS', { lessonId, taskId, attachmentId });
            return Promise.resolve();
          });
      },
    ),
    uploadStudentHomework: waiting(
      ({ homeworkId }) => `upload.student.homework.${homeworkId}`,
      // eslint-disable-next-line max-len
      ({ commit, rootState }, { lessonId, homeworkId, file }) => LessonRepository.uploadStudentHomework(lessonId, homeworkId, rootState.active.activeStudentId, file)
        .then(({ data }) => {
          if (data) {
            commit('UPLOAD_STUDENT_HOMEWORK', { homeworkId, data });
            return Promise.resolve(data);
          }
          return Promise.reject();
        }),
    ),
    deleteHomework: waiting(
      ({ homeworkId }) => `delete.lesson.task.${homeworkId}`,
      ({ commit }, { lessonId, homeworkId }) => LessonRepository.deleteHomeTask(lessonId, homeworkId)
        .then(() => {
          commit('DELETE_HOMEWORK', homeworkId);
          return Promise.resolve();
        }),
    ),
    downloadAttachmentsByTaskAndStudentForAdmin: waiting(
      'download.attachments',
      (context, { lessonId, taskId, studentId }) => LessonRepository
        .downloadAttachmentsByTaskAndStudentForAdmin({ lessonId, taskId, studentId }),
    ),
    downloadAttachmentsByTeacherForStudent: waiting(
      'download.attachments',
      (context, { taskId, payload }) => LessonRepository
        .downloadAttachmentsByTeacherForStudent({ taskId, payload }),
    ),
    downloadAttachmentsByStudentForStudent: waiting(
      'download.attachments',
      (context, { taskId, payload }) => LessonRepository
        .downloadAttachmentsByStudentForStudent({ taskId, payload }),
    ),
    downloadAttachmentsByLessonForAdmin: waiting(
      'download.attachments',
      (context, lessonId) => LessonRepository
        .downloadAttachmentsByLessonForAdmin(lessonId),
    ),
    downloadAttachmentsByLessonWithoutStudentForAdmin: waiting(
      'download.attachments',
      (context, { lessonId, taskId }) => LessonRepository
        .downloadAttachmentsByLessonWithoutStudentForAdmin({ lessonId, taskId }),
    ),
    downloadAttachmentsByLessonForStudent: waiting(
      'download.attachments',
      (context, lessonId) => LessonRepository
        .downloadAttachmentsByLessonForStudent(lessonId),
    ),
    uploadLessonAttachment: waiting(
      ({ lessonId }) => `upload.lesson.attachment.${lessonId}`,
      // eslint-disable-next-line max-len
      ({ commit }, { lessonId, file }) => LessonRepository.uploadLessonAttachment(lessonId, file)
        .then(({ data }) => {
          if (data) {
            commit('UPLOAD_LESSON_ATTACHMENT', data.attachment_links);
            return Promise.resolve(data);
          }
          return Promise.reject();
        }),
    ),
    deleteLessonAttachment: waiting(
      ({ attachmentId }) => `delete.lesson.attachment.${attachmentId}`,
      // eslint-disable-next-line max-len
      ({ commit }, { lessonId, attachmentId }) => LessonRepository.deleteLessonAttachment(lessonId, attachmentId)
        .then(() => {
          commit('DELETE_LESSON_ATTACHMENT', attachmentId);
          return Promise.resolve();
        }),
    ),
    clearLesson({ commit }) {
      commit('CLEAR_LESSON');
    },
  },
  mutations: {
    CLEAR_LESSON(state) {
      Object.keys(state).forEach((key) => {
        Vue.set(state, key, null);
      });
    },
    SET_TASKS_BY_HOMEWORK(state, { homeworkId, tasks }) {
      if (!state.tasksByHomework) {
        Vue.set(state, 'tasksByHomework', {});
      }
      Vue.set(state.tasksByHomework, homeworkId, tasks);
    },
    SET_LESSON(state, payload) {
      Vue.set(state, 'lesson', payload);
    },
    UPLOAD_LESSON_ATTACHMENT(state, attachments) {
      Vue.set(state.lesson, 'attachment_links', attachments);
    },
    DELETE_LESSON_ATTACHMENT(state, attachmentId) {
      const lessonAttachments = state.lesson.attachment_links;
      const index = lessonAttachments.findIndex((i) => i.id === attachmentId);
      Vue.delete(lessonAttachments, index);
    },
    SET_STUDENT_LESSON_BY_ID(state, payload) {
      Vue.set(state, 'studentLesson', payload);
    },
    CLEAR_STUDENT_LESSONS(state) {
      state.studentLesson = {};
    },
    SET_LESSON_VISITINGS(state, payload) {
      Vue.set(state, 'lessonVisitings', payload);
    },
    SET_LESSON_RATINGS_HOMEWORK(state, payload) {
      Vue.set(state, 'lessonRatingsHomework', payload);
    },
    SET_LESSON_TYPES(state, payload) {
      Vue.set(state, 'lessonTypes', payload);
    },
    SET_LESSON_HOMEWORK_LIST(state, payload) {
      const orderedHomeworks = orderBy(payload, 'created_at', 'desc');
      Vue.set(state, 'homeworksList', orderedHomeworks);
    },
    SET_INDIVIDUAL_LESSONS(state, payload) {
      Vue.set(state, 'individualLessons', payload);
    },
    SET_GRADE_INDIVIDUAL_LESSON(state, {
      individualLessonId, gradeId, payload,
    }) {
      const lesson = state.individualLessons.find((l) => l.id === individualLessonId);
      const foundIndex = lesson.grades.findIndex((g) => g.id === gradeId);
      if (foundIndex > -1) {
        lesson.grades.splice(foundIndex, 1, payload);
      }
    },
    SET_TAB_DATA_GRADES(state, payload) {
      Vue.set(state, 'lessonTabDataGrades', payload);
    },
    CLEAR_SUBJECT_CALENDAR(state) {
      Vue.set(state, 'lessonsDatesByClassSubject', null);
    },
    SET_SUBJECT_CALENDAR(state, payload) {
      Vue.set(state, 'lessonsDatesByClassSubject', payload);
    },
    ADD_HOMEWORK_TO_LIST(state, payload) {
      const foundIndex = state.homeworksList.findIndex((i) => i.id === payload.id);
      if (foundIndex > -1) {
        state.homeworksList.splice(foundIndex, 1, payload);
      }
    },
    ADD_LESSON_HOMEWORK(state, payload) {
      state.homeworksList.unshift(payload);
    },
    UPDATE_LESSON_HOMEWORK(state, payload) {
      const foundIndex = state.homeworksList.findIndex((i) => parseInt(i.id, 10) === parseInt(payload.id, 10));
      state.homeworksList.splice(foundIndex, 1, payload);
    },
    ADD_VISITING_TO_LIST(state, payload) {
      const foundIndex = state.lessonTabDataGrades.visits.findIndex((i) => i.id === payload.id);
      if (foundIndex > -1) {
        state.lessonTabDataGrades.visits.splice(foundIndex, 1, payload);
      }
    },
    ADD_LESSON_RATINGS_HOMEWORK(state, payload) {
      const foundIndex = state.lessonTabDataGrades.homework_grades
        .findIndex((i) => i.student.id === payload.student.id && payload.id === i.id);
      if (foundIndex > -1) {
        state.lessonTabDataGrades.homework_grades.splice(foundIndex, 1, payload);
      }
    },
    ADD_LESSON_RATINGS_INDIVIDUALS(state, payload) {
      const foundIndex = state.lessonTabDataGrades.individual_grades.findIndex((i) => i.id === payload.id);
      if (foundIndex > -1) {
        state.lessonTabDataGrades.individual_grades.splice(foundIndex, 1, payload);
      }
    },
    ADD_LESSON_COMMENTS(state, payload) {
      const foundIndex = state.lessonTabDataGrades.comments.findIndex((i) => i.id === payload.id);
      if (foundIndex > -1) {
        state.lessonTabDataGrades.comments.splice(foundIndex, 1, payload);
      } else {
        state.lessonTabDataGrades.comments.push(payload);
      }
    },
    // eslint-disable-next-line no-unused-vars
    ADD_LESSON_COMMENTS_BULK(state, payload) {
      state.lessonTabDataGrades.comments = payload;
      state.lessonTabDataGrades.comments.sort((a, b) => {
        if (new Date(a.updated_at) > new Date(b.updated_at)) {
          return 1;
        }
        return -1;
      });
    },
    ADD_GRADES_FOR_TOPIC(state, payload) {
      const foundIndex = state.lessonTabDataGrades?.topic_grades.findIndex((i) => i.id === payload.id);
      if (foundIndex > -1) {
        state.lessonTabDataGrades.topic_grades.splice(foundIndex, 1, payload);
      }
    },
    ADD_INDIVIDUAL_LESSON(state, payload) {
      const foundIndex = state.individualLessons.findIndex((l) => l.id === payload.id);
      if (foundIndex > -1) {
        state.individualLessons.splice(foundIndex, 1, payload);
      } else {
        state.individualLessons.push(payload);
      }
    },
    DELETE_ATTACHMENT_FROM_TASK(state, { taskId, attachmentId }) {
      const hwItem = state.homeworksList.find((i) => i.id === taskId);
      const linkIndex = hwItem.attachment_links.findIndex((i) => i.id === attachmentId);
      Vue.delete(hwItem.attachment_links, linkIndex);
    },
    DELETE_INDIVIDUAL_LESSON(state, individualsId) {
      const lessonIndex = state.individualLessons.findIndex((i) => i.id === individualsId);
      Vue.delete(state.individualLessons, lessonIndex);
    },
    UPLOAD_STUDENT_HOMEWORK(state, { data, homeworkId }) {
      const homework = state.studentLesson.lesson_tasks.find((task) => task.id === homeworkId);
      Vue.set(homework, 'student_attachment_links', data.student_attachment_links);
    },
    UPDATE_STUDENT_TASK_STATUS(state, { status, homeworkId }) {
      const homework = state.studentLesson.lesson_tasks.find((task) => task.id === homeworkId);
      Vue.set(homework, 'status', status);
    },
    DELETE_HOMEWORK(state, homeworkId) {
      const lessonIndex = state.homeworksList.findIndex((i) => i.id === homeworkId);
      state.homeworksList.splice(lessonIndex, 1);
    },
    DELETE_HOMEWORK_ATTACHMENTS(state, { taskId, attachmentId }) {
      const taskData = state.studentLesson.lesson_tasks.find((t) => t.id === taskId);
      const attachmentIdx = taskData.student_attachment_links.findIndex((l) => l.id === attachmentId);
      Vue.delete(taskData.student_attachment_links, attachmentIdx);
    },
    CHANGE_TASK_STATUS(state, {
      homeworkId, studentId, studentTask,
    }) {
      const currentTask = state.tasksByHomework[homeworkId].find((task) => task.student.id === studentId);
      Vue.set(currentTask, 'status', studentTask.status);
      Vue.set(currentTask, 'status_label', studentTask.status_label);
      Vue.set(currentTask, 'actions', studentTask.actions);
    },
    SET_TOPICS(state, payload) {
      Vue.set(state.lesson, 'lesson_topics', payload);
    },
    DELETE_TOPIC_LESSON(state, topicLessonId) {
      const idx = state.lesson.lesson_topics.findIndex((topic) => topic.id === topicLessonId);
      state.lesson.lesson_topics.splice(idx, 1);
    },
    SET_LESSON_TYPE(state, payload) {
      Vue.set(state.lesson, 'lesson_type', payload);
    },
  },
};
