import Vue from 'vue';
import * as AcademicYearsRepository from '@/api/academic-years';
import { waiting } from '@/store/waiting';
import {
  SET_ACADEMIC_YEARS_LIST,
  ADD_ACADEMIC_YEAR_TO_LIST,
  REMOVE_ACADEMIC_YEAR_FROM_LIST,
  SET_ACADEMIC_YEAR_BY_ID,
  ADD_WEEKEND_TO_ACADEMIC_YEAR, DELETE_WEEKEND_FROM_ACADEMIC_YEAR,
} from '@/store/modules/academic-years/mutation-types';

export default {
  namespaced: true,
  state: {
    academicYearsList: null,
    academicYears: {},
  },
  getters: {
    getAcademicYearsList: (state) => {
      if (state.academicYearsList?.data) {
        return state.academicYearsList.data;
      }
      return [];
    },
    getAcademicYearById: (state) => (id) => state.academicYears[id] || null,
  },
  actions: {
    fetch: waiting(
      'fetch.academic-years',
      ({ commit }) => AcademicYearsRepository.getList().then(({ data }) => {
        if (data) {
          commit(SET_ACADEMIC_YEARS_LIST, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    create: waiting(
      'create.academic-years',
      ({ commit }, payload) => AcademicYearsRepository.postCreate(payload).then(({ data }) => {
        if (data) {
          commit(ADD_ACADEMIC_YEAR_TO_LIST, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    delete: waiting(
      (id) => `delete.academic-years.${id}`,
      ({ commit, rootGetters, dispatch }, id) => AcademicYearsRepository.deleteById(id).then(() => {
        debugger;
        if (rootGetters['active/getActiveAcademicYearId'] === id) {
          dispatch('active/setActiveAcademicYear', null, { root: true });
        }
        commit(REMOVE_ACADEMIC_YEAR_FROM_LIST, { id });
        return Promise.resolve();
      }),
    ),
    getById: waiting(
      (id) => `fetch.academic-years.${id}`,
      ({ commit }, id) => AcademicYearsRepository.getById(id).then(({ data }) => {
        if (data) {
          commit(SET_ACADEMIC_YEAR_BY_ID, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    update: waiting(
      ({ id }) => `update.academic-years.${id}`,
      ({ commit }, { id, payload }) => AcademicYearsRepository.putUpdate(id, payload).then(({ data }) => {
        if (data) {
          commit(ADD_ACADEMIC_YEAR_TO_LIST, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    copy: waiting(
      ({ id }) => `copy.academic-years.${id}`,
      ({ commit }, { id, payload }) => AcademicYearsRepository.postCopyById(id, payload).then(({ data }) => {
        if (data) {
          commit(ADD_ACADEMIC_YEAR_TO_LIST, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
  },
  mutations: {
    [SET_ACADEMIC_YEARS_LIST](state, payload) {
      Vue.set(state, 'academicYearsList', payload);
    },
    [ADD_ACADEMIC_YEAR_TO_LIST](state, payload) {
      if (!state.academicYearsList) {
        Vue.set(state, 'academicYearsList', { data: [payload] });
      } else {
        const { id } = payload;
        const foundIndex = state.academicYearsList.data.findIndex((o) => parseInt(o.id, 10) === parseInt(id, 10));
        if (foundIndex > -1) {
          state.academicYearsList.data.splice(foundIndex, 1, payload);
        } else {
          state.academicYearsList.data.push(payload);
        }
      }
    },
    [REMOVE_ACADEMIC_YEAR_FROM_LIST](state, { id }) {
      if (state.academicYearsList?.data) {
        state.academicYearsList.data = state.academicYearsList.data.filter(
          (o) => parseInt(o.id, 10) !== parseInt(id, 10),
        );
      }
    },
    [SET_ACADEMIC_YEAR_BY_ID](state, payload) {
      const { id } = payload;
      Vue.set(state.academicYears, id, payload);
    },
    [ADD_WEEKEND_TO_ACADEMIC_YEAR](state, { academicYearId, payload }) {
      if (state.academicYears[academicYearId]) {
        if (!state.academicYears[academicYearId]?.weekends) {
          state.academicYears[academicYearId].weekends = [];
        }
        const foundIndex = state.academicYears[academicYearId].weekends.findIndex(
          (w) => parseInt(w.id, 10) === parseInt(payload.id, 10),
        );
        if (foundIndex > -1) {
          state.academicYears[academicYearId].weekends.splice(foundIndex, 1, payload);
        } else {
          state.academicYears[academicYearId].weekends.push(payload);
        }
      }
    },
    [DELETE_WEEKEND_FROM_ACADEMIC_YEAR](state, { academicYearId, id }) {
      if (state.academicYears[academicYearId]?.weekends) {
        state.academicYears[academicYearId].weekends = state.academicYears[academicYearId].weekends.filter(
          (w) => parseInt(w.id, 10) !== parseInt(id, 10),
        );
      }
    },
  },
};
