import http from '@/api';

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getList = (payload) => http.get('/api/admin/teachers', {
  params: payload,
});

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getArchiveList = (payload) => http.get('/api/admin/teachers/archive', {
  params: payload,
});

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getRecoveryById = (id) => http.get(`/api/admin/teachers/${id}/recovery`);

/**
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getById = (id) => http.get(`/api/admin/teachers/${id}`);

/**
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const putUpdate = (id, payload) => http.put(
  `/api/admin/teachers/${id}`,
  payload,
);

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postCreate = (payload) => http.post('/api/admin/teachers', payload);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteById = (id) => http.delete(`/api/admin/teachers/${id}`);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getShowPasswordById = (id) => http.get(`/api/admin/teachers/${id}/show-password`);

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getTeachersForSelects = () => http.get('/api/admin/teachers/for-selects');

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getTeacherDebts = () => http.get('/api/admin/journal/lesson/homework/debts/export');
