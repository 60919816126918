import http from '@/api';

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getFinalGradesTypes = () => http.get('api/admin/journal/finals/grades/types');

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getFinalGradesSummary = (payload) => http.post('api/admin/journal/finals/progress', payload);

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getFinalGradesGradesList = (payload) => http.get('api/admin/journal/finals/grades/list', {
  params: payload,
});

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getFinalGradesGradesListAll = (payload) => http.get('api/admin/journal/finals/grades/list/all', {
  params: payload,
}).then((response) => response.data);

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const createFinalGradesGrade = (payload) => http.post('api/admin/journal/finals/grades/create', payload);

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const editFinalGradesGrade = ({ finalGradeSettingId, payload }) => http.put(`api/admin/journal/finals/grades/${finalGradeSettingId}`, payload);

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const deleteFinalGrades = (finalGradeSettingId) => http.delete(`api/admin/journal/finals/grades/${finalGradeSettingId}`);

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const setFinalGradeGrade = (gradeId, payload) => http.patch(`api/admin/journal/finals/grades/${gradeId}`, payload);
