import http from '@/api/index';

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postMessage = (payload) => http.post('/api/admin/dashboard/text', payload)
  .then((response) => response.data);

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchMessages = () => http.get('/api/admin/dashboard/text')
  .then((response) => response.data.text);
