import waiting from '@/store/waiting';
import { fetchMessages, postMessage } from '@/api/news/index';

export default {
  namespaced: true,
  state: {
    messages: null,
  },
  getters: {
  },
  actions: {
    postMessage: waiting(
      'news.postMessage',
      ({ commit }, payload) => postMessage(payload).then(({ text }) => {
        commit('SET_MESSAGES', text);
      }),
    ),
    fetchMessages: waiting(
      'news.getMessages',
      ({ commit }) => fetchMessages().then((data) => {
        commit('SET_MESSAGES', data);
      }),
    ),
  },
  mutations: {
    SET_MESSAGES(state, payload) {
      state.messages = payload;
    },
  },
};
