export default {
  namespaced: true,
  state: {
    deployedSubjects: [],
    deployedSections: [],
  },
  getters: {
  },
  mutations: {
    DEPLOY_SUBJECT(state, id) {
      const itemIdx = state.deployedSubjects.findIndex((subjectId) => subjectId === id);
      if (itemIdx !== -1) {
        state.deployedSubjects.splice(itemIdx, 1);
      } else {
        state.deployedSubjects.push(id);
      }
    },
    DEPLOY_SECTION(state, id) {
      const itemIdx = state.deployedSections.findIndex((sectionId) => sectionId === id);
      if (itemIdx !== -1) {
        state.deployedSections.splice(itemIdx, 1);
      } else {
        state.deployedSections.push(id);
      }
    },
  },
  actions: {
  },
};
