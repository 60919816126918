// eslint-disable-next-line import/prefer-default-export
export const SET_TYPES = 'SET_TYPES';
export const SET_AUDIENCE_LINKS = 'SET_AUDIENCE_LINKS';
export const SET_SUBJECTS_BY_SUBGROUP = 'SET_SUBJECTS_BY_SUBGROUP';
export const SET_SUBJECTS_BY_CLASS = 'SET_SUBJECTS_BY_CLASS';
export const SET_SCHEDULE_LIST_BY_DATE = 'SET_SCHEDULE_LIST_BY_DATE';
export const ADD_SCHEDULE_TO_LIST = 'ADD_SCHEDULE_TO_LIST';
export const SET_SCHEDULE_CACHE_TIME = 'SET_SCHEDULE_CACHE_TIME';
export const CLEAR_SCHEDULE_LIST = 'CLEAR_SCHEDULE_LIST';
export const REMOVE_LESSON_FROM_SCHEDULE_LIST = 'REMOVE_LESSON_FROM_SCHEDULE_LIST';
export const SET_FILTER_OPTIONS_BY_DATE = 'SET_FILTER_OPTIONS_BY_DATE';
