<template>
  <component :is="layout" />
</template>

<script>
export default {
  name: 'Layout',
  props: {
    path: {
      type: String,
      default: 'layouts',
    },
    useParentLayout: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      ln: 'default',
    };
  },
  watch: {
    $route: {
      // eslint-disable-next-line consistent-return
      handler() {
        const {
          meta = {},
        } = this.$route;
        let { rootLayout } = meta;
        if (!rootLayout) {
          if (this.useParentLayout) {
            const { matched } = this.$route;
            const match = matched.slice().reverse().find((route) => route.meta?.rootLayout);
            if (match) {
              this.ln = match.meta.rootLayout;
              return null;
            }
          }
          rootLayout = 'default';
        }
        this.ln = rootLayout;
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    layout() {
      const { ln } = this;
      return () => import(/* webpackChunkName: "layout-[request]" */ `@/${this.path}/${ln}.vue`);
    },
  },
};
</script>
