import http from '@/api';

/**
 * @param {number} trainingProgramId
 * @param {number} subjectId
 * @param {number} sectionId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getList = (trainingProgramId, subjectId, sectionId) => http.get(
  `/api/admin/training_programs/${trainingProgramId}/subjects/${subjectId}/sections/${sectionId}/topics`,
);

/**
 * @param {number} trainingProgramId
 * @param {number} subjectId
 * @param {number} sectionId
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postCreateBatch = (trainingProgramId, subjectId, sectionId, payload) => http.post(
  `/api/admin/training_programs/${trainingProgramId}/subjects/${subjectId}/sections/${sectionId}/topics-batch`,
  payload,
);

/**
 * @param {number} trainingProgramId
 * @param {number} subjectId
 * @param {number} sectionId
 * @param {number} topicId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteById = (trainingProgramId, subjectId, sectionId, topicId) => http.delete(
  `/api/admin/training_programs/${trainingProgramId}/subjects/${subjectId}/sections/${sectionId}/topics/${topicId}`,
);

/**
 * @param {number} trainingProgramId
 * @param {number} subjectId
 * @param {number} sectionId
 * @param {number} topicId
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const putUpdate = (trainingProgramId, subjectId, sectionId, topicId, payload) => http.put(
  `/api/admin/training_programs/${trainingProgramId}/subjects/${subjectId}/sections/${sectionId}/topics/${topicId}`,
  payload,
);

/**
 * @param {number} trainingProgramId
 * @param {number} subjectId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getListBySubjectOnly = (trainingProgramId, subjectId) => http.get(
  `api/admin/training_programs/${trainingProgramId}/subjects/${subjectId}/topics`,
);
