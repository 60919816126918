import http from '@/api';

/**
 * @param {string} type
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line import/prefer-default-export
export const getExport = (type) => http.get('/api/admin/users/export', {
  params: { type },
  responseType: 'arraybuffer',
});

/**
 * @param {object} payload
 * @param {object} config
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postImport = (payload, config = {}) => http.post(
  '/api/admin/users/import',
  payload,
  config,
);
