import http from '@/api';

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getList = () => http.get('/api/admin/academic_years');

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getById = (id) => http.get(`/api/admin/academic_years/${id}`);

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postCreate = (payload) => http.post('/api/admin/academic_years', payload);

/**
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const putUpdate = (id, payload) => http.put(`/api/admin/academic_years/${id}`, payload);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteById = (id) => http.delete(`/api/admin/academic_years/${id}`);
/**
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postCopyById = (id, payload) => http.post(
  `/api/admin/academic_years/${id}/copy`,
  payload,
);

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getCurrent = () => http.get('/api/academic_years/current');
