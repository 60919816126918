import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const loadLocaleMessages = () => {
  try {
    const locales = require.context('@/locales', false, /[A-Za-z0-9-_,\s]+\.js$/i);
    return locales.keys().reduce((result, key) => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i);
      if (matched && matched.length > 1) {
        const locale = matched[1];
        // eslint-disable-next-line no-param-reassign
        result[locale] = locales(key)?.default || {};
      }
      return result;
    }, {});
  } catch (e) {
    return {};
  }
};

export const locales = loadLocaleMessages();

export default new VueI18n({
  locale: 'ru',
  fallbackLocale: 'ru',
  messages: locales,
});
