import {
  TOGGLE_MENU,
} from '@/store/modules/ui/mutation-types';

export default {
  namespaced: true,
  state: {
    isMenuExpanded: false,
  },
  mutations: {
    [TOGGLE_MENU](state) {
      state.isMenuExpanded = !state.isMenuExpanded;
    },
  },
  actions: {
    toggleMenu({ commit }) {
      commit(TOGGLE_MENU);
    },
  },
};
