export const SET_LESSON_BY_ID = 'SET_LESSON_BY_ID';
export const SET_LESSON_VISITINGS = 'SET_LESSON_VISITINGS';
export const SET_LESSON_TYPES = 'SET_LESSON_TYPES';
export const SET_LESSON_HOMEWORK_LIST = 'SET_LESSON_HOMEWORK_LIST';
export const SET_LESSON_RATINGS_HOMEWORK = 'SET_LESSON_RATINGS_HOMEWORK';
export const SET_LESSON_RATINGS_INDIVIDUALS = 'SET_LESSON_RATINGS_INDIVIDUALS';
export const SET_INDIVIDUAL_LESSONS = 'SET_INDIVIDUAL_LESSONS';
export const SET_GRADE_INDIVIDUAL_LESSON = 'SET_GRADE_INDIVIDUAL_LESSON';
export const SET_TAB_DATA_GRADES = 'SET_TAB_DATA_GRADES';
export const SET_SUBJECT_CALENDAR = 'SET_SUBJECT_CALENDAR';
export const ADD_LESSON_TASK = 'ADD_LESSON_TASK';
export const ADD_VISITING_TO_LIST = 'ADD_VISITING_TO_LIST';
export const ADD_LESSON_RATINGS_HOMEWORK = 'ADD_LESSON_RATINGS_HOMEWORK';
export const ADD_GRADES_FOR_TOPIC = 'ADD_GRADES_FOR_TOPIC';
export const ADD_HOMEWORK_TO_LIST = 'ADD_HOMEWORK_TO_LIST';
export const ADD_LESSON_COMMENTS = 'ADD_LESSON_COMMENTS';
export const ADD_LESSON_COMMENTS_BULK = 'ADD_LESSON_COMMENTS_BULK';
export const ADD_LESSON_RATINGS_INDIVIDUALS = 'ADD_LESSON_RATINGS_INDIVIDUALS';
export const ADD_INDIVIDUAL_LESSON = 'ADD_INDIVIDUAL_LESSON';
export const DELETE_ATTACHMENT_FROM_TASK = 'DELETE_ATTACHMENT_FROM_TASK';
export const DELETE_INDIVIDUAL_LESSON = 'DELETE_INDIVIDUAL_LESSON';
export const SET_STUDENT_LESSON_BY_ID = 'SET_STUDENT_LESSON_BY_ID';
export const UPLOAD_STUDENT_HOMEWORK = 'UPLOAD_STUDENT_HOMEWORK';
export const DELETE_HOME_TASK = 'DELETE_HOME_TASK';
export const DELETE_HOMEWORK_ATTACHMENTS = 'DELETE_HOMEWORK_ATTACHMENTS';
export const UPLOAD_LESSON_ATTACHMENT = 'UPLOAD_LESSON_ATTACHMENT';
export const DELETE_LESSON_ATTACHMENT = 'DELETE_LESSON_ATTACHMENT';
export const CLEAR_STUDENT_LESSONS = 'CLEAR_STUDENT_LESSONS';
