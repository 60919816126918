import Vue from 'vue';
import { waiting } from '@/store/waiting';
import * as MainPageRepository from '@/api/main';
import {
  SET_HISTORY_LOGS_EVENTS,
  SET_HISTORY_LOGS_LIST,
  SET_TEACHER_SCHEDULES_TODAY,
  SET_TEACHER_HOMEWORKS_TODAY,
  SET_DASHBOARD_INFO,
  SET_ACTIONS_LIST,
  CLEAR_TEACHER_HOMEWORKS_TODAY,
  CLEAR_TEACHER_SCHEDULES_TODAY,
} from '@/store/modules/main/mutation-types';

export default {
  namespaced: true,
  state: {
    historyLogsEvents: null,
    historyLogsList: null,
    teacherSchedulesToday: null,
    teacherHomeworksToday: {},
    dashboardInfo: {},
    actionsList: {},
  },
  getters: {
    getHistoryLogsEvents: (state) => state.historyLogsEvents,
    getHistoryLogsList: (state) => state.historyLogsList,
    getTeacherSchedulesToday: (state) => (date) => state.teacherSchedulesToday[date] || [],
    getTeacherHomeworksToday: (state) => (date) => state.teacherHomeworksToday[date] || [],
    getDashboardInfo: (state) => (year) => state.dashboardInfo[year] || {},
    getActionsList: (state) => (year) => state.actionsList[year] || [],
  },
  actions: {
    fetchHistoryLogsEvents: waiting(
      'fetch.history.logs.events',
      ({ commit }) => MainPageRepository.getHistoryLogsEvents().then(({ data }) => {
        if (data) {
          commit(SET_HISTORY_LOGS_EVENTS, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    fetchHistoryLogsList: waiting(
      'fetch.history.logs.list',
      ({ commit }) => MainPageRepository.getHistoryLogsList().then(({ data }) => {
        if (data) {
          commit(SET_HISTORY_LOGS_LIST, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    fetchTeacherSchedulesToday: waiting(
      'fetch.teacher.schedules.today',
      ({ commit }, payload) => MainPageRepository.getSchedulesToday(payload).then(({ data }) => {
        if (data) {
          commit(SET_TEACHER_SCHEDULES_TODAY, { payload: data, date: payload.start_date });
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    fetchTeacherHomeworksToday: waiting(
      'fetch.teacher.homeworks.today',
      ({ commit }, payload) => MainPageRepository.getHomeworksToday(payload).then(({ data }) => {
        if (data) {
          commit(SET_TEACHER_HOMEWORKS_TODAY, { payload: data, date: payload.start_date });
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    fetchDashboardInfo: waiting(
      'fetch.dashboard.info',
      ({ commit, rootGetters }) => {
        const payload = {};
        if (rootGetters['active/getActiveAcademicYearId']) {
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        return MainPageRepository.getDashboardInfo(payload).then(({ data }) => {
          if (data) {
            commit(SET_DASHBOARD_INFO, { payload: data, year: payload.academic_year_id });
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
    ),
    fetchActionsList: waiting(
      'fetch.actions.list',
      ({ commit, rootGetters }) => {
        const payload = {};
        if (rootGetters['active/getActiveAcademicYearId']) {
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        return MainPageRepository.getListActions(payload).then(({ data }) => {
          if (data) {
            commit(SET_ACTIONS_LIST, { payload: data, year: payload.academic_year_id });
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
    ),
    replaceActions: waiting(
      'replace.actions',
      ({ commit, rootGetters }, payload) => {
        if (rootGetters['active/getActiveAcademicYearId']) {
          // eslint-disable-next-line no-param-reassign
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        return MainPageRepository.replaceActions(payload).then(({ data }) => {
          if (data) {
            commit(SET_ACTIONS_LIST, { year: payload.academic_year_id, payload: data });
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
    ),
    clearTeacherData: ({ commit }) => {
      commit(CLEAR_TEACHER_HOMEWORKS_TODAY);
      commit(CLEAR_TEACHER_SCHEDULES_TODAY);
    },
  },
  mutations: {
    [SET_HISTORY_LOGS_EVENTS](state, payload) {
      Vue.set(state, 'historyLogsEvents', payload);
    },
    [SET_HISTORY_LOGS_LIST](state, payload) {
      Vue.set(state, 'historyLogsList', payload);
    },
    [SET_TEACHER_SCHEDULES_TODAY](state, { payload }) {
      Vue.set(state, 'teacherSchedulesToday', payload);
    },
    [SET_TEACHER_HOMEWORKS_TODAY](state, { payload, date }) {
      Vue.set(state.teacherHomeworksToday, date, payload);
    },
    [SET_DASHBOARD_INFO](state, { payload, year }) {
      Vue.set(state.dashboardInfo, year, payload);
    },
    [SET_ACTIONS_LIST](state, { payload, year }) {
      const data = payload.map((i) => {
        const param = i.data[0];
        // eslint-disable-next-line no-param-reassign
        i.paramToInteract = param.paramToInteract;
        // eslint-disable-next-line no-param-reassign
        i.section = param.section;
        // eslint-disable-next-line no-param-reassign
        i.title = param.sectionTitle;
        // eslint-disable-next-line no-param-reassign
        i.type = param.type;
        // eslint-disable-next-line no-param-reassign
        i.filterId = param.filterId;
        return i;
      });
      Vue.set(state.actionsList, year, data);
    },
    [CLEAR_TEACHER_HOMEWORKS_TODAY](state) {
      Vue.set(state, 'teacherHomeworksToday', {});
    },
    [CLEAR_TEACHER_SCHEDULES_TODAY](state) {
      Vue.set(state, 'teacherSchedulesToday', null);
    },
  },
};
