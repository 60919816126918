import Vue from 'vue';
import moment from '@/moment';
import * as ScheduleRepository from '@/api/schedule';
import * as AudienceRepository from '@/api/audience-links';
import * as ClassesRepository from '@/api/classes';
import * as CustomerRepository from '@/api/customer';
import minBy from 'lodash.minby';
import { waiting } from '@/store/waiting';
import {
  ADD_SCHEDULE_TO_LIST,
  CLEAR_SCHEDULE_LIST,
  REMOVE_LESSON_FROM_SCHEDULE_LIST,
  SET_AUDIENCE_LINKS,
  SET_SCHEDULE_CACHE_TIME,
  SET_SCHEDULE_LIST_BY_DATE,
  SET_SUBJECTS_BY_CLASS,
  SET_SUBJECTS_BY_SUBGROUP,
  SET_TYPES,
  SET_FILTER_OPTIONS_BY_DATE,
} from '@/store/modules/schedule/mutation-types';
import { exportSchedule } from '@/api/schedule';

const MAX_CACHED_SCHEDULES = 5;

export default {
  namespaced: true,
  state: {
    types: null,
    audienceLinks: [],
    subjectsForSelectLessonCreate: [],
    scheduleListByDate: {},
    schedulesCacheTime: [],
    filtersOptionsByDate: {},
  },
  getters: {
    getTypes: (state) => state.types || [],
    getScheduleListByDate: (state) => (date) => state.scheduleListByDate[date] || [],
  },
  actions: {
    fetchScheduleListByDate: waiting(
      'fetch.schedule.list',
      ({ commit }, payload, { cancelToken }) => ScheduleRepository.getScheduleList(payload, { cancelToken })
        .then(({ data }) => {
          if (data) {
            commit(CLEAR_SCHEDULE_LIST);
            commit(SET_SCHEDULE_LIST_BY_DATE, { date: payload.start_date, payload: data });
            // commit(SET_SCHEDULE_CACHE_TIME, payload.start_date);
            return Promise.resolve(data);
          }
          return Promise.reject();
        }),
      { cancel: true },
    ),
    fetchSchedule: waiting(
      'fetch.schedule',
      (store, payload) => ScheduleRepository.getSchedule(payload),
    ),
    fetchScheduleListForTeacher: waiting(
      'fetch.schedule.list',
      ({ commit }, payload, { cancelToken }) => ScheduleRepository.getScheduleListForTeacher(payload, { cancelToken })
        .then(({ data }) => {
          if (data) {
            commit(CLEAR_SCHEDULE_LIST);
            commit(SET_SCHEDULE_LIST_BY_DATE, { date: payload.start_date, payload: data });
            // commit(SET_SCHEDULE_CACHE_TIME, payload.start_date);
            return Promise.resolve(data);
          }
          return Promise.reject();
        }),
      { cancel: true },
    ),
    fetchScheduleListForActiveStudent: waiting(
      'fetch.schedule.list',
      ({ commit, rootState }, payload, { cancelToken }) => {
        const { activeStudentId } = rootState.active;
        if (!activeStudentId) {
          return Promise.reject();
        }
        // eslint-disable-next-line no-param-reassign
        payload.student_id = activeStudentId;
        return CustomerRepository.getLessonsList(payload, { cancelToken }).then(({ data }) => {
          if (data) {
            commit(CLEAR_SCHEDULE_LIST);
            commit(SET_SCHEDULE_LIST_BY_DATE, { date: payload.start_date, payload: data });
            // commit(SET_SCHEDULE_CACHE_TIME, payload.start_date);
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
      { cancel: true },
    ),
    fetchScheduleListByStudentForParent: waiting(
      'fetch.schedule.list',
      ({ commit }, { payload, id }, { cancelToken }) => CustomerRepository
        .getScheduleListByStudentForParent({ payload, id }, { cancelToken })
        .then(({ data }) => {
          if (data) {
            commit(CLEAR_SCHEDULE_LIST);
            commit(SET_SCHEDULE_LIST_BY_DATE, { date: payload.start_date, payload: data });
            // commit(SET_SCHEDULE_CACHE_TIME, payload.start_date);
            return Promise.resolve(data);
          }
          return Promise.reject();
        }),
      { cancel: true },
    ),
    fetchTypes: waiting(
      'fetch.schedule.types',
      ({ commit }) => ScheduleRepository.getTypes().then(({ data }) => {
        if (data) {
          commit(SET_TYPES, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    createSchedule: waiting(
      'create.update.schedule',
      ({ commit }, payload) => ScheduleRepository.createSchedule(payload).then(({ data }) => {
        if (data) {
          commit(ADD_SCHEDULE_TO_LIST, { payload: data });
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    updateSchedule: waiting(
      'create.update.schedule',
      (context, { id, payload }) => ScheduleRepository.putUpdateSchedule({ id, payload }).then(({ data }) => {
        if (data) {
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    deleteSchedule: waiting(
      'delete.schedule',
      (context, id) => ScheduleRepository.deleteSchedule(id).then(({ data }) => Promise.resolve(data)),
    ),
    copySchedule: waiting(
      'copy.schedule',
      ({ rootGetters }, payload) => {
        if (rootGetters['active/getActiveAcademicYearId']) {
          // eslint-disable-next-line no-param-reassign
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        return ScheduleRepository.copySchedule(payload).then(({ data }) => Promise.resolve(data));
      },
    ),
    fetchAudienceLinks: waiting(
      'fetch.audience.links',
      ({ commit }) => AudienceRepository.getAudienceLinks().then(({ data }) => {
        if (data) {
          commit(SET_AUDIENCE_LINKS, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    fetchFiltersOptions: waiting(
      'fetch.filters.options',
      ({ commit }, period) => {
        const outputDate = period?.start ? {
          start: moment(period?.start).format('DD-MM-YYYY'),
          end: moment(period?.end).format('DD-MM-YYYY'),
        } : {
          start: moment(period).format('DD-MM-YYYY'),
          end: moment(period).format('DD-MM-YYYY'),
        };
        return ScheduleRepository.getFiltersOptions(outputDate).then((data) => {
          if (data) {
            commit(SET_FILTER_OPTIONS_BY_DATE, data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
    ),
    createAudienceLink: waiting(
      'create.audience.link',
      (context, payload) => AudienceRepository.postCreateAudienceLink(payload).then(({ data }) => {
        if (data) {
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    fetchSubjectsBySubgroup: waiting(
      'fetch.subjects.by.subgroup',
      ({ commit }, id) => ScheduleRepository.getSubjectsBySubgroup(id).then(({ data }) => {
        if (data) {
          commit(SET_SUBJECTS_BY_SUBGROUP, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    fetchSubjectsByClass: waiting(
      (id) => `fetch.subjects.by.class.${id}`,
      ({ commit }, id) => ClassesRepository.getSubjectsByClassId(id).then(({ data }) => {
        if (data) {
          commit(SET_SUBJECTS_BY_CLASS, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    clearScheduleData: ({ commit }) => {
      commit(CLEAR_SCHEDULE_LIST);
      return Promise.resolve();
    },
    exportSchedule: waiting(
      'export.schedule',
      (store, payload) => exportSchedule(payload),
    ),
  },
  mutations: {
    [SET_TYPES](state, data) {
      Vue.set(state, 'types', data);
    },
    [SET_AUDIENCE_LINKS](state, data) {
      Vue.set(state, 'audienceLinks', data);
    },
    [SET_FILTER_OPTIONS_BY_DATE](state, data) {
      Vue.set(state, 'filtersOptionsByDate', data);
    },
    [SET_SUBJECTS_BY_SUBGROUP](state, data) {
      Vue.set(state, 'subjectsForSelectLessonCreate', data);
    },
    [SET_SUBJECTS_BY_CLASS](state, data) {
      Vue.set(state, 'subjectsForSelectLessonCreate', data);
    },
    [SET_SCHEDULE_LIST_BY_DATE](state, { date, payload }) {
      Vue.set(state.scheduleListByDate, date, payload);
    },

    [ADD_SCHEDULE_TO_LIST](state, { payload }) {
      const date = moment(payload.start_date).format('DD-MM-YYYY');
      if (Object.keys(state.scheduleListByDate).length && state.scheduleListByDate[date]) {
        const foundIndex = state.scheduleListByDate[date].findIndex((i) => i.id === payload.id);
        if (foundIndex > -1) {
          state.scheduleListByDate[date].splice(foundIndex, 1, payload);
        } else {
          state.scheduleListByDate[date].push(payload);
        }
      }
    },
    [SET_SCHEDULE_CACHE_TIME](state, date) {
      if (state.schedulesCacheTime.length === MAX_CACHED_SCHEDULES) {
        const outdatedScheduleDate = minBy(state.schedulesCacheTime, (item) => item.time).date;
        Vue.delete(state.scheduleListByDate, outdatedScheduleDate);
        /* eslint-disable-next-line max-len */
        Vue.delete(state.schedulesCacheTime, state.schedulesCacheTime.findIndex((item) => item.date === outdatedScheduleDate));
      }
      state.schedulesCacheTime.push({ date, time: moment().unix() });
    },
    [CLEAR_SCHEDULE_LIST](state) {
      state.schedulesCacheTime = [];
      state.scheduleListByDate = {};
    },
    [REMOVE_LESSON_FROM_SCHEDULE_LIST](state, { date, id }) {
      const formattedDate = moment(date).format('DD-MM-YYYY');
      if (Object.keys(state.scheduleListByDate).length && state.scheduleListByDate[formattedDate]) {
        // eslint-disable-next-line max-len
        state.scheduleListByDate[formattedDate] = state.scheduleListByDate[formattedDate].filter((l) => l.lesson_id !== id);
      }
    },
  },
};
