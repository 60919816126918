import * as TasksRepository from '@/api/tasks/index';
import waiting from '@/store/waiting';

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  actions: {
    fetchTasks: waiting(
      'fetch.tasks',
      (store, payload) => TasksRepository.getTasks(payload),
    ),
    updateTaskStatus: waiting(
      ({ studentId, taskId }) => `update.task.${studentId}.${taskId}`,
      (store, { studentId, taskId }) => TasksRepository.getTasks({
        students: [studentId],
        lesson_task_id: taskId,
      }).then((tasks) => tasks[0]),
    ),
  },
};
