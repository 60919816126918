import Vue from 'vue';
import * as WeekendsRepository from '@/api/weekends';
import { waiting } from '@/store/waiting';
import {
  SET_WEEKENDS_TYPES,
} from '@/store/modules/weekends/mutation-types';
import {
  ADD_WEEKEND_TO_ACADEMIC_YEAR,
  DELETE_WEEKEND_FROM_ACADEMIC_YEAR,
} from '@/store/modules/academic-years/mutation-types';

export default {
  namespaced: true,
  state: {
    types: null,
  },
  getters: {},
  actions: {
    fetchTypes: waiting(
      'fetch.weekends-types',
      ({ commit }) => WeekendsRepository.getTypesList().then(({ data }) => {
        if (data) {
          commit(SET_WEEKENDS_TYPES, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    create: waiting(
      ({ academic_year_id: academicYearId }) => `create.weekends.${academicYearId}`,
      ({ dispatch }, payload) => WeekendsRepository.postCreate(payload).then(({ data }) => {
        if (data) {
          return dispatch('getById', data.id).then((result) => Promise.resolve(result));
        }
        return Promise.reject();
      }),
    ),
    getById: waiting(
      (id) => `fetch.weekends.${id}`,
      ({ commit }, id) => WeekendsRepository.getById(id).then(({ data }) => {
        if (data) {
          const { academic_year: academicYear } = data;
          if (academicYear.id) {
            commit(
              `academicYears/${ADD_WEEKEND_TO_ACADEMIC_YEAR}`,
              { academicYearId: academicYear.id, payload: data },
              { root: true },
            );
          }
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    deleteById: waiting(
      (id) => `delete.weekends.${id}`,
      ({ commit }, { academicYearId, id }) => WeekendsRepository.deleteById(id).then(() => {
        commit(
          `academicYears/${DELETE_WEEKEND_FROM_ACADEMIC_YEAR}`,
          { academicYearId, id },
          { root: true },
        );
        return Promise.resolve();
      }),
    ),
    update: waiting(
      ({ id }) => `update.weekends.${id}`,
      ({ dispatch }, { id, payload }) => WeekendsRepository.putUpdate(id, payload).then(({ data }) => {
        if (data) {
          return dispatch('getById', data.id).then((result) => Promise.resolve(result));
        }
        return Promise.reject();
      }),
    ),
  },
  mutations: {
    [SET_WEEKENDS_TYPES](state, payload) {
      Vue.set(state, 'types', payload);
    },
  },
};
