import http from '@/api';

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getHomeworkProgress = (payload) => http.get('/api/admin/journal/grades/homework/progress', {
  params: payload,
});

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getHomeworkMarks = (payload) => http.get('/api/admin/journal/grades/homework/marks', {
  params: payload,
});

/**
 * @param {number} gradeId
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const putGradesRatingsByGrade = (gradeId, payload) => http.put(`api/admin/journal/grades/${gradeId}`, payload);
