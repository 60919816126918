/* eslint-disable import/prefer-default-export */
export const SET_PROGRESSING_LIST = 'SET_PROGRESSING_LIST';
export const SET_LESSONS_LIST_BY_DATE = 'SET_LESSONS_LIST_BY_DATE';
export const DROP_LESSONS_LIST_EXCEPT_DATE = 'DROP_LESSONS_LIST_EXCEPT_DATE';
export const SET_PROGRESS_BY_ID = 'SET_PROGRESS_BY_ID';
export const SET_PROGRESS_CUSTOMER_ID = 'SET_PROGRESS_CUSTOMER_ID';
export const SET_VISITINGS_BY_DATE_CLASS = 'SET_VISITINGS_BY_DATE_CLASS';
export const SET_HOMEWORK_PROGRESS = 'SET_HOMEWORK_PROGRESS';
export const SET_HOMEWORK_MARKS = 'SET_HOMEWORK_MARKS';
export const SET_FINAL_GRADES_TYPES = 'SET_FINAL_GRADES_TYPES';
export const SET_FINAL_GRADES_SUMMARY = 'SET_FINAL_GRADES_SUMMARY';
export const SET_FINAL_GRADES_GRADES_LIST = 'SET_FINAL_GRADES_GRADES_LIST';
export const ADD_HOMEWORK_MARKS = 'ADD_HOMEWORK_MARKS';
export const ADD_PROGRESS_GRADES = 'ADD_PROGRESS_GRADES';
export const ADD_VISITINGS_BY_DATE = 'ADD_VISITINGS_BY_DATE';
export const ADD_FINAL_GRADES_GRADE = 'ADD_FINAL_GRADES_GRADE';
export const ADD_FINAL_GRADES_GRADES_LIST = 'ADD_FINAL_GRADES_GRADES_LIST';
export const DELETE_FINAL_GRADES = 'DELETE_FINAL_GRADES';
export const SET_PROGRAM_PROGRESS_LIST = 'SET_PROGRAM_PROGRESS_LIST';
export const SET_PROGRAM_PROGRESS_DETAILS = 'SET_PROGRAM_PROGRESS_DETAILS';
export const SET_FINAL_GRADES_GRADES_LIST_ALL = 'SET_FINAL_GRADES_GRADES_LIST_ALL';
