import Vue from 'vue';
import Meta from 'vue-meta';
import VueWait from 'vue-wait';
import Vuelidate from 'vuelidate';
import PortalVue from 'portal-vue';
import VBlur from 'v-blur';
import VModal from 'vue-js-modal/dist/index.nocss';
import Notifications from 'vue-notification';
import VueScreen from 'vue-screen';
import VueDiv100vh from 'vue-div-100vh';
import Hotjar from 'vue-hotjar';
import vueTabevents from 'vue-tabevents';

import router from '@/router';
import store from '@/store';
import i18n from '@/i18n';
import { initialize as httpInitialize } from '@/api';
import '@/notify';
import '@/inputmask';
import '@/filters';

import App from '@/App.vue';
import '@/assets/styles/index.scss';

import initMixin from '@/mixins/init';
// import markerIoMixin from '@/mixins/marker.io';
import '@/directives/deploy.directive';
import '@/directives/loading.directive';
import NothingFound from '@/components/NothingFound.vue';
import globalVariables from '@/mixins/globalVariables';

Vue.use(Meta, {
  refreshOnceOnNavigation: true,
});
Vue.use(VueWait);
Vue.use(Vuelidate);
Vue.use(PortalVue);
Vue.use(VModal);
Vue.use(VBlur);
Vue.use(VueScreen, 'tailwind');
Vue.use(Notifications, {
  componentName: 'VueNotifications',
});
Vue.use(vueTabevents);
Vue.use(globalVariables);

if (process.env.VUE_APP_HOTJAR_ID) {
  Vue.use(Hotjar, {
    id: process.env.VUE_APP_HOTJAR_ID,
    isProduction: process.env.NODE_ENV === 'production',
  });
}

Vue.component('VueDiv100vh', VueDiv100vh);
Vue.component('NothingFound', NothingFound);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  wait: new VueWait({
    useVuex: true,
    registerComponent: false,
    registerDirective: false,
  }),
  mixins: [
    initMixin,
    // markerIoMixin,
  ],
  beforeCreate() {
    httpInitialize(this.$store, this.$router);
  },
  render: (h) => h(App),
}).$mount('#app');
