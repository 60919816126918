<template>
  <div class="cursor-pointer">
    <!-- eslint-disable -->
    <div
      class="flex flex-col items-stretch border rounded-4px overflow-hidden w-400px bg-white relative text-sm md:text-base"
      :class="{
        'border-success-50': data.type === 'success',
        'border-danger-200': data.type === 'danger',
        '!w-300px': isMobileOnly
      }"
    >
    <!-- eslint-enable -->
      <div class="flex flex-row items-start py-4 px-6 font-semibold space-x-4 justify-center md:justify-start">
        <div class="w-6 h-6 flex-shrink-0">
          <template v-if="data.type === 'success'">
            <IconSuccess class="w-full h-full text-success-50" />
          </template>
          <template v-if="data.type === 'danger'">
            <IconCaution class="w-full h-full text-danger-200" />
          </template>
        </div>
        <span>{{ data.text }}</span>
      </div>
      <div class="h-px flex flex-row justify-start absolute w-full left-0 bottom-0">
        <div
          class="w-full transform translate-x-0"
          :class="{
            '-translate-x-full': launch,
            'bg-success-50': data.type === 'success',
            'bg-danger-200': data.type === 'danger',
          }"
          :style="{ transition: `transform ${data.length}ms linear` }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import IconCaution from '@/assets/icons/caution.svg?component';
import IconSuccess from '@/assets/icons/success.svg?component';
import { screenDetectMixin } from '@/mixins/screenDetect';

export default {
  name: 'NotificationsToast',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    IconCaution,
    IconSuccess,
  },
  mixins: [screenDetectMixin],
  data() {
    return {
      delay: 50,
      launch: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.launch = true;
    }, this.delay);
  },
};
</script>
