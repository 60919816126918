import http from '@/api';

/**
 * @param {object} payload
 * @param {object} config
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line import/prefer-default-export
export const getList = (payload, config) => http.get(
  '/api/admin/journal',
  {
    params: payload,
    cancelToken: config?.cancelToken,
  },
);

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getVisitings = (payload, config) => http.get('api/admin/journal/visitings', {
  params: payload,
  cancelToken: config?.cancelToken,
});

/**
 * @param {object} payload
 * @param {number} visitingId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const setVisitings = (visitingId, payload) => http.put(`api/admin/journal/visitings/${visitingId}`, payload);
