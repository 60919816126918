import http from '@/api';

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getList = (payload) => http.get('/api/admin/classes', {
  params: payload,
});

/**
 * @param {object} config
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getMyList = (payload, config) => http.get('/api/customer/me/classes', {
  params: payload,
  cancelToken: config?.cancelToken,
});

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getTypes = () => http.get('/api/admin/classes/types');

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postCreate = (payload) => http.post('/api/admin/classes', payload);

/**
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postAttachStudents = (id, payload) => http.post(
  `/api/admin/classes/${id}/students/attach`,
  payload,
);

/**
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postDetachStudents = (id, payload) => http.post(
  `/api/admin/classes/${id}/students/detach`,
  payload,
);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getById = (id) => http.get(`/api/admin/classes/${id}`);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteById = (id) => http.delete(`/api/admin/classes/${id}`);

/**
 * @param {number} id
 * @param {Object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getStudentsByClassId = (id, payload) => http.get(`/api/admin/classes/${id}/students`, {
  params: payload,
});

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getGroupsByClassId = (id) => http.get(`/api/admin/classes/${id}/groups`);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSubjectsByClassId = (id) => http.get(`/api/admin/classes/${id}/subjects`);

/**
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const putUpdate = (id, payload) => http.put(
  `/api/admin/classes/${id}`,
  payload,
);

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postCreateLetter = (payload) => http.post(
  '/api/admin/classes/letters',
  payload,
);

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postCreateNumber = (payload) => http.post(
  '/api/admin/classes/numbers',
  payload,
);
