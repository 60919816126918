import Vue from 'vue';
import * as TrainingProgramsRepository from '@/api/training-programs';
import * as SubjectsRepository from '@/api/training-programs/subjects';
import * as SectionsRepository from '@/api/training-programs/sections';
import * as TopicsRepository from '@/api/training-programs/topics';
import { waiting } from '@/store/waiting';
import {
  ADD_SECTION_TO_SUBJECT,
  ADD_SUBJECT_TO_TRAINING_PROGRAM_TREE,
  ADD_TRAINING_PROGRAM_SUBJECT_SECTION_TOPICS,
  ADD_TRAINING_PROGRAM_TO_LIST,
  DELETE_SECTION_FROM_SUBJECT,
  DELETE_SUBJECT_FROM_TRAINING_PROGRAM_TREE,
  DELETE_TOPIC_FROM_SUBJECT_SECTION,
  DROP_TRAINING_PROGRAM_TREE,
  REMOVE_TRAINING_PROGRAM_FROM_LIST,
  UPDATE_SUBJECT_SECTION,
  SET_SUBJECT_SECTION_TOPIC_POSITION,
  SET_TRAINING_PROGRAM_SUBJECT_SECTION_TOPICS,
  SET_TRAINING_PROGRAM_TREE,
  SET_TRAINING_PROGRAMS_LIST,
  UPDATE_TRAINING_PROGRAM_TREE_SUBJECT,
  DROP_TRAINING_PROGRAMS_LIST,
  SET_SUBJECTS_FOR_SELECTS,
  SET_SUBJECTS_TRAINING_PROGRAM_ID,
  SET_TOPICS_BY_SECTION,
  SET_TOPICS_BY_SUBJECT_ONLY,
} from '@/store/modules/training-programs/mutation-types';
import { swapArrayObjectPositionProperty } from '@/utils/index';
import cloneDeep from 'lodash.clonedeep';
import orderBy from 'lodash.orderby';

export default {
  namespaced: true,
  state: {
    trainingProgramsList: null,
    trainingProgramsTrees: {},
    subjectsSectionsTopics: {},
    topicsBySubjectOnly: {},
    subjectsForSelectsByAcademicYearId: {},
    subjectsByProgramId: {},
  },
  getters: {
    getTrainingProgramsList: (state) => {
      if (state.trainingProgramsList?.data) {
        return state.trainingProgramsList.data;
      }
      return [];
    },
    getSubjectSectionsTopics: (state) => (id) => state.subjectsSectionsTopics[id] || [],
    getTrainingProgramTree: (state) => (id) => {
      if (state.trainingProgramsTrees[id]) {
        const data = state.trainingProgramsTrees[id];
        if (data.subjects) {
          data.subjects = data.subjects.map((subject) => {
            if (subject.sections) {
              // eslint-disable-next-line no-param-reassign
              subject.sections = orderBy(subject.sections, 'position');
            }
            return subject;
          });
        }
        return data;
      }
      return null;
    },
    getTrainingProgramSubjectSectionTopics: (state) => (trainingProgramId, subjectId, sectionId) => {
      if (state.trainingProgramsTrees[trainingProgramId]?.subjects) {
        const subjectIndex = state.trainingProgramsTrees[trainingProgramId].subjects.findIndex(
          (s) => s.id === subjectId,
        );
        if (subjectIndex > -1) {
          // eslint-disable-next-line max-len
          const sectionIndex = state.trainingProgramsTrees[trainingProgramId].subjects[subjectIndex]?.sections.findIndex(
            (s) => s.id === sectionId,
          );
          if (sectionIndex > -1) {
            // eslint-disable-next-line max-len
            return state.trainingProgramsTrees[trainingProgramId].subjects[subjectIndex]?.sections[sectionIndex]?.topics || [];
          }
        }
      }
      return [];
    },
    getSubjectsForSelects: (state) => (id) => state.subjectsForSelectsByAcademicYearId[id] || [],
    getSubjectsByTrainingProgramId: (state) => (id) => state.subjectsByProgramId[id] || [],
    getTopicsBySubjectOnly: (state) => (trainingProgramId, subjectId) => {
      const key = `${trainingProgramId}-${subjectId}`;
      return state.topicsBySubjectOnly[key] || [];
    },
    getAllSubjects(state, getters, rootState, rootGetters) {
      const subjectsObj = state.subjectsForSelectsByAcademicYearId[rootGetters['active/getActiveAcademicYearId']];
      if (!subjectsObj) return null;
      return Object.keys(subjectsObj).map((key) => ({ name: key, ids: subjectsObj[key], id: key }));
    },
  },
  actions: {
    fetch: waiting(
      'fetch.training-programs',
      ({ commit, rootGetters }) => {
        const payload = {};
        if (rootGetters['active/getActiveAcademicYearId']) {
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        return TrainingProgramsRepository.getList(payload).then(({ data }) => {
          if (data) {
            commit(SET_TRAINING_PROGRAMS_LIST, data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
    ),
    create: waiting(
      'create.training-programs',
      ({ commit }, payload) => TrainingProgramsRepository.postCreate(payload).then(({ data }) => {
        if (data) {
          commit(ADD_TRAINING_PROGRAM_TO_LIST, data);
          return Promise.resolve();
        }
        return Promise.reject();
      }),
    ),
    deleteById: waiting(
      (id) => `delete.training-programs.${id}`,
      ({ commit }, id) => TrainingProgramsRepository.deleteById(id).then(() => {
        commit(REMOVE_TRAINING_PROGRAM_FROM_LIST, { id });
        return Promise.resolve();
      }),
    ),
    update: waiting(
      ({ id }) => `update.training-programs.${id}`,
      ({ commit }, { id, payload }) => TrainingProgramsRepository.putUpdate(id, payload).then(({ data }) => {
        if (data) {
          commit(ADD_TRAINING_PROGRAM_TO_LIST, data);
          return Promise.resolve();
        }
        return Promise.reject();
      }),
    ),
    fetchTopicBySubjectOnly: (
      'fetch.topic.by.subject.only',
      ({ commit }, { trainingProgramId, subjectId }) => TopicsRepository
        .getListBySubjectOnly(trainingProgramId, subjectId)
        .then(({ data }) => {
          if (data) {
            commit(SET_TOPICS_BY_SUBJECT_ONLY, { trainingProgramId, subjectId, payload: data });
            return Promise.resolve();
          }
          return Promise.reject();
        })
    ),
    fetchSubjectsById: waiting(
      (id) => `fetch.training-programs.${id}.subjects`,
      ({ commit }, id) => SubjectsRepository.getListByTrainingProgramId(id).then(({ data }) => {
        if (data) {
          commit(SET_SUBJECTS_TRAINING_PROGRAM_ID, { id, data });
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    fetchTreeById: waiting(
      (id) => `fetch.training-programs.${id}.tree`,
      ({ commit }, id) => TrainingProgramsRepository.getTreeById(id).then(({ data }) => {
        if (data) {
          commit(SET_TRAINING_PROGRAM_TREE, { id, data });
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    createSubject: waiting(
      ({ id }) => `create.training-programs.${id}.subject`,
      ({ commit }, { id, payload }) => SubjectsRepository.postCreate(id, payload).then(({ data }) => {
        if (data) {
          commit(ADD_SUBJECT_TO_TRAINING_PROGRAM_TREE, { trainingProgramId: id, subject: data });
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    updateSubject: waiting(
      ({ trainingProgramId, subjectId }) => `update.training-programs.${trainingProgramId}.subject.${subjectId}`,
      ({ commit }, { trainingProgramId, subjectId, payload }) => SubjectsRepository.putUpdate(
        trainingProgramId,
        subjectId,
        payload,
      ).then(({ data }) => {
        if (data) {
          commit(UPDATE_TRAINING_PROGRAM_TREE_SUBJECT, {
            trainingProgramId,
            subjectId,
            payload: data,
          });
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    deleteSubject: waiting(
      ({ id }) => `delete.training-programs.${id}.subject`,
      ({ commit }, { trainingProgramId, subjectId }) => SubjectsRepository.deleteById(
        trainingProgramId,
        subjectId,
      ).then(() => {
        commit(DELETE_SUBJECT_FROM_TRAINING_PROGRAM_TREE, { trainingProgramId, subjectId });
        return Promise.resolve();
      }),
    ),
    createSubjectSection: waiting(
      ({
        trainingProgramId,
        subjectId,
      }) => `create.training-programs.${trainingProgramId}.subject.${subjectId}.section`,
      ({ commit }, { trainingProgramId, subjectId, payload }) => SectionsRepository.postCreate(
        trainingProgramId,
        subjectId,
        payload,
      ).then(({ data }) => {
        if (data) {
          commit(ADD_SECTION_TO_SUBJECT, {
            trainingProgramId,
            subjectId,
            section: data,
          });
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    createSubjectSectionBatch: waiting(
      ({
        trainingProgramId,
        subjectId,
      }) => `create.training-programs.${trainingProgramId}.subject.${subjectId}.section-batch`,
      ({ commit }, { trainingProgramId, subjectId, payload }) => SectionsRepository.postCreateBatch(
        trainingProgramId,
        subjectId,
        payload,
      ).then(({ data }) => {
        if (data) {
          if (Array.isArray(data)) {
            data.forEach((section) => commit(ADD_SECTION_TO_SUBJECT, {
              trainingProgramId,
              subjectId,
              section,
            }));
          }
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    updateSubjectSectionOnBackend: waiting(
      ({
        trainingProgramId,
        subjectId,
        sectionId,
      }) => `update.training-programs.${trainingProgramId}.subject.${subjectId}.section.${sectionId}`,
      (context, {
        trainingProgramId,
        subjectId,
        sectionId,
        payload,
      }) => SectionsRepository.putUpdate(
        trainingProgramId,
        subjectId,
        sectionId,
        payload,
      ).then(({ data }) => {
        if (data) {
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    deleteSubjectSection: waiting(
      ({
        trainingProgramId,
        subjectId,
        sectionId,
      }) => `create.training-programs.${trainingProgramId}.subject.${subjectId}.section.${sectionId}`,
      ({ commit }, { trainingProgramId, subjectId, sectionId }) => SectionsRepository.deleteById(
        trainingProgramId,
        subjectId,
        sectionId,
      ).then(() => {
        commit(DELETE_SECTION_FROM_SUBJECT, { trainingProgramId, subjectId, sectionId });
        return Promise.resolve();
      }),
    ),
    fetchSubjectSectionTopics: waiting(
      ({
        trainingProgramId,
        subjectId,
        sectionId,
      }) => `fetch.training-programs.${trainingProgramId}.subject.${subjectId}.section.${sectionId}.topics`,
      ({ commit }, { trainingProgramId, subjectId, sectionId }) => TopicsRepository.getList(
        trainingProgramId,
        subjectId,
        sectionId,
      ).then(({ data }) => {
        if (data) {
          commit(SET_TRAINING_PROGRAM_SUBJECT_SECTION_TOPICS, {
            trainingProgramId,
            subjectId,
            sectionId,
            data,
          });
          commit(SET_TOPICS_BY_SECTION, { trainingProgramId, data });
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    createSubjectSectionTopicsBatch: waiting(
      ({
        trainingProgramId,
        subjectId,
        sectionId,
      }) => `create.training-programs.${trainingProgramId}.subject.${subjectId}.section.${sectionId}.topics-batch`,
      ({ commit }, {
        trainingProgramId,
        subjectId,
        sectionId,
        payload,
      }) => TopicsRepository.postCreateBatch(
        trainingProgramId,
        subjectId,
        sectionId,
        payload,
      ).then(({ data }) => {
        if (data) {
          commit(ADD_TRAINING_PROGRAM_SUBJECT_SECTION_TOPICS, {
            trainingProgramId,
            subjectId,
            sectionId,
            topics: data,
          });
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    deleteSubjectSectionTopic: waiting(
      ({
        trainingProgramId,
        subjectId,
        sectionId,
        topicId,
      }) => `delete.training-programs.${trainingProgramId}.subject.${subjectId}.section.${sectionId}.topic.${topicId}`,
      ({ commit }, {
        trainingProgramId,
        subjectId,
        sectionId,
        topicId,
      }) => TopicsRepository.deleteById(
        trainingProgramId,
        subjectId,
        sectionId,
        topicId,
      ).then(() => {
        commit(DELETE_TOPIC_FROM_SUBJECT_SECTION, {
          trainingProgramId,
          subjectId,
          sectionId,
          topicId,
        });
      }),
    ),
    updateSubjectSection: ({ commit, dispatch }, {
      trainingProgramId,
      subjectId,
      sectionId,
      position,
      name,
      startDate,
      endDate,
    }) => {
      commit(UPDATE_SUBJECT_SECTION, {
        trainingProgramId,
        subjectId,
        sectionId,
        position,
        name,
        startDate,
        endDate,
      });
      return dispatch('updateSubjectSectionOnBackend', {
        trainingProgramId,
        subjectId,
        sectionId,
        payload: {
          position,
          name,
          start_date: startDate,
          end_date: endDate,
        },
      });
    },
    changeSubjectSectionTopicPosition: ({ commit, dispatch }, {
      trainingProgramId,
      subjectId,
      sectionId,
      topicId,
      position,
    }) => {
      commit(SET_SUBJECT_SECTION_TOPIC_POSITION, {
        trainingProgramId,
        subjectId,
        sectionId,
        topicId,
        position,
      });
      return dispatch('updateSubjectSectionTopic', {
        trainingProgramId,
        subjectId,
        sectionId,
        topicId,
        payload: {
          position,
        },
      });
    },
    updateSubjectSectionTopic: waiting(
      ({
        trainingProgramId,
        subjectId,
        sectionId,
        topicId,
      }) => `update.training-programs.${trainingProgramId}.subject.${subjectId}.section.${sectionId}.topic.${topicId}`,
      ({ commit }, {
        trainingProgramId,
        subjectId,
        sectionId,
        topicId,
        payload,
      }) => TopicsRepository.putUpdate(
        trainingProgramId,
        subjectId,
        sectionId,
        topicId,
        payload,
      ).then(({ data }) => {
        commit(ADD_TRAINING_PROGRAM_SUBJECT_SECTION_TOPICS, {
          trainingProgramId,
          subjectId,
          sectionId,
          topics: [data],
        });
      }),
    ),
    postImport: waiting(
      ({ trainingProgramId }) => `import.training-programs.${trainingProgramId}`,
      (context, {
        trainingProgramId,
        payload,
        config,
      }) => TrainingProgramsRepository.postImport(trainingProgramId, payload, config)
        .then((data) => Promise.resolve(data)).catch((res) => Promise.reject(res.response.data)),
    ),
    exportProgram: waiting(
      (program) => `program.${program}`,
      (store, program) => TrainingProgramsRepository.exportProgram(program),
    ),
    fetchSubjectsForSelects: waiting(
      () => 'fetch.all.subjects',
      ({ commit, rootGetters }) => {
        const payload = {};
        if (rootGetters['active/getActiveAcademicYearId']) {
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        return TrainingProgramsRepository.getSubjectsForSelects(payload).then(({ data }) => {
          if (data) {
            commit(SET_SUBJECTS_FOR_SELECTS,
              { academicYearId: rootGetters['active/getActiveAcademicYearId'], payload: data });
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
    ),
  },
  mutations: {
    [SET_TRAINING_PROGRAMS_LIST](state, payload) {
      Vue.set(state, 'trainingProgramsList', payload);
    },
    [ADD_TRAINING_PROGRAM_TO_LIST](state, payload) {
      if (!state.trainingProgramsList) {
        Vue.set(state, 'trainingProgramsList', { data: [payload] });
      } else {
        const { id } = payload;
        const foundIndex = state.trainingProgramsList.data.findIndex((o) => parseInt(o.id, 10) === parseInt(id, 10));
        if (foundIndex > -1) {
          state.trainingProgramsList.data.splice(foundIndex, 1, payload);
        } else {
          state.trainingProgramsList.data.push(payload);
        }
      }
    },
    [REMOVE_TRAINING_PROGRAM_FROM_LIST](state, { id }) {
      if (state.trainingProgramsList?.data) {
        state.trainingProgramsList.data = state.trainingProgramsList.data.filter(
          (o) => parseInt(o.id, 10) !== parseInt(id, 10),
        );
      }
    },
    [SET_TRAINING_PROGRAM_TREE](state, { id, data }) {
      Vue.set(state.trainingProgramsTrees, id, data);
    },
    [DROP_TRAINING_PROGRAM_TREE](state, id) {
      if (state.trainingProgramsTrees[id]) {
        Vue.delete(state.trainingProgramsTrees, id);
      }
    },
    [ADD_SUBJECT_TO_TRAINING_PROGRAM_TREE](state, { trainingProgramId, subject }) {
      if (state.trainingProgramsTrees[trainingProgramId]) {
        if (!state.trainingProgramsTrees[trainingProgramId].subjects) {
          state.trainingProgramsTrees[trainingProgramId].subjects = [];
        }
        const { id } = subject;
        const foundIndex = state.trainingProgramsTrees[trainingProgramId].subjects.findIndex(
          (o) => parseInt(o.id, 10) === parseInt(id, 10),
        );
        if (foundIndex > -1) {
          state.trainingProgramsTrees[trainingProgramId].subjects.splice(foundIndex, 1, subject);
        } else {
          state.trainingProgramsTrees[trainingProgramId].subjects.push(subject);
        }
      }
    },
    [DELETE_SUBJECT_FROM_TRAINING_PROGRAM_TREE](state, { trainingProgramId, subjectId }) {
      if (state.trainingProgramsTrees[trainingProgramId]?.subjects) {
        const result = state.trainingProgramsTrees[trainingProgramId].subjects.filter(
          (s) => parseInt(s.id, 10) !== parseInt(subjectId, 10),
        );
        state.trainingProgramsTrees[trainingProgramId].subjects = result;
      }
    },
    [ADD_SECTION_TO_SUBJECT](state, { trainingProgramId, subjectId, section }) {
      if (state.trainingProgramsTrees[trainingProgramId]) {
        if (state.trainingProgramsTrees[trainingProgramId].subjects) {
          // eslint-disable-next-line max-len
          state.trainingProgramsTrees[trainingProgramId].subjects = state.trainingProgramsTrees[trainingProgramId].subjects.map((subject) => {
            if (parseInt(subject.id, 10) === parseInt(subjectId, 10)) {
              if (!subject.sections) {
                Vue.set(subject, 'sections', []);
              }
              const { id } = section;
              const foundIndex = subject.sections.findIndex(
                (o) => parseInt(o.id, 10) === parseInt(id, 10),
              );
              if (foundIndex > -1) {
                subject.sections.splice(foundIndex, 1, section);
              } else {
                subject.sections.push(section);
              }
            }
            return subject;
          });
        }
      }
    },
    [DELETE_SECTION_FROM_SUBJECT](state, { trainingProgramId, subjectId, sectionId }) {
      if (state.trainingProgramsTrees[trainingProgramId]?.subjects) {
        const foundSubjectIndex = state.trainingProgramsTrees[trainingProgramId].subjects.findIndex(
          (s) => parseInt(s.id, 10) === parseInt(subjectId, 10),
        );
        // eslint-disable-next-line max-len
        if (foundSubjectIndex > -1 && state.trainingProgramsTrees[trainingProgramId].subjects[foundSubjectIndex]?.sections) {
          const result = state.trainingProgramsTrees[trainingProgramId].subjects[foundSubjectIndex].sections.filter(
            (section) => parseInt(section.id, 10) !== parseInt(sectionId, 10),
          );
          state.trainingProgramsTrees[trainingProgramId].subjects[foundSubjectIndex].sections = result;
        }
      }
    },
    [SET_TRAINING_PROGRAM_SUBJECT_SECTION_TOPICS](state, {
      trainingProgramId,
      subjectId,
      sectionId,
      data,
    }) {
      if (state.trainingProgramsTrees[trainingProgramId]?.subjects) {
        const subjectIndex = state.trainingProgramsTrees[trainingProgramId].subjects.findIndex(
          (s) => parseInt(s.id, 10) === parseInt(subjectId, 10),
        );
        if (subjectIndex > -1 && state.trainingProgramsTrees[trainingProgramId].subjects[subjectIndex].sections) {
          const sectionIndex = state.trainingProgramsTrees[trainingProgramId].subjects[subjectIndex].sections.findIndex(
            (s) => parseInt(s.id, 10) === parseInt(sectionId, 10),
          );
          if (sectionIndex > -1) {
            if (!state.trainingProgramsTrees[trainingProgramId].subjects[subjectIndex].sections[sectionIndex].topics) {
              Vue.set(
                state.trainingProgramsTrees[trainingProgramId].subjects[subjectIndex].sections[sectionIndex],
                'topics',
                [],
              );
            }
            state.trainingProgramsTrees[trainingProgramId].subjects[subjectIndex].sections[sectionIndex].topics = data;
          }
        }
      }
    },
    [ADD_TRAINING_PROGRAM_SUBJECT_SECTION_TOPICS](state, {
      trainingProgramId,
      subjectId,
      sectionId,
      topics,
    }) {
      if (state.trainingProgramsTrees[trainingProgramId]?.subjects) {
        const subjectIndex = state.trainingProgramsTrees[trainingProgramId].subjects.findIndex(
          (s) => parseInt(s.id, 10) === parseInt(subjectId, 10),
        );
        if (subjectIndex > -1 && state.trainingProgramsTrees[trainingProgramId].subjects[subjectIndex].sections) {
          const sectionIndex = state.trainingProgramsTrees[trainingProgramId].subjects[subjectIndex].sections.findIndex(
            (s) => parseInt(s.id, 10) === parseInt(sectionId, 10),
          );
          if (sectionIndex > -1) {
            if (!state.trainingProgramsTrees[trainingProgramId].subjects[subjectIndex].sections[sectionIndex].topics) {
              Vue.set(
                state.trainingProgramsTrees[trainingProgramId].subjects[subjectIndex].sections[sectionIndex],
                'topics',
                [],
              );
            }
            topics.forEach((topic) => {
              const { id: topicId } = topic;
              // eslint-disable-next-line max-len
              const topicIndex = state.trainingProgramsTrees[trainingProgramId].subjects[subjectIndex].sections[sectionIndex].topics.findIndex(
                (o) => parseInt(o.id, 10) === parseInt(topicId, 10),
              );
              if (topicIndex > -1) {
                // eslint-disable-next-line max-len
                state.trainingProgramsTrees[trainingProgramId].subjects[subjectIndex].sections[sectionIndex].topics.splice(
                  topicIndex,
                  1,
                  topic,
                );
              } else {
                // eslint-disable-next-line max-len
                state.trainingProgramsTrees[trainingProgramId].subjects[subjectIndex].sections[sectionIndex].topics.push(topic);
                // eslint-disable-next-line max-len
                state.trainingProgramsTrees[trainingProgramId].subjects[subjectIndex].sections[sectionIndex].topics_count += 1;
              }
            });
          }
        }
      }
    },
    [DELETE_TOPIC_FROM_SUBJECT_SECTION](state, {
      trainingProgramId,
      subjectId,
      sectionId,
      topicId,
    }) {
      if (state.trainingProgramsTrees[trainingProgramId]?.subjects) {
        const subjectIndex = state.trainingProgramsTrees[trainingProgramId].subjects.findIndex(
          (s) => parseInt(s.id, 10) === parseInt(subjectId, 10),
        );
        if (subjectIndex > -1 && state.trainingProgramsTrees[trainingProgramId].subjects[subjectIndex].sections) {
          const sectionIndex = state.trainingProgramsTrees[trainingProgramId].subjects[subjectIndex].sections.findIndex(
            (s) => parseInt(s.id, 10) === parseInt(sectionId, 10),
          );
          if (sectionIndex > -1) {
            if (state.trainingProgramsTrees[trainingProgramId].subjects[subjectIndex].sections[sectionIndex].topics) {
              // eslint-disable-next-line max-len
              state.trainingProgramsTrees[trainingProgramId].subjects[subjectIndex].sections[sectionIndex].topics = state.trainingProgramsTrees[trainingProgramId].subjects[subjectIndex].sections[sectionIndex].topics.filter(
                (t) => parseInt(t.id, 10) !== parseInt(topicId, 10),
              );
              // eslint-disable-next-line max-len
              state.trainingProgramsTrees[trainingProgramId].subjects[subjectIndex].sections[sectionIndex].topics_count = state.trainingProgramsTrees[trainingProgramId].subjects[subjectIndex].sections[sectionIndex].topics.length;
            }
          }
        }
      }
    },
    [UPDATE_SUBJECT_SECTION](state, {
      trainingProgramId,
      subjectId,
      sectionId,
      position,
      name,
      startDate,
      endDate,
    }) {
      if (state.trainingProgramsTrees[trainingProgramId]?.subjects) {
        const foundSubjectIndex = state.trainingProgramsTrees[trainingProgramId].subjects.findIndex(
          (s) => s.id === subjectId,
        );
        if (foundSubjectIndex > -1) {
          let sections = state.trainingProgramsTrees[trainingProgramId].subjects[foundSubjectIndex].sections || [];
          const foundSectionIndex = sections.findIndex((s) => s.id === sectionId);
          if (foundSectionIndex > -1) {
            if (name) {
              sections[foundSectionIndex].name = name;
            }
            sections[foundSectionIndex].start_date = startDate;
            sections[foundSectionIndex].end_date = endDate;
            if (position) {
              const oldPosition = sections[foundSectionIndex]?.position;
              sections = swapArrayObjectPositionProperty(
                cloneDeep(sections),
                (s) => s.id === sectionId,
                oldPosition,
                position,
              );
              Vue.set(
                state.trainingProgramsTrees[trainingProgramId].subjects[foundSubjectIndex],
                'sections',
                orderBy(
                  sections,
                  'position',
                ),
              );
            }
          }
        }
      }
    },
    [SET_SUBJECT_SECTION_TOPIC_POSITION](state, {
      trainingProgramId,
      subjectId,
      sectionId,
      topicId,
      position,
    }) {
      if (state.trainingProgramsTrees[trainingProgramId]?.subjects) {
        const foundSubjectIndex = state.trainingProgramsTrees[trainingProgramId].subjects.findIndex(
          (s) => s.id === subjectId,
        );
        if (foundSubjectIndex > -1) {
          const sections = orderBy(
            state.trainingProgramsTrees[trainingProgramId].subjects[foundSubjectIndex].sections || [],
            'position',
          );
          const foundSectionIndex = sections.findIndex((s) => s.id === sectionId);
          if (foundSectionIndex > -1) {
            let topics = sections[foundSectionIndex].topics || [];
            const foundTopicIndex = topics.findIndex((s) => s.id === topicId);
            if (foundTopicIndex > -1) {
              const oldPosition = sections[foundSectionIndex].topics[foundTopicIndex]?.position;
              topics = swapArrayObjectPositionProperty(
                cloneDeep(topics),
                (s) => s.id === topicId,
                oldPosition,
                position,
              );
              Vue.set(
                state.trainingProgramsTrees[trainingProgramId].subjects[foundSubjectIndex].sections[foundSectionIndex],
                'topics',
                orderBy(
                  topics,
                  'position',
                ),
              );
            }
          }
        }
      }
    },
    [UPDATE_TRAINING_PROGRAM_TREE_SUBJECT](state, { trainingProgramId, subjectId, payload }) {
      if (state.trainingProgramsTrees[trainingProgramId]?.subjects) {
        const foundSubjectIndex = state.trainingProgramsTrees[trainingProgramId].subjects.findIndex(
          (s) => s.id === subjectId,
        );
        if (foundSubjectIndex > -1) {
          Vue.set(state.trainingProgramsTrees[trainingProgramId].subjects, foundSubjectIndex, {
            ...state.trainingProgramsTrees[trainingProgramId].subjects[foundSubjectIndex],
            ...payload,
          });
        }
      }
    },
    [DROP_TRAINING_PROGRAMS_LIST](state) {
      state.trainingProgramsList = null;
    },
    [SET_SUBJECTS_FOR_SELECTS](state, { academicYearId, payload }) {
      Vue.set(state.subjectsForSelectsByAcademicYearId, academicYearId, payload);
    },
    [SET_SUBJECTS_TRAINING_PROGRAM_ID](state, { id, data }) {
      Vue.set(state.subjectsByProgramId, id, data);
    },
    [SET_TOPICS_BY_SECTION](state, { trainingProgramId, data }) {
      Vue.set(state.subjectsSectionsTopics, trainingProgramId, data);
    },
    [SET_TOPICS_BY_SUBJECT_ONLY](state, { trainingProgramId, subjectId, payload }) {
      Vue.set(state.topicsBySubjectOnly, `${trainingProgramId}-${subjectId}`, payload);
    },
  },
};
