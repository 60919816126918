import http from '@/api';
import { ability } from '@/store/plugins/casl';

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getAudienceLinks = () => {
  const rootUrl = ability.can('api', 'admin') ? 'admin' : 'customer';
  return http.get(`/api/${rootUrl}/audience-links`);
};

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postCreateAudienceLink = (payload) => http.post('/api/admin/audience-links', payload);
