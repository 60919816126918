import http from '@/api';

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getListByTrainingProgramId = (id) => http.get(`/api/admin/training_programs/${id}/subjects`);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getTypesByTrainingProgramId = (id) => http.get(`/api/admin/training_programs/${id}/subjects/types`);

/**
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const postCreate = (id, payload) => http.post(`/api/admin/training_programs/${id}/subjects`, payload);

/**
 * @param {number} trainingProgramId
 * @param {number} subjectId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteById = (trainingProgramId, subjectId) => http.delete(
  `/api/admin/training_programs/${trainingProgramId}/subjects/${subjectId}`,
);

/**
 * @param trainingProgramId
 * @param subjectId
 * @param payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const putUpdate = (trainingProgramId, subjectId, payload) => http.put(
  `/api/admin/training_programs/${trainingProgramId}/subjects/${subjectId}`,
  payload,
);
