import Vue from 'vue';
import { waiting } from '@/store/waiting';
import * as TeachersRepository from '@/api/teachers';
import * as UsersRepository from '@/api/users';
import {
  SET_TEACHERS_LIST,
  DELETE_TEACHER_BY_ID,
  SET_TEACHER_BY_ID,
  DROP_TEACHER_BY_ID,
  ADD_TEACHER_TO_LIST,
  UPDATE_TEACHER_BY_ID,
  SET_TEACHERS_FOR_SELECTS,
  SET_TEACHERS_ARCHIVE_LIST,
  REMOVE_TEACHER_FROM_ARCHIVE_LIST,
} from '@/store/modules/teachers/mutation-types';
import orderBy from 'lodash.orderby';

export default {
  namespaced: true,
  state: {
    teachersList: null,
    teachersArchiveList: null,
    teachers: {},
    teachersForSelects: null,
  },
  getters: {
    getTeachersList: (state) => {
      if (state.teachersList?.data) {
        return orderBy(state.teachersList.data, 'id', ['desc']);
      }
      return [];
    },
    getTeachersArchiveList: (state) => {
      if (state.teachersArchiveList) {
        return orderBy(state.teachersArchiveList, 'id', ['desc']);
      }
      return [];
    },
    getTeacherById: (state) => (id) => state.teachers[id] || null,
    getTeachersForSelects: (state) => state.teachersForSelects || [],
  },
  actions: {
    fetch: waiting(
      'fetch.teachers',
      ({ commit }) => TeachersRepository.getList().then(({ data }) => {
        if (data) {
          commit(SET_TEACHERS_LIST, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    fetchArchive: waiting(
      'fetch.teachers.archive',
      ({ commit, rootGetters }) => {
        const payload = {};
        if (rootGetters['active/getActiveAcademicYearId']) {
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        return TeachersRepository.getArchiveList(payload).then(({ data }) => {
          if (data) {
            commit(SET_TEACHERS_ARCHIVE_LIST, data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
    ),
    getById: waiting(
      (id) => `fetch.teachers.${id}`,
      ({ commit }, id) => TeachersRepository.getById(id).then(({ data }) => {
        if (data) {
          commit(SET_TEACHER_BY_ID, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    delete: waiting(
      (id) => `delete.teachers.${id}`,
      ({ commit }, id) => TeachersRepository.deleteById(id).then(() => {
        commit(DELETE_TEACHER_BY_ID, id);
      }),
    ),
    update: waiting(
      ({ id }) => `update.teachers.${id}`,
      ({ commit }, { id, payload }) => TeachersRepository.putUpdate(id, payload).then(({ data }) => {
        if (data) {
          commit(ADD_TEACHER_TO_LIST, data);
          commit(SET_TEACHER_BY_ID, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    recoverById: waiting(
      ({ id }) => `recover.teachers.${id}`,
      ({ commit }, id) => TeachersRepository.getRecoveryById(id).then(({ data }) => {
        if (data) {
          commit(ADD_TEACHER_TO_LIST, data);
          commit(SET_TEACHER_BY_ID, data);
          commit(REMOVE_TEACHER_FROM_ARCHIVE_LIST, id);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    create: waiting(
      'create.teachers',
      ({ commit }, payload) => TeachersRepository.postCreate(payload).then(({ data }) => {
        if (data) {
          commit(ADD_TEACHER_TO_LIST, data);
          commit(SET_TEACHER_BY_ID, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    getPasswordById: waiting(
      (id) => `fetch.teachers.${id}.password`,
      ({ commit }, id) => TeachersRepository.getShowPasswordById(id).then(({ data }) => {
        if (data) {
          commit(UPDATE_TEACHER_BY_ID, { id, payload: data });
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    export: waiting(
      'export.teachers',
      () => UsersRepository.getExport('teacher'),
    ),
    import: waiting(
      'import.teachers',
      (context, { payload, config }) => UsersRepository.postImport(
        payload,
        config,
      ).then((data) => Promise.resolve(data))
        .catch((res) => Promise.reject(res.response.data)),
    ),
    fetchTeachersForSelects: waiting(
      'fetch.teachers.teachers-for-selects',
      ({ commit }) => TeachersRepository.getTeachersForSelects().then(({ data }) => {
        if (data) {
          commit(SET_TEACHERS_FOR_SELECTS, data);
          return Promise.resolve();
        }
        return Promise.reject();
      }),
    ),
    exportTeacherDebts: waiting(
      '',
      () => TeachersRepository.getTeacherDebts(),
    ),
  },
  mutations: {
    [SET_TEACHERS_LIST](state, payload) {
      Vue.set(state, 'teachersList', payload);
    },
    [SET_TEACHERS_ARCHIVE_LIST](state, payload) {
      Vue.set(state, 'teachersArchiveList', payload);
    },
    [DELETE_TEACHER_BY_ID](state, id) {
      if (state.teachersList?.data) {
        state.teachersList.data = state.teachersList.data.filter((s) => parseInt(s.id, 10) !== parseInt(id, 10));
      }
      if (state.teachers[id]) {
        Vue.delete(state.teachers, id);
      }
    },
    [SET_TEACHER_BY_ID](state, payload) {
      const { id } = payload;
      Vue.set(state.teachers, id, payload);
    },
    [DROP_TEACHER_BY_ID](state, id) {
      Vue.delete(state.teachers, id);
    },
    [ADD_TEACHER_TO_LIST](state, payload) {
      const { id } = payload;
      if (!state.teachersList?.data) {
        Vue.set(state, 'teachersList', { data: [] });
      }
      const foundIndex = state.teachersList.data.findIndex((o) => parseInt(o.id, 10) === parseInt(id, 10));
      if (foundIndex > -1) {
        state.teachersList.data.splice(foundIndex, 1, payload);
      } else {
        state.teachersList.data.push(payload);
      }
    },
    [UPDATE_TEACHER_BY_ID](state, { id, payload }) {
      if (state.teachers[id]) {
        Vue.set(state.teachers, id, {
          ...state.teachers[id],
          ...payload,
        });
      }
    },
    [SET_TEACHERS_FOR_SELECTS](state, data) {
      Vue.set(state, 'teachersForSelects', data);
    },
    [REMOVE_TEACHER_FROM_ARCHIVE_LIST](state, id) {
      if (state.teachersArchiveList) {
        // eslint-disable-next-line max-len
        state.teachersArchiveList = state.teachersArchiveList.filter((t) => parseInt(t.id, 10) !== parseInt(id, 10));
      }
    },
  },
};
