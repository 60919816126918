import Vue from 'vue';
import * as JournalRepository from '@/api/journal';
import * as ProgressingRepository from '@/api/journal/progressing';
import * as GradesRepository from '@/api/journal/grades';
import * as FinalGradesRepository from '@/api/journal/final-grades';
import { waiting } from '@/store/waiting';
import {
  DROP_LESSONS_LIST_EXCEPT_DATE,
  SET_LESSONS_LIST_BY_DATE,
  SET_HOMEWORK_PROGRESS,
  SET_HOMEWORK_MARKS,
  ADD_HOMEWORK_MARKS,
  SET_PROGRESSING_LIST,
  SET_PROGRESS_BY_ID,
  ADD_PROGRESS_GRADES,
  SET_VISITINGS_BY_DATE_CLASS,
  ADD_VISITINGS_BY_DATE,
  SET_FINAL_GRADES_TYPES,
  SET_FINAL_GRADES_SUMMARY,
  SET_FINAL_GRADES_GRADES_LIST,
  ADD_FINAL_GRADES_GRADE,
  ADD_FINAL_GRADES_GRADES_LIST,
  SET_PROGRESS_CUSTOMER_ID,
  DELETE_FINAL_GRADES,
  SET_PROGRAM_PROGRESS_LIST,
  SET_PROGRAM_PROGRESS_DETAILS,
  SET_FINAL_GRADES_GRADES_LIST_ALL,
}
  from '@/store/modules/journal/mutation-types';

export default {
  namespaced: true,
  state: {
    progressList: [],
    lessonsListByDate: {},
    visitingsByDateClass: {},
    progressById: {},
    progressCustomerById: {},
    homeworkProgress: [],
    homeworkMarks: [],
    finalGradesTypes: null,
    finalGradesSummary: null,
    finalGradesGradesList: {},
    finalGradesGradesListAll: [],
    programProgressList: [],
    programProgressDetails: {},
  },
  getters: {
    getLessonsListByDate: (state) => (/** @type {string} */ date) => state.lessonsListByDate[date]?.items,
    getNearestPrevDayByDate: (state) => (/** @type {string} */ date) => state.lessonsListByDate[date]?.prev_day || null,
    getNearestNextDayByDate: (state) => (/** @type {string} */ date) => state.lessonsListByDate[date]?.next_day || null,
    getVisitingsByDate: (state) => (date) => state.visitingsByDateClass[date] || [],
    getProgressList: (state) => state.progressList,
    getProgressById: (state) => (id) => state.progressById[id] || null,
    getProgressCustomerById: (state) => (id) => state.progressCustomerById[id] || null,
    getHomeworkMarks: (state) => state.homeworkMarks,
    getFinalGradesTypes: (state) => state.finalGradesTypes,
    getFinalGradesSummary: (state) => state.finalGradesSummary,
    getFinalGradesGradesList: (state) => (classId) => state.finalGradesGradesList[classId],
    getFinalGradesGradesListAll: (state) => state.finalGradesGradesListAll || [],
    getProgramProgressList: (state) => state.programProgressList,
    getProgramProgressDetails: (state) => (id) => state.programProgressDetails[id] || null,
    getProgramsBySubjects: (state) => {
      const programsBySubjectClass = {};
      Object.keys(state.programProgressDetails).forEach((key) => {
        programsBySubjectClass[key] = state.programProgressDetails[key].sections;
      });
      return programsBySubjectClass;
    },
  },
  actions: {
    fetchProgressingList: waiting(
      'fetch.progressing',
      ({ commit, rootGetters }, payload) => {
        if (rootGetters['active/getActiveAcademicYearId']) {
          // eslint-disable-next-line no-param-reassign
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        return ProgressingRepository.getListProgressing(payload).then(({ data }) => {
          if (data) {
            commit(SET_PROGRESSING_LIST, data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
    ),
    fetchProgressingListCustomer: waiting(
      'fetch.progressing.list.customer',
      ({ commit }, payload) => ProgressingRepository.getListProgressingCustomer(payload).then(({ data }) => {
        if (data) {
          commit(SET_PROGRESSING_LIST, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    fetchProgressByIdCustomer: waiting(
      'fetch.progress.customer.details',
      ({ commit }, { id, payload }) => ProgressingRepository.getProgressByIdCustomer(id, payload).then(({ data }) => {
        if (data) {
          commit(SET_PROGRESS_CUSTOMER_ID, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    fetch: waiting(
      'fetch.journal',
      ({ commit, rootGetters }, payload, { cancelToken }) => {
        if (rootGetters['active/getActiveAcademicYearId']) {
          // eslint-disable-next-line no-param-reassign
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        if (rootGetters['auth/role'] === 'teacher') {
          // eslint-disable-next-line no-param-reassign
          payload.teacher_id = rootGetters['auth/getMyInfo']?.id;
        }
        return JournalRepository.getList(payload, { cancelToken }).then(({ data }) => {
          if (data) {
            commit(SET_LESSONS_LIST_BY_DATE, { date: payload.select_date, payload: data });
            commit(DROP_LESSONS_LIST_EXCEPT_DATE, payload.select_date);
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
      { cancel: true },
    ),
    fetchProgressByClass: waiting(
      'fetch.progress.by.class',
      ({ commit, rootGetters }, payload) => {
        if (rootGetters['active/getActiveAcademicYearId']) {
          // eslint-disable-next-line no-param-reassign
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        return ProgressingRepository.getProgressById(payload).then(({ data }) => {
          if (data) {
            commit(SET_PROGRESS_BY_ID, data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
    ),
    fetchVisitingsByDateClass: waiting(
      'fetch.visitings.by.date.class',
      ({ commit, rootGetters }, payload, { cancelToken }) => {
        if (rootGetters['active/getActiveAcademicYearId']) {
          // eslint-disable-next-line no-param-reassign
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        return JournalRepository.getVisitings(payload, { cancelToken }).then(({ data }) => {
          if (data) {
            commit(SET_VISITINGS_BY_DATE_CLASS, { date: payload.select_date, payload: data });
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
      { cancel: true },
    ),
    setJournalVisiting: waiting(
      'set.journal.visiting',
      ({ commit }, { visitingId, payload, date }) => JournalRepository.setVisitings(visitingId, payload)
        .then(({ data }) => {
          if (data) {
            commit(ADD_VISITINGS_BY_DATE, { date, visitingId, payload: data });
            return Promise.resolve(data);
          }
          return Promise.reject();
        }),
    ),
    fetchHomeworkProgress: waiting(
      'fetch.journal.homework.progress',
      ({ commit }, payload) => GradesRepository.getHomeworkProgress(payload).then(({ data }) => {
        if (data) {
          commit(SET_HOMEWORK_PROGRESS, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    fetchHomeworkMarks: waiting(
      'fetch.journal.homework.marks',
      ({ commit }, payload) => GradesRepository.getHomeworkMarks(payload).then(({ data }) => {
        if (data) {
          commit(SET_HOMEWORK_MARKS, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    fetchHomeworksAndGrades: waiting(
      'fetch.journal.homeworks.grades',
      (store, payload) => GradesRepository.getHomeworkMarks(payload).then((response) => response.data),
    ),
    putGradesRatingsByGrade: waiting(
      ({ gradeId }) => `edit.grade.rating.by.${gradeId}`,
      ({ commit }, { gradeId, payload, progressId = null }) => GradesRepository
        .putGradesRatingsByGrade(gradeId, payload)
        .then(({ data }) => {
          if (data) {
            if (progressId) {
              commit(ADD_PROGRESS_GRADES, { progressId, gradeId, payload: data });
            } else {
              commit(ADD_HOMEWORK_MARKS, data);
            }
            return Promise.resolve(data);
          }
          return Promise.reject();
        }),
    ),
    fetchFinalGradesTypes: waiting(
      'fetch.final.grades.types',
      ({ commit }) => FinalGradesRepository.getFinalGradesTypes().then(({ data }) => {
        if (data) {
          commit(SET_FINAL_GRADES_TYPES, data);
          return Promise.resolve(data);
        }
        return Promise.reject();
      }),
    ),
    fetchFinalGradesSummary: waiting(
      'fetch.final.grades.summary',
      ({ commit, rootGetters }, payload) => {
        if (rootGetters['active/getActiveAcademicYearId']) {
          // eslint-disable-next-line no-param-reassign
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        return FinalGradesRepository.getFinalGradesSummary(payload).then(({ data }) => {
          if (data) {
            commit(SET_FINAL_GRADES_SUMMARY, data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
    ),
    fetchFinalGradesGradesList: waiting(
      'fetch.final.grades.grades.list',
      ({ commit, rootGetters }, payload) => {
        if (rootGetters['active/getActiveAcademicYearId']) {
          // eslint-disable-next-line no-param-reassign
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        return FinalGradesRepository.getFinalGradesGradesList(payload).then(({ data }) => {
          if (data) {
            const { classes_id: classId } = payload;
            commit(SET_FINAL_GRADES_GRADES_LIST, { classId, payload: data });
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
    ),
    fetchFinalGradesGradesListAll: waiting(
      'fetch.final.grades.grades.list.all',
      ({ rootGetters }, payload = {}) => {
        if (rootGetters['active/getActiveAcademicYearId']) {
          // eslint-disable-next-line no-param-reassign
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        return FinalGradesRepository.getFinalGradesGradesListAll(payload);
      },
    ),
    createFinalGradesGrade: waiting(
      'create.final.grades.grade',
      ({ commit, rootGetters }, { payload }) => {
        if (rootGetters['active/getActiveAcademicYearId']) {
          // eslint-disable-next-line no-param-reassign
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        return FinalGradesRepository.createFinalGradesGrade(payload)
          .then(({ data }) => {
            if (data) {
              commit(ADD_FINAL_GRADES_GRADES_LIST, { classId: payload.classes_id, payload: data });
              return Promise.resolve(data);
            }
            return Promise.reject();
          });
      },
    ),
    editFinalGradesGrade: waiting(
      'edit.final.grades.grade',
      ({ commit, rootGetters }, { finalGradeSettingId, payload }) => {
        if (rootGetters['active/getActiveAcademicYearId']) {
          // eslint-disable-next-line no-param-reassign
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        return FinalGradesRepository.editFinalGradesGrade({ finalGradeSettingId, payload })
          .then(({ data }) => {
            if (data) {
              commit(ADD_FINAL_GRADES_GRADES_LIST, { finalGradeSettingId, classId: payload.classes_id, payload: data });
              return Promise.resolve(data);
            }
            return Promise.reject();
          });
      },
    ),
    setFinalGradesGrade: waiting(
      'set.final.grades.grade',
      ({ commit }, { classId, gradeId, payload }) => FinalGradesRepository.setFinalGradeGrade(gradeId, payload)
        .then(({ data }) => {
          if (data) {
            commit(ADD_FINAL_GRADES_GRADE, { classId, gradeId, payload: data });
            return Promise.resolve(data);
          }
          return Promise.reject();
        }),
    ),
    patchFinalGrade: waiting(
      'patch.final.grade',
      (store, { gradeId, payload }) => FinalGradesRepository.setFinalGradeGrade(gradeId, payload)
        .then((response) => response.data),
    ),
    deleteFinalGrades: waiting(
      'delete.final.grades',
      ({ commit }, { classId, finalGradeSettingId }) => FinalGradesRepository.deleteFinalGrades(finalGradeSettingId)
        .then(() => commit(DELETE_FINAL_GRADES, { classId, finalGradeSettingId })),
    ),
    fetchProgramProgressList: waiting(
      'fetch.program.progress.list',
      ({ commit, rootGetters }, payload) => {
        if (rootGetters['active/getActiveAcademicYearId']) {
          // eslint-disable-next-line no-param-reassign
          payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        return ProgressingRepository.getProgramProgressing(payload).then(({ data }) => {
          if (data) {
            commit(SET_PROGRAM_PROGRESS_LIST, data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
    ),
    fetchProgramProgressDetails: waiting(
      ({ classes_id: classId, subject_id: subjectId }) => `fetch.program.${classId}-${subjectId}`,
      ({ commit, rootGetters }, payload) => {
        if (rootGetters['active/getActiveAcademicYearId']) {
          // eslint-disable-next-line no-param-reassign
          // payload.academic_year_id = rootGetters['active/getActiveAcademicYearId'];
        }
        return ProgressingRepository.getProgramProgressingDetails(payload).then(({ data }) => {
          if (data) {
            commit(SET_PROGRAM_PROGRESS_DETAILS, data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        });
      },
    ),
    exportProgramProgress: waiting(
      'export.program.progress',
      (context, payload) => ProgressingRepository.exportProgramProgress(payload),
    ),
  },
  mutations: {
    [SET_PROGRESSING_LIST](state, payload) {
      Vue.set(state, 'progressList', payload);
    },
    [SET_LESSONS_LIST_BY_DATE](state, { date, payload }) {
      Vue.set(state.lessonsListByDate, date, payload);
    },
    [DROP_LESSONS_LIST_EXCEPT_DATE](state, date) {
      if (state.lessonsListByDate) {
        Object.keys(state.lessonsListByDate).forEach((key) => {
          if (key !== date) {
            delete state.lessonsListByDate[key];
          }
        });
      }
    },
    [SET_PROGRESS_BY_ID](state, data) {
      const { progress } = data;
      const classSubject = `${progress.class.id}-${progress.subject.id}`;
      Vue.set(state.progressById, classSubject, data);
    },
    [SET_VISITINGS_BY_DATE_CLASS](state, { date, payload }) {
      Vue.set(state.visitingsByDateClass, date, payload);
    },
    [SET_HOMEWORK_PROGRESS](state, payload) {
      Vue.set(state, 'homeworkProgress', payload);
    },
    [SET_HOMEWORK_MARKS](state, payload) {
      Vue.set(state, 'homeworkMarks', payload);
    },
    [SET_PROGRESS_CUSTOMER_ID](state, payload) {
      Vue.set(state.progressCustomerById, payload.progress.id, payload);
    },
    [SET_FINAL_GRADES_TYPES](state, payload) {
      Vue.set(state, 'finalGradesTypes', payload);
    },
    [SET_FINAL_GRADES_SUMMARY](state, payload) {
      Vue.set(state, 'finalGradesSummary', payload);
    },
    [SET_FINAL_GRADES_GRADES_LIST](state, { classId, payload }) {
      Vue.set(state.finalGradesGradesList, classId, payload);
    },
    [SET_FINAL_GRADES_GRADES_LIST_ALL](state, payload) {
      Vue.set(state, 'finalGradesGradesListAll', payload);
    },
    [ADD_HOMEWORK_MARKS](state, data) {
      const foundIndex = state?.homeworkMarks?.grades.findIndex((i) => i.id === data.id);
      if (foundIndex > -1) {
        state.homeworkMarks.grades.splice(foundIndex, 1, data);
      }
    },
    [ADD_PROGRESS_GRADES](state, { progressId, gradeId, payload }) {
      // eslint-disable-next-line no-param-reassign
      payload.student_id = payload.student.id;
      // eslint-disable-next-line no-param-reassign
      payload.topic_id = payload.topic.id;
      const gradeIdx = state.progressById[progressId].grades.findIndex((i) => i.id === gradeId);
      state.progressById[progressId].grades.splice(gradeIdx, 1, payload);
    },
    [ADD_VISITINGS_BY_DATE](state, { date, visitingId, payload }) {
      const foundIndex = state.visitingsByDateClass[date].findIndex((i) => i.id === visitingId);
      state.visitingsByDateClass[date].splice(foundIndex, 1, payload);
    },
    [ADD_FINAL_GRADES_GRADE](state, { classId, gradeId, payload }) {
      if (!payload.topics) {
        // eslint-disable-next-line no-param-reassign
        payload = {
          class: state.finalGradesGradesList[classId].find((i) => i.class)?.class,
          created_at: payload.created_at,
          grade_type: payload.grade_type,
          schedule: payload?.schedule,
          id: payload.id,
          rating_setting: payload.rating_setting,
          student: payload.student,
          topics: [payload.topic],
          updated_at: payload.updated_at,
        };
      }
      const foundIndex = state.finalGradesGradesList[classId]
        .findIndex((i) => i.id === gradeId);
      if (foundIndex > -1) {
        state.finalGradesGradesList[classId]
          .splice(foundIndex, 1, payload);
      }
    },
    [ADD_FINAL_GRADES_GRADES_LIST](state, { finalGradeSettingId, classId, payload }) {
      if (finalGradeSettingId) {
        state.finalGradesGradesList[classId] = state.finalGradesGradesList[classId].map((i) => {
          const payloadItem = payload
            .find((p) => p.final_grade_setting_id === i.final_grade_setting_id && p.id === i.id);
          if (payloadItem) {
            // eslint-disable-next-line no-param-reassign
            i = payloadItem;
          }
          return i;
        });
      } else {
        state.finalGradesGradesList[classId].push(...payload);
      }
    },
    [DELETE_FINAL_GRADES](state, { classId, finalGradeSettingId }) {
      // eslint-disable-next-line max-len
      state.finalGradesGradesList[classId] = state.finalGradesGradesList[classId].filter((g) => g.final_grade_setting_id !== finalGradeSettingId);
    },
    [SET_PROGRAM_PROGRESS_LIST](state, payload) {
      Vue.set(state, 'programProgressList', payload);
    },
    [SET_PROGRAM_PROGRESS_DETAILS](state, payload) {
      // Если запрос был с одним subject_id
      if (typeof payload.subject.id === 'undefined') {
        Object.entries(payload.subject).forEach((entry) => {
          const [key, value] = entry;
          const entity = [];
          entity.classes = payload.classes;
          entity.sections = payload.sections[key];
          entity.subject = value;
          entity.topic_labels = payload.topic_labels;
          const classAndSubject = `${payload?.classes?.id}-${value.id}`;
          Vue.set(state.programProgressDetails, classAndSubject, entity);
        });
      } else {
        const classAndSubject = `${payload?.classes?.id}-${payload?.subject?.id}`;
        Vue.set(state.programProgressDetails, classAndSubject, payload);
      }
    },
  },
};
