import {
  SET_ACTIVE_ACADEMIC_YEAR,
  SET_ACTIVE_STUDENT,
  SET_ACTIVE_ACADEMIC_YEAR_ID,
  SET_ACTIVE_SCHEDULE_SETTINGS,
  CLEAR_ACTIVE_SCHEDULE_SETTINGS,
} from '@/store/modules/active/mutation-types';
import {
  DROP_TRAINING_PROGRAMS_LIST,
} from '@/store/modules/training-programs/mutation-types';
import moment from '@/moment';
import { waiting } from '@/store/waiting';
import * as AcademicYearsRepository from '@/api/academic-years';
import { CLEAR_HOMEWORKS } from '@/store/modules/customer/mutation-types';
import { CLEAR_SCHEDULE_LIST } from '@/store/modules/schedule/mutation-types';
import { CLEAR_STUDENT_LESSONS } from '@/store/modules/lesson/mutation-types';
import { formatDateForOutput } from '@/utils/index';

export default {
  namespaced: true,
  state: {
    academicYear: null,
    academicYearId: null, // TODO: Переписать на геттер и заменить в местах использования
    activeStudentId: null,
    activeScheduleSettings: null,
    selectedAcademicYear: null,
  },
  getters: {
    getActiveAcademicYear: (state) => state.selectedAcademicYear || state.academicYear,
    getActiveAcademicYearId: (state, getters) => getters.getActiveAcademicYear.id,
    getSelectedAcademicYearId: (state) => state.selectedAcademicYear?.id,
    getAcademicYearPeriod: (state, getters) => ({
      start: formatDateForOutput(getters.getActiveAcademicYear.start_date),
      end: formatDateForOutput(
        moment(getters.getActiveAcademicYear.start_date).add(1, 'year').subtract(1, 'd').toDate(),
      ),
    }),
    getWeekendInActiveAcademicYearByDate: (state, getters) => {
      if (getters.getActiveAcademicYear) {
        const weekends = getters.getActiveAcademicYear.weekends || [];
        // eslint-disable-next-line max-len
        return (/** @type {string|object|Date} */ date) => weekends.find((w) => moment(date).isBetween(moment(w.start_date).format('YYYY-MM-DD'),
          moment(w.end_date).format('YYYY-MM-DD'), null, '[]'));
      }
      return () => null;
    },
    getWeekends(state, getters) {
      return getters.getActiveAcademicYear.weekends || [];
    },
    // eslint-disable-next-line max-len
    getActiveStudent: (state, getters, rootState, rootGetters) => rootGetters['auth/userStudents']?.find((student) => student.id === state.activeStudentId),
  },
  actions: {
    setActiveAcademicYear: ({ commit }, value) => {
      commit(SET_ACTIVE_ACADEMIC_YEAR, value);
      commit(SET_ACTIVE_ACADEMIC_YEAR_ID, value.id);
      commit(`trainingPrograms/${DROP_TRAINING_PROGRAMS_LIST}`, null, { root: true });
    },
    setSelectedAcademicYear: ({ commit, dispatch }, value) => {
      commit('SET_SELECTED_ACADEMIC_YEAR', value);
      commit('pagesQueryCache/CLEAR_ALL_CACHE', null, { root: true });
      dispatch('classes/fetch', null, { root: true });
    },
    setActiveStudent: ({ state, commit, dispatch }, value) => {
      if (state.activeStudentId !== value) {
        dispatch('clearStudentData');
        commit(SET_ACTIVE_STUDENT, value);
      }
    },
    clearStudentData: ({ commit }) => {
      commit(`customer/${CLEAR_HOMEWORKS}`, null, { root: true });
      commit(`schedule/${CLEAR_SCHEDULE_LIST}`, null, { root: true });
      commit(`lesson/${CLEAR_STUDENT_LESSONS}`, null, { root: true });
    },
    unsetActiveStudent: ({ commit }) => {
      commit(SET_ACTIVE_STUDENT, null);
    },
    setActiveScheduleSettings: ({ commit }, value) => {
      commit(SET_ACTIVE_SCHEDULE_SETTINGS, value);
    },
    clearActiveScheduleSettings: ({ commit }) => {
      commit(CLEAR_ACTIVE_SCHEDULE_SETTINGS);
    },
    fetchCurrentAcademicYear: waiting(
      'fetch.current-academic-year',
      ({ dispatch }) => AcademicYearsRepository.getCurrent().then(({ data }) => {
        dispatch('active/setActiveAcademicYear', data, { root: true });
        return Promise.resolve();
      }),
    ),
  },
  mutations: {
    [SET_ACTIVE_ACADEMIC_YEAR_ID](state, id) {
      state.academicYearId = id;
    },
    SET_SELECTED_ACADEMIC_YEAR(state, value) {
      state.selectedAcademicYear = value;
    },
    [SET_ACTIVE_STUDENT](state, id) {
      state.activeStudentId = id;
    },
    [SET_ACTIVE_ACADEMIC_YEAR](state, data) {
      state.academicYear = data;
    },
    [SET_ACTIVE_SCHEDULE_SETTINGS](state, data) {
      state.activeScheduleSettings = data;
    },
    [CLEAR_ACTIVE_SCHEDULE_SETTINGS](state) {
      state.activeScheduleSettings = null;
    },
  },
};
