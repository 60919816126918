import Vue from 'vue';
/* eslint-disable  */
const getSpinnerEl = (isBlur) => `
    <div id="loadingSpinner" class="absolute z-100 flex justify-center items-center inset-0 ${isBlur ? 'bg-white bg-opacity-20' : ''}">
        <div class="loading relative w-4 h-4 flex-shrink-0">
            <div class=" relative top-1/2 left-1/2 absolute transform -translate-x-1/2 -translate-y-1/2 border-solid border-current border-2 w-full h-full"/>
        </div>
    </div>
`;

Vue.directive('loading', (el, bindings ) => {
  const elStyle = getComputedStyle(el)
  const elPosition = elStyle.getPropertyValue('position')
  if (bindings.modifiers.fit) {
    const elPadding = elStyle.getPropertyValue('padding')
    const children = Array.from(el.children).filter((el) => el.id !== 'loadingSpinner')
    if (bindings.value && !children.length) {
      if (elPadding === '0px') {
        el.style.padding = '16px'
      }
    } else {
      el.style.padding = '0px'
    }
  }
  if (elPosition === 'static' || !elPosition) {
    el.style.position = 'relative'
  }
  const loadingEl  = el.querySelector('#loadingSpinner')
  if (bindings.value) {
    if (!loadingEl) {
      el.insertAdjacentHTML('beforeend', getSpinnerEl(bindings.modifiers.blur));
    }
  } else {
    if (loadingEl) {
      try {
        el.removeChild(loadingEl)
      } catch (e) {
      }
    }
  }
});
