import moment from 'moment';

/**
 * @param {Object} task
 * @returns {Object}
 */
// eslint-disable-next-line import/prefer-default-export
export function formatSourceHomeworkData(task) {
  const isDone = Boolean(task.grade?.rating_setting?.value);
  const isExpired = Boolean(!isDone && moment().isAfter(moment(task.end_date)));
  const isPending = Boolean(!isDone && task.student_attachment_links.length);
  return {
    ...task, isExpired, isDone, isPending,
  };
}
