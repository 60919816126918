import http from '@/api';

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getLessonsList = ({ student_id: studentId, ...params }, config) => http.get(
  `/api/customer/schedules/${studentId}`,
  {
    params,
    cancelToken: config?.cancelToken,
  },
);

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getHomeworks = (payload) => http.get(
  '/api/customer/tasks',
  {
    params: {
      ...payload,
      type: 'all',
    },
  },
);

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getScheduleListByStudentForParent = ({ payload, id }, config) => http.get(
  `/api/customer/schedules/${id}`,
  {
    params: payload,
    cancelToken: config?.cancelToken,
  },
);
