import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import toast from '@/notify';
import i18n from '@/i18n';

const http = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

export const initialize = ($store, $router) => {
  http.interceptors.request.use((request) => {
    const token = $store.getters['auth/token'];
    if (token) {
      // eslint-disable-next-line no-param-reassign
      request.headers.Authorization = `Bearer ${token}`;
    }
    const locale = i18n?.locale;
    if (locale) {
      // eslint-disable-next-line no-param-reassign
      request.headers['Accept-Language'] = locale;
    }
    return request;
  });
  http.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response) {
        const { config, status, data } = error?.response;
        if (data.message === 'errors.forbidden.access_denied.user') {
          toast.danger({
            text: '😶 Вход в систему заблокирован',
          });
        }
        if (config && ![401].includes(status)) {
          const { showToastOnError } = config;
          if (typeof showToastOnError === 'undefined' || showToastOnError) {
            if (data?.message) {
              if ([425, 423].includes(status)) {
                toast.warn({
                  text: `🗣 ${data.message}`,
                });
              } else {
                toast.danger({
                  text: `😶 ${data.message}`,
                });
              }
            } else if (data?.errors) {
              Object.values(data?.errors).forEach((e) => {
                toast.danger({
                  text: `😶 ${e}`,
                });
              });
            } else {
              toast.danger({
                text: '😶 Что-то пошло не так',
              });
            }
          }
        }
      }
      return Promise.reject(error);
    },
  );
  createAuthRefreshInterceptor(
    http,
    () => {
      if (!$store.getters['auth/isRefreshTokenValid']()) {
        $store.dispatch('auth/logout', { root: true }).then(() => {
          const $route = $router?.app?.$route;
          if (!$route?.matched?.some((route) => route.name === 'Auth')) {
            $router.push({
              name: 'Auth',
            });
          }
        });
        return Promise.reject();
      }
      return $store.dispatch('auth/refreshToken', { root: true })
        .then((data) => {
          if (data?.access_token) {
            return Promise.resolve();
          }
          $store.dispatch('auth/logout', { root: true }).then(() => {
            $router.push({
              name: 'Auth',
            });
          });
          return Promise.reject();
        })
        .catch(() => {
          $store.dispatch('auth/logout', { root: true }).then(() => {
            $router.push({
              name: 'Auth',
            });
          });
          return Promise.reject();
        });
    },
    { statusCodes: [401] },
  );
};

export default http;
