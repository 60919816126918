import http from '@/api';

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line import/prefer-default-export
export const getTypes = () => http.get('/api/admin/schedules/types');

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const createSchedule = (payload) => http.post('/api/admin/schedules', payload);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteSchedule = (id) => http.delete(`/api/admin/schedules/${id}`);

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const copySchedule = (payload) => http.post('api/admin/schedules/copy', payload);

/**
 * @param {number} id
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const putUpdateSchedule = ({ id, payload }) => http.put(`/api/admin/schedules/${id}`, payload);

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSubjectsBySubgroup = (id) => http.get(`/api/admin/sub-groups/${id}/subjects`);

/**
 * @param {object} payload
 * @param {object} config
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getScheduleList = (payload, config) => http.get(
  '/api/admin/schedules',
  {
    params: payload,
    cancelToken: config?.cancelToken,
  },
);

export const getSchedule = (payload) => http.get('/api/admin/schedules', { params: payload })
  .then((response) => response.data);

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getScheduleListForTeacher = (payload, config) => http.get(
  '/api/admin/schedules/me',
  {
    params: payload,
    cancelToken: config?.cancelToken,
  },
);

/**
 * @param {object} period
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getFiltersOptions = (period) => http.get('/api/admin/schedules/get_items_by_periods', {
  params: {
    start_date: period.start,
    end_date: period.end,
  },
}).then((response) => response.data);

/**
 * @param {object} payload
 * @returns {Promise}
 */
export const exportSchedule = (payload) => http.get(
  '/api/admin/schedules/export',
  {
    params: payload,
  },
);
