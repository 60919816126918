import Store from '@/store';

export default (to, from, next) => {
  const { meta = {} } = to;
  let { auth } = meta;
  if (typeof auth === 'undefined') {
    const { matched } = to;
    const match = matched.slice().reverse().find((route) => typeof route.meta?.auth !== 'undefined');
    if (match) {
      auth = Boolean(match.meta.auth);
    } else {
      auth = true;
    }
  }
  if (auth && !Store.getters['auth/authenticated']) {
    if (to.name === 'Dashboard.Settings.General') {
      return next({
        name: 'Auth.Login',
      });
    }
    const query = to.path;
    return next({
      name: 'Auth.Login',
      query: { ref: query },
    });
  }
  return next();
};
