export const SET_CLASSES_LIST = 'SET_CLASSES_LIST';
export const SET_TYPES = 'SET_TYPES';
export const ADD_CLASS_TO_LIST = 'ADD_CLASS_TO_LIST';
export const REMOVE_CLASS_FROM_LIST = 'REMOVE_CLASS_FROM_LIST';
export const SET_CLASS_BY_ID = 'SET_CLASS_BY_ID';
export const SET_CLASS_STUDENTS = 'SET_CLASS_STUDENTS';
export const SET_CLASS_SUBJECTS = 'SET_CLASS_SUBJECTS';
export const SET_CLASS_GROUPS = 'SET_CLASS_GROUPS';
export const REMOVE_STUDENT_FROM_CLASS = 'REMOVE_STUDENT_FROM_CLASS';
export const ADD_STUDENT_TO_CLASS_GROUP = 'ADD_STUDENT_TO_CLASS_GROUP';
export const REMOVE_STUDENT_FROM_CLASS_GROUP = 'REMOVE_STUDENT_FROM_CLASS_GROUP';
export const ADD_CLASS_GROUP = 'ADD_CLASS_GROUP';
export const REMOVE_CLASS_GROUP = 'REMOVE_CLASS_GROUP';
