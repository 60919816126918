import http from '@/api';

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getListProgressing = (payload) => http.get('/api/admin/journal/progressing', {
  params: payload,
});

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getProgressById = (payload) => http.get('api/admin/journal/progressing/details', {
  params: payload,
});

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getListProgressingCustomer = (payload) => http.get('/api/customer/progressing', {
  params: payload,
});

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getProgressByIdCustomer = (id, payload) => http.get(`/api/customer/progressing/${id}`, {
  params: payload,
});

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getProgramProgressing = (payload) => http.get('/api/admin/journal/program-progressing', {
  params: payload,
});

/**
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getProgramProgressingDetails = (payload) => http.get('/api/admin/journal/program-progressing/details', {
  params: payload,
});

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line max-len
export const exportProgramProgress = (payload) => http.get('/api/admin/journal/program-progressing/export', {
  params: payload,
  responseType: 'arraybuffer',
});
