import { waiting } from '@/store/waiting';
import * as CustomerRepository from '@/api/customer';
import {
  SET_TODAY_LESSONS,
  SET_HOMEWORKS,
  CLEAR_HOMEWORKS,
} from '@/store/modules/customer/mutation-types';
import moment from '@/moment';
import { formatSourceHomeworkData } from '@/utils/homework';
import uniqBy from 'lodash.uniqby';

export default {
  namespaced: true,
  state: {
    todayLessons: [],
    homeworks: [],
  },
  getters: {
    getUndoneHomeworks: (state) => state.homeworks.filter((homework) => !homework.isDone),
    getDoneHomeworks: (state) => state.homeworks.filter((homework) => homework.isDone),
    getPendingHomeworks: (state) => state.homeworks.filter((homework) => homework.isPending),
  },
  actions: {
    fetchLessons: waiting(
      'fetch.customer.lessons.list',
      ({ commit }, payload, { cancelToken }) => CustomerRepository.getLessonsList(payload,
        { cancelToken }).then((response) => {
        commit(SET_TODAY_LESSONS, response.data);
        return Promise.resolve(response.data);
      }),
      { cancel: true },
    ),
    fetchStudentHomeworks: waiting(
      'fetch.customer.homeworks',
      ({ commit, rootState }, status) => {
        const payload = {
          start_date: moment.utc(rootState.active.academicYear.start_date).startOf('day').toISOString(),
          end_date: moment.utc(rootState.active.academicYear.end_date).endOf('day').toISOString(),
          student_id: rootState.active.activeStudentId,
        };
        if (status === 'undone') {
          payload.status = ['issued'];
        } else if (status === 'done') {
          payload.status = ['done', 'pending'];
        } else {
          payload.status = ['issued', 'pending', 'done'];
        }
        return CustomerRepository.getHomeworks(payload).then(({ data }) => {
          commit(SET_HOMEWORKS, data.map((item) => formatSourceHomeworkData(item)));
          return Promise.resolve(data);
        });
      },
    ),
  },
  mutations: {
    [SET_TODAY_LESSONS](state, payload) {
      state.todayLessons = payload;
    },
    [SET_HOMEWORKS](state, payload) {
      // state.homeworks = uniqBy([...state.homeworks, ...payload], 'id');
      state.homeworks = uniqBy(payload, 'id');
    },
    [CLEAR_HOMEWORKS](state) {
      state.homeworks = [];
    },
  },
};
