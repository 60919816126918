import Vue from 'vue';
import omit from 'lodash.omit';
import moment from '@/moment';

export default {
  namespaced: true,
  state: {
    cache: {},
    valuesCache: {},
    lastPage: null,
  },
  mutations: {
    SET_CACHE(state, { type, query }) {
      Vue.set(state.cache, type, query);
    },
    SET_VALUES_CACHE(state, { page, type, value }) {
      if (!state.valuesCache[page]) {
        Vue.set(state.valuesCache, page, {});
      }
      if (state.valuesCache[page][type] && !Object.getOwnPropertyDescriptor((state.valuesCache[page]), type).set) {
        Vue.delete(state.valuesCache[page], type);
      }
      Vue.set(state.valuesCache[page], type, value);
    },
    REPLACE_FILTERS(state, { page, value }) {
      Vue.set(state.valuesCache, page, value);
    },
    CLEAR_ALL_CACHE(state) {
      Vue.set(state, 'valuesCache', {});
    },
    SET_TODAY_DATE(state) {
      Object.keys(omit(state.valuesCache, state.lastPage)).forEach((key) => {
        const cachedDate = state.valuesCache[key]?.date;
        if (cachedDate) {
          state.valuesCache[key].date = cachedDate.start
            ? { start: moment(new Date()).startOf('week').toDate(), end: moment(new Date()).endOf('week').toDate() }
            : new Date();
        }
      });
    },
    SET_LAST_PAGE(state, page) {
      Vue.set(state, 'lastPage', page);
    },
  },
};
