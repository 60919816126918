export const SET_STUDENTS_LIST = 'SET_STUDENTS_LIST';
export const DROP_STUDENTS_LIST = 'DROP_STUDENTS_LIST';
export const ADD_STUDENT_TO_LIST = 'ADD_STUDENT_TO_LIST';
export const SET_STUDENT_BY_ID = 'SET_STUDENT_BY_ID';
export const UPDATE_STUDENT_BY_ID = 'UPDATE_STUDENT_BY_ID';
export const DROP_STUDENT_BY_ID = 'DROP_STUDENT_BY_ID';
export const SET_STUDENT_PARENT_BY_ID = 'SET_STUDENT_PARENT_BY_ID';
export const DELETE_STUDENT_PARENT_BY_ID = 'DELETE_STUDENT_PARENT_BY_ID';
export const DELETE_STUDENT_BY_ID = 'DELETE_STUDENT_BY_ID';
export const SET_STUDENT_CLASS = 'SET_STUDENT_CLASS';
export const SET_STUDENTS_ARCHIVE_LIST = 'SET_STUDENTS_ARCHIVE_LIST';
export const REMOVE_STUDENT_FROM_ARCHIVE_LIST = 'REMOVE_STUDENT_FROM_ARCHIVE_LIST';
