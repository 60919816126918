import http from '@/api';

/**
 * @param {object} payload
 * @returns {Promise}
 */
export const getTasks = (payload) => http.get('/api/admin/tasks', { params: payload })
  .then((response) => response.data);

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line import/prefer-default-export
export const getTeacherTasksCalendar = (payload) => http.get('/api/admin/tasks/calendar', {
  params: payload,
});

/**
 * @param {object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getTeacherHomeworks = (payload) => http.get('api/admin/work/today/homeworks', {
  params: payload,
});
