import waiting from '@/store/waiting';
import { fetchGrades } from '@/api/grades/index';
import { putGradesForTopic } from '@/api/lesson/index';
import { formatGrade } from '@/utils/index';

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  actions: {
    fetchSectionGrades: waiting(
      ({ section }) => `fetch.section.grades${section}`,
      (store, { payload }) => fetchGrades(payload).then((response) => response.data)
        .then((grades) => grades.map((grade) => formatGrade(grade))),
    ),
    patchLessonGrade: waiting(
      ({ gradeId }) => `patch.grade.${gradeId}`,
      (store, { lessonId, gradeId, payload }) => putGradesForTopic(lessonId, gradeId, payload)
        .then((response) => response.data),
    ),
  },
};
